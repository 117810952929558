import { ACTION_VIEW_ID, PAGE_RECORDS, STATUS_ID, formatDate, normalizeStringForRest } from "../../utils/utils";
import { AdapterDateFns, DatePicker, LocalizationProvider } from "@vapor/react-x-date-pickers";
import { Button, FormControl, InputLabel, MenuItem, Pagination, Select, TextField } from "@vapor/react-material";
import { ChangeEvent, useEffect, useState } from "react";
import { EOrder, Order, SurveyStatus } from "../../model/enum";
import { FlexCenter, FlexContainer, FlexEnd } from "../../style/div";
import { selectMySurveys, shouldFetchAllMySurveys } from "../../store/slice/mySurveys";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import { AnyAction } from "redux";
import CustomPage from "../../components/customPage/CustomPage";
import { ELanguages } from "../../localization/i18n";
import { EReduxState } from "../../store/model";
import EmptyState from "@vapor/react-custom/EmptyState";
import FilterBtn from "../../components/btns/FilterBtn";
import GenericTable from "../../components/table/Table";
import { PATHS } from "../../routes/routesEnum";
import Search from "@vapor/react-icons/Search";
import SelectRecord from "../../components/selectRecord/SelectRecord";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MySurveys = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectMySurveys);
    const navigate = useNavigate();
    const [record, setRecord] = useState<number>(PAGE_RECORDS[0]);
    const [search, setSearch] = useState<string>("");
    const [order, setOrder] = useState<Order>(EOrder.asc);
    const [orderBy, setOrderBy] = useState<string>(i18n.language === ELanguages.en ? "titleEn" : "titleIt");
    const [sortBy, setSortBy] = useState<string>("survey");
    const [page, setPage] = useState<number>(1);

    const [statusSelect, setStatusSelect] = useState<SurveyStatus | undefined>(undefined);
    const [requiredSelect, setRequiredSelect] = useState<string | undefined>(undefined);

    const [filterSurvey, setFilterSurvey] = useState<string>("");
    const [filterStatus, setFilterStatus] = useState<string>("");
    const [filterRequired, setFilterRequired] = useState<string>("");
    const [filterDate, setFilterDate] = useState<string>("");
    const [datePicker, setDatePicker] = useState<string>("");

    const [radioDate, setRadioDate] = useState<string>("e");

    const [filter, setFilter] = useState<boolean>(false);
    const [filteredStatus, setFilteredStatus] = useState<boolean>(false);
    const [filteredRequired, setFilteredRequired] = useState<boolean>(false);
    const [filteredDate, setFilteredDate] = useState<boolean | undefined>(undefined);

    const { status, survey } = data;

    const fetchAllSurvey = () => dispatch(shouldFetchAllMySurveys({ pageNumber: 1, records: record, forceCall: true, nameFilter: search, query: buildQuery() }));

    useEffect(() => {
        fetchAllSurvey();
    }, [record, search]);

    const buildQuery = () => {
        return `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${!!filterStatus ? `${!!filterSurvey ? ";" : ""}filterstatus=${filterStatus}` : ""}${
            !!filterRequired ? `${!!filterStatus || !!filterSurvey ? ";" : ""}filterrequired=${filterRequired}` : ""
        }${!!filterDate ? `${!!filterRequired || !!filterStatus || !!filterSurvey ? ";" : ""}filterdate=${filterDate}` : ""}`;
    };

    const sort = (cellId: string, fieldName: string, isAsc: boolean) => {
        setOrder(isAsc ? EOrder.desc : EOrder.asc);
        setOrderBy(cellId);
        setSortBy(fieldName);
        setPage(1);
        dispatch(shouldFetchAllMySurveys({ pageNumber: 1, sortOrder: isAsc ? EOrder.desc : EOrder.asc, sorting: fieldName, records: record, query: buildQuery(), forceCall: true }));
    };

    const statusItems = [SurveyStatus.New, SurveyStatus.Pending, SurveyStatus.Completed, SurveyStatus.Deleted, SurveyStatus.Expired];

    return (
        <CustomPage pageTitle={t("mySurveys")}>
            {status === EReduxState.rejected ? (
                <FlexContainer>
                    <EmptyState badgeIcon description={t("error")} title={t("apiError")} />
                </FlexContainer>
            ) : (
                <>
                    <SelectRecord record={record} setRecord={setRecord} />
                    <GenericTable
                        editBtn={PATHS.skills}
                        loading={status === EReduxState.pending}
                        eyeBtn={(id: number) => {}}
                        clickableRow={(id: number) => navigate(`${PATHS.mySurveys}/${id}`)}
                        headCells={[
                            {
                                id: i18n.language === ELanguages.en ? "titleEn" : "titleIt",
                                label: t("clmName"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "survey",
                                filter: (
                                    <FilterBtn
                                        showDelete={filter}
                                        cleanFilter={() => {
                                            setFilter(false);
                                            setFilterSurvey("");
                                            dispatch(
                                                shouldFetchAllMySurveys({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: `&filters=${!!filterStatus ? `filterstatus=${filterStatus}` : ""}${
                                                        !!filterRequired ? `${!!filterStatus ? ";" : ""}filterrequired=${filterRequired}` : ""
                                                    }${!!filterDate ? `${!!filterRequired || !!filterStatus ? ";" : ""}filterdate=${filterDate}` : ""}`,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilter(true);
                                                    dispatch(
                                                        shouldFetchAllMySurveys({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                                !!filterStatus ? `${!!filterSurvey ? ";" : ""}filterstatus=${filterStatus}` : ""
                                                            }${!!filterRequired ? `${!!filterStatus || !!filterSurvey ? ";" : ""}filterrequired=${filterRequired}` : ""}${
                                                                !!filterDate ? `${!!filterRequired || !!filterStatus || !!filterSurvey ? ";" : ""}filterdate=${filterDate}` : ""
                                                            }`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.name")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        name="search"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterSurvey(`${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            {
                                id: STATUS_ID,
                                label: t("clmStatus"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "status",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredStatus}
                                        cleanFilter={() => {
                                            setFilteredStatus(false);
                                            setFilterStatus("");
                                            dispatch(
                                                shouldFetchAllMySurveys({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                        !!filterRequired ? `${!!filterSurvey ? ";" : ""}filterrequired=${filterRequired}` : ""
                                                    }${!!filterDate ? `${!!filterRequired || !!filterSurvey ? ";" : ""}filterdate=${filterDate}` : ""}`,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredStatus(true);
                                                    setStatusSelect(undefined);
                                                    setFilterStatus(`${statusSelect}`);
                                                    dispatch(
                                                        shouldFetchAllMySurveys({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                                !!statusSelect ? `${!!filterSurvey ? ";" : ""}filterstatus=${statusSelect}` : ""
                                                            }${!!filterRequired ? `${!!filterStatus || !!filterSurvey ? ";" : ""}filterrequired=${filterRequired}` : ""}${
                                                                !!filterDate ? `${!!filterRequired || !!filterStatus || !!filterSurvey ? ";" : ""}filterdate=${filterDate}` : ""
                                                            }`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="demo-simple-select-label">{t("filter.status")}</InputLabel>
                                                <FlexCenter>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={statusSelect}
                                                        label="Status"
                                                        sx={{ width: "100%" }}
                                                        onChange={(e) => setStatusSelect(e.target.value as SurveyStatus)}
                                                    >
                                                        {statusItems.map((s, index) => (
                                                            <MenuItem key={index} value={s}>
                                                                {t(s.toLowerCase()).toLowerCase()}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            {
                                id: "requiredFe",
                                label: t("clmRequired"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "required",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredRequired}
                                        cleanFilter={() => {
                                            setFilteredRequired(false);
                                            setRequiredSelect(undefined);
                                            setFilterRequired("");
                                            dispatch(
                                                shouldFetchAllMySurveys({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                        !!filterStatus ? `${!!filterSurvey ? ";" : ""}filterstatus=${filterStatus}` : ""
                                                    }${!!filterDate ? `${!!filterStatus || !!filterSurvey ? ";" : ""}filterdate=${filterDate}` : ""}`,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredRequired(true);
                                                    setFilterRequired(`${requiredSelect}`);
                                                    dispatch(
                                                        shouldFetchAllMySurveys({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                                !!filterStatus ? `${!!filterSurvey ? ";" : ""}filterstatus=${filterStatus}` : ""
                                                            }${!!requiredSelect ? `${!!filterStatus || !!filterSurvey ? ";" : ""}filterrequired=${requiredSelect}` : ""}${
                                                                !!filterDate ? `${!!requiredSelect || !!filterStatus || !!filterSurvey ? ";" : ""}filterdate=${filterDate}` : ""
                                                            }`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="demo-simple-select-label">{t("filter.required")}</InputLabel>
                                                <FlexCenter>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={requiredSelect}
                                                        label="Status"
                                                        sx={{ width: "100%" }}
                                                        onChange={(e) => setRequiredSelect(e.target.value as string)}
                                                    >
                                                        <MenuItem value={"false"}>{t("radioNo")}</MenuItem>
                                                        <MenuItem value={"true"}>{t("radioYes")}</MenuItem>
                                                    </Select>
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            {
                                id: "lastVote",
                                label: t("clmLastVote"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "date",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredDate}
                                        cleanFilter={() => {
                                            setFilteredDate(false);
                                            setFilterDate("");
                                            setRequiredSelect(undefined);
                                            dispatch(
                                                shouldFetchAllMySurveys({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                        !!filterStatus ? `${!!filterSurvey ? ";" : ""}filterstatus=${filterStatus}` : ""
                                                    }${!!filterRequired ? `${!!filterStatus || !!filterSurvey ? ";" : ""}filterrequired=${filterRequired}` : ""}`,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredDate(true);
                                                    dispatch(
                                                        shouldFetchAllMySurveys({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                                !!filterStatus ? `${!!filterSurvey ? ";" : ""}filterstatus=${filterStatus}` : ""
                                                            }${!!requiredSelect ? `${!!filterStatus || !!filterSurvey ? ";" : ""}filterrequired=${requiredSelect}` : ""}${
                                                                !!filterDate ? `${!!requiredSelect || !!filterStatus || !!filterSurvey ? ";" : ""}filterdate=${filterDate}` : ""
                                                            }`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="demo-simple-select-label">{t("filter.date")}</InputLabel>
                                                <FlexCenter>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={radioDate}
                                                        label="Status"
                                                        sx={{ width: "72px", justifyContent: "center" }}
                                                        onChange={(e) => {
                                                            let value: string = `${e.target.value}`;
                                                            setRadioDate(e.target.value as string);
                                                            let date = !!filterDate ? filterDate.replace(/[<>e]/g, "") : new Date().toISOString().substring(0, 10);
                                                            setFilterDate(value + date);
                                                        }}
                                                    >
                                                        <MenuItem value={"<"}>{"<"}</MenuItem>
                                                        <MenuItem value={">"}>{">"}</MenuItem>
                                                        <MenuItem value={"e"}>{"="}</MenuItem>
                                                    </Select>
                                                    <TextField
                                                        className="input-container"
                                                        type="date"
                                                        label=""
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterDate(radioDate + e.target.value);
                                                        }}
                                                    />
                                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            onChange={(e: any) => {
                                                                setDatePicker(e);
                                                                setFilterDate(radioDate + e.toISOString().slice(0, 10));
                                                            }}
                                                            value={datePicker}
                                                            renderInput={(props) => <TextField {...props} />}
                                                        />
                                                    </LocalizationProvider> */}
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            { id: ACTION_VIEW_ID, label: "", numeric: false, disablePadding: false },
                        ]}
                        rows={
                            status === EReduxState.pending
                                ? Array.from(Array(record).keys())
                                : survey.map((s) => ({ ...s, requiredFe: !!s.required ? t("radioYes") : t("radioNo"), lastVote: !!s.lastVote ? formatDate(s.lastVote) : "" }))
                        }
                        parentOrder={order}
                        fieldToOrder={orderBy}
                        parentSortAscDesc={sort}
                    />
                    <FlexEnd>
                        {data.totalPages > 1 && (
                            <Pagination
                                page={page}
                                count={data.totalPages}
                                onChange={(event: ChangeEvent<unknown>, page: number) => {
                                    setPage(page);
                                    dispatch(shouldFetchAllMySurveys({ pageNumber: page, sortOrder: order, sorting: sortBy, records: record, query: buildQuery(), forceCall: true }));
                                }}
                            />
                        )}
                    </FlexEnd>
                </>
            )}
        </CustomPage>
    );
};

export default MySurveys;
