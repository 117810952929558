import { ACTION_VIEW_ID, PAGE_RECORDS, csvFileName, normalizeStringForRest, truncateString } from "../../utils/utils";
import {
    Box,
    Button,
    Drawer,
    FormControl,
    IconButton,
    InputLabel,
    Pagination,
    Popover,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@vapor/react-material";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { ContainerSmallGap, FilterContainer, FlexCenter, FlexContainer, FlexEnd } from "../../style/div";
import { EOrder, Order } from "../../model/enum";
import { IEditSkill, IGetSkillForAdminResponse } from "../../model";
import { getCsvData, getSkillsForManager } from "../../api/helper";
import { selectMyTeamSkills, shouldFetchAllMyTeamSkills } from "../../store/slice/myTeamSkills";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import { CSVLink } from "react-csv";
import Close from "@vapor/react-icons/Xmark";
import CustomPage from "../../components/customPage/CustomPage";
import DeleteRow from "../../modals/deleteRow/DeleteRow";
import { ELanguages } from "../../localization/i18n";
import { EReduxState } from "../../store/model";
import EmptyState from "@vapor/react-custom/EmptyState";
import FilterBtn from "../../components/btns/FilterBtn";
import GenericTable from "../../components/table/Table";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import Search from "@vapor/react-icons/Search";
import SelectRecord from "../../components/selectRecord/SelectRecord";
import { StyledCsvBtnManager } from "../../style/buttons";
import Title from "@vapor/react-custom/Title";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const MyTeamSkills = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectMyTeamSkills);
    const [record, setRecord] = useState<number>(PAGE_RECORDS[0]);
    const [modalRemove, setModalRemove] = useState<{ show: boolean; id: number }>({ show: false, id: -1 });
    const deleteRow = useCallback(async () => {}, [modalRemove]);
    const { status, skills } = data;
    const [search, setSearch] = useState<string>("");
    const [csvData, setCsvData] = useState<Array<string[]>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [date, setDate] = useState<string>("");
    const [modalMatrix, setModalMatrix] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [matrix, setMatrix] = useState<IGetSkillForAdminResponse[]>([]);
    const [title, setTitle] = useState<string>(t("userMatrix"));
    const [page, setPage] = useState<number>(1);

    const [order, setOrder] = useState<Order>(EOrder.asc);
    const [orderBy, setOrderBy] = useState<string>(i18n.language === ELanguages.en ? "nameEn" : "nameIt");
    const [sortBy, setSortBy] = useState<string>("");

    const [filterQuery, setFilterQuery] = useState<string>("");

    const [filterSkill, setFilterSkill] = useState<string>("");
    const [filterCategory, setFilterCategory] = useState<string>("");
    const [filterSurvey, setFilterSurvey] = useState<string>("");

    const [filteredSkill, setFilteredSkill] = useState<boolean>(false);
    const [filteredSurvey, setFilteredSurvey] = useState<boolean>(false);

    const fetchData = useCallback(async (type: IEditSkill) => {
        setLoadingData(true);
        try {
            setTitle(i18n.language === ELanguages.en ? type.nameEn : type.nameIt);

            let data = await getSkillsForManager({ surveyId: type.idSurvey, skillId: type.id });
            const skills = get(data, "data", []);
            setMatrix(skills);
        } catch (err) {
        } finally {
            setLoadingData(false);
        }
    }, []);

    const fetchAllSkills = useCallback(async () => {
        dispatch(shouldFetchAllMyTeamSkills({ pageNumber: 1, records: record, forceCall: true, nameFilter: search, query: filterQuery }));
    }, [filterQuery, record]);

    useEffect(() => {
        fetchAllSkills();
    }, [search, record]);
    const csvInstance = useRef<any>();

    useEffect(() => {
        const link: any = get(csvInstance, "current.link");
        if (csvData.length > 0 && !!link) {
            setTimeout(() => {
                link.click();
                setCsvData([]);
            });
        }
    }, [csvData]);

    const fetchCsvDataAndDownload = async () => {
        setLoading(true);
        try {
            let response = await getCsvData(true, date);
            let data = get(response, "data", []);
            setCsvData(data);
            setLoading(false);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const sort = useCallback(
        (cellId: string, fieldName: string, isAsc: boolean) => {
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
            setOrderBy(cellId);
            setPage(1);
            setSortBy(fieldName);
            dispatch(shouldFetchAllMyTeamSkills({ pageNumber: 1, sortOrder: isAsc ? EOrder.desc : EOrder.asc, sorting: fieldName, records: record, query: filterQuery, forceCall: true }));
        },
        [filterQuery, record],
    );
    return (
        <CustomPage pageTitle={t("myTeamSkills")}>
            <FilterContainer>
                <SelectRecord record={record} setRecord={setRecord} />

                <FlexEnd>
                    <>
                        <StyledCsvBtnManager aria-describedby={id} variant="contained" onClick={handleClick}>
                            {t("downloadCsv")}
                        </StyledCsvBtnManager>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "auto",
                                    px: 2,
                                    py: 2,
                                }}
                            >
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="titleSmall">{t("csvTitle")}</Typography>
                                    <IconButton color="primary" onClick={handleClose}>
                                        <Close />
                                    </IconButton>
                                </Stack>
                                <Box>
                                    <Typography variant="body">{t("csvDescription")}</Typography>

                                    <TextField className="input-container" defaultValue={new Date().toISOString().substring(0, 10)} type="date" label="" onChange={(e) => setDate(e.target.value)} />
                                </Box>
                                <Stack direction="row" spacing={1} justifyContent="right" alignItems="center" sx={{ marginTop: "0.5rem" }}>
                                    <Button variant="outlined" onClick={handleClose}>
                                        {t("buttons.cancel")}
                                    </Button>
                                    <LoadingBtn type="button" variant="contained" loading={loading} msg={t("downloadCsv")} onClick={fetchCsvDataAndDownload} />
                                </Stack>
                            </Box>
                        </Popover>
                        {csvData ? <CSVLink data={csvData} filename={csvFileName(date)} ref={csvInstance} separator={i18n.language === ELanguages.it ? ";" : ","} /> : undefined}
                    </>
                </FlexEnd>
            </FilterContainer>
            {status === EReduxState.rejected ? (
                <FlexContainer>
                    <EmptyState badgeIcon description={t("error")} title={t("apiError")} />
                </FlexContainer>
            ) : (
                <>
                    <GenericTable
                        loading={status === EReduxState.pending}
                        headCells={[
                            {
                                id: i18n.language === ELanguages.en ? "nameEn" : "nameIt",
                                label: t("clmSkill"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "skill",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredSkill}
                                        cleanFilter={() => {
                                            setFilteredSkill(false);
                                            let queryToSet =
                                                !!filterCategory && !!filterSurvey
                                                    ? `&filters=${filterCategory};${filterSurvey}`
                                                    : !!filterCategory || !!filterSurvey
                                                      ? `&filters=${filterCategory ?? ""}${filterSurvey ?? ""}`
                                                      : "";

                                            setFilterQuery(queryToSet);
                                            setFilterSkill("");
                                            dispatch(
                                                shouldFetchAllMyTeamSkills({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: queryToSet,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredSkill(true);
                                                    let queryToSet = `&filters=${filterSkill}${!!filterCategory ? `;${filterCategory}` : ""}${!!filterSurvey ? `;${filterSurvey}` : ""}`;
                                                    setFilterQuery(queryToSet);
                                                    dispatch(
                                                        shouldFetchAllMyTeamSkills({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: queryToSet,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.skill")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        name="search"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterSkill(`filterskill=${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            {
                                id: i18n.language === ELanguages.en ? "surveyTitleEn" : "surveyTitleIt",
                                label: t("clmSurvey"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "survey",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredSurvey}
                                        cleanFilter={() => {
                                            setFilteredSurvey(false);
                                            let queryToSet =
                                                !!filterCategory && !!filterSkill
                                                    ? `&filters=${filterCategory};${filterSurvey}`
                                                    : !!filterCategory || !!filterSkill
                                                      ? `&filters=${filterCategory ?? ""}${filterSkill ?? ""}`
                                                      : "";

                                            setFilterQuery(queryToSet);
                                            setFilterSurvey("");
                                            dispatch(
                                                shouldFetchAllMyTeamSkills({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: queryToSet,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredSurvey(true);
                                                    let queryToSet = `&filters=${filterSurvey}${!!filterCategory ? `;${filterCategory}` : ""}${!!filterSkill ? `;${filterSkill}` : ""}`;
                                                    setFilterQuery(queryToSet);
                                                    dispatch(
                                                        shouldFetchAllMyTeamSkills({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: queryToSet,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.survey")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        name="search"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterSurvey(`filtersurvey=${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            { id: ACTION_VIEW_ID, label: "", numeric: false, disablePadding: false },
                        ]}
                        rows={status === EReduxState.pending ? Array.from(Array(record).keys()) : skills}
                        eyeBtn={(data: number, type: IEditSkill) => {
                            fetchData(type);
                            setModalMatrix(true);
                        }}
                        parentOrder={order}
                        fieldToOrder={orderBy}
                        parentSortAscDesc={sort}
                    />
                    <FlexEnd>
                        {data.totalPages > 1 && (
                            <Pagination
                                page={page}
                                count={data.totalPages}
                                onChange={(event: ChangeEvent<unknown>, page: number) => {
                                    setPage(page);
                                    dispatch(shouldFetchAllMyTeamSkills({ pageNumber: page, sortOrder: order, sorting: sortBy, records: record, query: filterQuery, forceCall: true }));
                                }}
                            />
                        )}
                    </FlexEnd>
                </>
            )}
            <DeleteRow apply={deleteRow} cancel={() => setModalRemove((oldState) => ({ ...oldState, show: false }))} open={modalRemove.show} />
            <Drawer anchor="right" open={modalMatrix} onClose={() => setModalMatrix(false)} width={600} sx={{ zIndex: "1220 !important" }}>
                <Title
                    description=""
                    rightItems={
                        <IconButton sx={{ alignSelf: "flex-end" }} onClick={() => setModalMatrix(false)} color="primary">
                            <Close />
                        </IconButton>
                    }
                    title={truncateString(title)}
                />
                <ContainerSmallGap>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("userName")}</TableCell>
                                <TableCell>{t("lastVote")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </>
                            ) : (
                                <>
                                    {matrix.map((m: IGetSkillForAdminResponse) =>
                                        m.skills.map((m) => (
                                            <TableRow key={`${m.skillId}${m.vote}${m.surveyId}`}>
                                                <TableCell component="th" scope="row">
                                                    {m.userFirstName} {m.userLastName}
                                                </TableCell>

                                                <TableCell>{m.vote}</TableCell>
                                            </TableRow>
                                        )),
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </ContainerSmallGap>
            </Drawer>
        </CustomPage>
    );
};

export default MyTeamSkills;
