import { Dispatch, SetStateAction } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@vapor/react-material";

import { FlexStart } from "../../style/div";
import { PAGE_RECORDS } from "../../utils/utils";

interface ISelectRecordProps {
    setRecord: Dispatch<SetStateAction<number>>;
    record: number;
}

const SelectRecord = (props: ISelectRecordProps) => {
    const { setRecord, record } = props;
    return (
        <FlexStart>
            <FormControl
                sx={{
                    m: 0,
                    width: 120,
                }}
            >
                <InputLabel id="simple-select-label">Record</InputLabel>
                <Select id="simple-select-label" label="Record" value={record} onChange={(e) => setRecord(e.target.value as number)}>
                    {PAGE_RECORDS.map((data) => (
                        <MenuItem value={data} id={`${data}`} key={data}>
                            {data}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </FlexStart>
    );
};

export default SelectRecord;
