import "./style.scss";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Skeleton } from "@vapor/react-material";
import { FlexCenter, FlexClm } from "../../style/div";
import { ISurveyBe, ISurveyToFillFormik } from "../../model";
import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";
import { getLoggedSurveyById, submitSurvey } from "../../api/helper";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ArrowLeft from "@vapor/react-icons/ArrowLeft";
import Close from "@vapor/react-icons/Xmark";
import { ELanguages } from "../../localization/i18n";
import { Formik } from "formik";
import GenericCancelModalWithBtn from "../../modals/genericCancel/GenericCancelModalWithBtn";
import Header from "../../components/header/Header";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import { PATHS } from "../../routes/routesEnum";
import { SurveyStatus } from "../../model/enum";
import Typography from "@vapor/react-extended/ExtendedTypography";
import ViewMacro from "../../components/viewMacro/ViewMacro";
import { deSerializeSurveyToFill } from "../../model/deserialize";
import { get } from "lodash";
import { normalizeSurveyToFillData } from "../../utils/utils";
import { openToast } from "../../store/slice/toastSlice";
import { useAppDispatch } from "../../store/hook";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

interface ISurveyToFillProps {
    readonly?: boolean;
}

const SurveyToFill = ({ readonly }: ISurveyToFillProps) => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState<boolean>(false);
    const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [loadingSurvey, setLoadingSurvey] = useState<boolean>(false);
    const [start, setStart] = useState<boolean>(false);
    const navigate = useNavigate();
    const [survey, setSurvey] = useState<ISurveyBe>();
    const [initialValue, setInitialValue] = useState<ISurveyToFillFormik>({});
    const { accounts } = useMsal();
    const username = get(accounts, "[0].name", "");

    const fetchSurvey = useCallback(async () => {
        setLoadingSurvey(true);
        try {
            if (!!id) {
                let response = await getLoggedSurveyById(parseInt(id));
                setSurvey(response.data);
                let data = deSerializeSurveyToFill(response.data);
                let isStarted = Object.keys(data).filter((d) => data[d].isMacroCategoryResponse);
                setStart(isStarted.length > 0 || response.data.status !== SurveyStatus.New);
                setInitialValue(data);
            }
        } catch (err) {
            dispatch(openToast({ msg: t("newSurveyForm.errorLoading"), severity: "error" }));
        } finally {
            setLoadingSurvey(false);
        }
    }, []);

    useEffect(() => {
        fetchSurvey();
    }, []);

    const saveDraft = useCallback(async (values: any) => {
        setLoadingDraft(true);
        try {
            if (!!id) {
                let normalizedData = normalizeSurveyToFillData(values);
                if (normalizedData.length > 0) {
                    await submitSurvey({
                        surveyId: parseInt(id),
                        isDraft: true,
                        skillWithVote: normalizedData,
                    });
                    dispatch(openToast({ msg: t("newSurveyForm.success") }));
                } else {
                    dispatch(openToast({ msg: t("newSurveyForm.draftWarning"), severity: "warning" }));
                }
            }
        } catch (err) {
            dispatch(openToast({ msg: t("newSurveyForm.error"), severity: "error" }));
        } finally {
            setLoadingDraft(false);
        }
    }, []);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    try {
                        let normalizedData = normalizeSurveyToFillData(values, true);
                        if (!!normalizedData && normalizedData.length > 0 && !!id) {
                            await submitSurvey({
                                surveyId: parseInt(id),
                                isDraft: false,
                                skillWithVote: normalizedData,
                            });
                            dispatch(openToast({ msg: t("newSurveyForm.success") }));
                            navigate(PATHS.mySurveys);
                        } else if (!!id) {
                            dispatch(openToast({ msg: t("errorComplete"), severity: "warning" }));
                        }
                    } catch (err) {
                        dispatch(openToast({ msg: t("newSurveyForm.error"), severity: "error" }));
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                {({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
                            <div className="survey-container">
                                <VaporPage
                                    variant="basic"
                                    title={i18n.language === ELanguages.en ? survey?.titleEn : survey?.titleIt}
                                    subtitle=""
                                    headerLeft={
                                        <IconButton onClick={() => setOpen(true)}>
                                            <ArrowLeft color="primary" />
                                        </IconButton>
                                    }
                                    contentToolbar={
                                        <VaporToolbar
                                            size="large"
                                            contentRight={[
                                                <GenericCancelModalWithBtn disabled={loading} cancel={() => navigate(-1)} key={"cancel"} />,
                                                <LoadingBtn
                                                    variant="outlined"
                                                    type="button"
                                                    disabled={!start || loading}
                                                    key={"secondary"}
                                                    loading={loadingDraft}
                                                    msg={t("buttons.saveAsDraft")}
                                                    onClick={() => saveDraft(values)}
                                                />,
                                                <LoadingBtn loading={loading} type="submit" msg={t("buttons.confirm")} disabled={!start || loading} key={"primary"} />,
                                            ]}
                                        />
                                    }
                                >
                                    {loadingSurvey ? (
                                        <FlexCenter>
                                            <FlexClm>
                                                <Skeleton width={"100%"} height={200} />
                                            </FlexClm>
                                        </FlexCenter>
                                    ) : (
                                        <>
                                            {!start && !readonly ? (
                                                <>
                                                    <VaporPage.Section>
                                                        <Typography variant="displaySmall" gutterBottom component="div">
                                                            {t("hello", { user: username })}
                                                        </Typography>
                                                        <Typography variant="body" gutterBottom component="div">
                                                            {t("startSurvey")}
                                                        </Typography>
                                                    </VaporPage.Section>
                                                    <VaporPage.Section>
                                                        <FlexCenter>
                                                            <Button variant="contained" size="medium" onClick={() => setStart(true)}>
                                                                {t("start")}
                                                            </Button>
                                                        </FlexCenter>
                                                    </VaporPage.Section>
                                                </>
                                            ) : (
                                                <VaporPage.Section>
                                                    {survey?.macroCategories.map((m, index) => (
                                                        <ViewMacro macro={m} key={m.id} readonly={!!readonly ? readonly : false} firstOpen={index === 0} surveyStatus={survey.status} />
                                                    ))}
                                                </VaporPage.Section>
                                            )}
                                        </>
                                    )}
                                </VaporPage>
                            </div>
                        </VaporUIShellNav>
                    </form>
                )}
            </Formik>
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle sx={{ color: "black !important" }}>
                    {t("modalUnsavedDataTitle")}
                    <IconButton onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"> {t("modalUnsavedDataDescription")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button" variant="outlined" onClick={() => setOpen(false)}>
                        {t("radioNo")}
                    </Button>
                    <LoadingBtn loading={false} msg={t("radioYes")} type="button" onClick={() => navigate(-1)} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SurveyToFill;
