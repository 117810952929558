import { Configuration, PopupRequest, SilentRequest } from "@azure/msal-browser";

let TENANT_ID = process.env.REACT_APP_TENANT_ID ?? "";
let CLIENTID = process.env.REACT_APP_CLIENTID ?? "";
let REDIRECTURI = process.env.REACT_APP_REDIRECTURI ?? "";
let SCOPE = process.env.REACT_APP_SCOPE ?? "";

export const msalConfig: Configuration = {
    auth: {
        clientId: CLIENTID, // This is the ONLY mandatory field; everything else is optional.
        authority: "https://login.microsoftonline.com/" + TENANT_ID, // Choose sign-in user-flow as your default.
        redirectUri: REDIRECTURI, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

export const loginRequest: PopupRequest = {
    scopes: [SCOPE],
};

export const tokenRequest: SilentRequest = {
    scopes: [SCOPE],
    forceRefresh: false,
};
