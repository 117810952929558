import { IconButton, Tooltip } from "@vapor/react-material";

import Pen from "@vapor/react-icons/Pen";
import { useTranslation } from "react-i18next";

export interface IEditBtnProps {
    onClick?: any;
}
const EditBtn = (props: IEditBtnProps) => {
    const { t } = useTranslation();
    const { onClick } = props;
    return (
        <Tooltip arrow title={t("buttons.edit")}>
            <IconButton
                onClick={() => {
                    if (!!onClick) onClick();
                }}
            >
                <Pen color="primary" />
            </IconButton>
        </Tooltip>
    );
};
export default EditBtn;
