import NewSurvey, { INewSurvey } from "./NewSurvey";
import { useCallback, useEffect, useState } from "react";

import LoadingPage from "../../components/loadingPage/LoadingPage";
import { PATHS } from "../../routes/routesEnum";
import { deserializeSurvey } from "../../model/deserialize";
import { getSurveyById } from "../../api/helper";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditSurvey = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [surveyToEdit, setSurveyToEdit] = useState<INewSurvey>();

    const fetchSkillById = useCallback(async () => {
        setLoading(true);
        try {
            if (!!id) {
                let response = await getSurveyById(parseInt(id));
                setSurveyToEdit(deserializeSurvey(response.data));
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchSkillById();
    }, []);

    return <>{loading ? <LoadingPage title={t("editSurvey")} path={PATHS.macrocategories} /> : <NewSurvey surveyToEdit={surveyToEdit} />}</>;
};

export default EditSurvey;
