import { ARROWS_ID, PAGE_RECORDS, normalizeStringForRest } from "../../utils/utils";
import { Button, FormControl, InputLabel, MenuItem, Pagination, Select, Stack, TextField } from "@vapor/react-material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { EOrder, Order } from "../../model/enum";
import { FilterContainer, FlexCenter, FlexContainer, FlexEnd } from "../../style/div";
import { selectMySkills, shouldFetchAllMySkills } from "../../store/slice/mySkillsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import CustomPage from "../../components/customPage/CustomPage";
import CustomSearch from "../../components/customSearch/CustomSearch";
import DeleteRow from "../../modals/deleteRow/DeleteRow";
import { ELanguages } from "../../localization/i18n";
import { EReduxState } from "../../store/model";
import EmptyState from "@vapor/react-custom/EmptyState";
import FilterBtn from "../../components/btns/FilterBtn";
import MySkillsTable from "../../components/table/MySkillsTable";
import { PATHS } from "../../routes/routesEnum";
import Search from "@vapor/react-icons/Search";
import SelectRecord from "../../components/selectRecord/SelectRecord";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MySkills = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectMySkills);
    const navigate = useNavigate();
    const [record, setRecord] = useState<number>(PAGE_RECORDS[0]);
    const [modalRemove, setModalRemove] = useState<{ show: boolean; id: number }>({ show: false, id: -1 });
    const deleteRow = useCallback(async () => {}, [modalRemove]);
    const [search, setSearch] = useState<string>("");
    const [order, setOrder] = useState<Order>(EOrder.asc);
    const [orderBy, setOrderBy] = useState<string>(i18n.language === ELanguages.it ? "skillNameIt" : "skillNameEn");
    const [sortBy, setSortBy] = useState<string>("");

    const [filterSkill, setFilterSkill] = useState<string>("");
    const [filteredSkill, setFilteredSkill] = useState<boolean>(false);

    const [filterSurvey, setFilterSurvey] = useState<string>("");
    const [filteredSurvey, setFilteredSurvey] = useState<boolean>(false);

    const [selectLastValue, setSelectLastValue] = useState<number | undefined>(undefined);
    const [filterLastValue, setFilterLastValue] = useState<string>("");
    const [filteredLastValue, setFilteredLastValue] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);

    const { status, skills } = data;

    const fetchAllSkills = () => dispatch(shouldFetchAllMySkills({ pageNumber: 1, records: record, forceCall: true, nameFilter: search, query: buildQuery() }));

    useEffect(() => {
        fetchAllSkills();
    }, [record]);

    const sort = (cellId: string, fieldName: string, isAsc: boolean) => {
        setOrder(isAsc ? EOrder.desc : EOrder.asc);
        setOrderBy(cellId);
        setSortBy(fieldName);
        setPage(1);
        dispatch(shouldFetchAllMySkills({ pageNumber: 1, sortOrder: isAsc ? EOrder.desc : EOrder.asc, sorting: fieldName, records: record, query: buildQuery(), forceCall: true }));
    };

    const buildQuery = () => {
        return `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${!!filterSkill ? `${!!filterSurvey ? ";" : ""}filterskill=${filterSkill}` : ""}${
            !!filterLastValue ? `${!!filterSurvey || !!filterSkill ? ";" : ""}filterlastvote=${filterLastValue}` : ""
        }`;
    };

    return (
        <CustomPage pageTitle={t("mySkillsTitle")}>
            {status === EReduxState.rejected ? (
                <FlexContainer>
                    <EmptyState badgeIcon description={t("error")} title={t("apiError")} />
                </FlexContainer>
            ) : (
                <>
                    <SelectRecord record={record} setRecord={setRecord} />
                    <MySkillsTable
                        editBtn={PATHS.users}
                        loading={status === EReduxState.pending}
                        headCells={[
                            { id: ARROWS_ID, label: "", numeric: false, disablePadding: false },
                            {
                                id: i18n.language === ELanguages.it ? "skillNameIt" : "skillNameEn",
                                label: t("clmSkill"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "skill",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredSkill}
                                        cleanFilter={() => {
                                            setFilteredSkill(false);
                                            setFilterSkill("");
                                            dispatch(
                                                shouldFetchAllMySkills({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                        !!filterLastValue ? `${!!filterSurvey ? ";" : ""}filterlastvote=${filterLastValue}` : ""
                                                    }`,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredSkill(true);
                                                    dispatch(
                                                        shouldFetchAllMySkills({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                                !!filterSkill ? `${!!filterSurvey ? ";" : ""}filterskill=${filterSkill}` : ""
                                                            }${!!filterLastValue ? `${!!filterSurvey || !!filterSkill ? ";" : ""}filterlastvote=${filterLastValue}` : ""}`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.skill")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        autoFocus
                                                        name="search"
                                                        onChange={(e) => {
                                                            setFilterSkill(`${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            {
                                id: i18n.language === ELanguages.it ? "surveyTitleIt" : "surveyTitleEn",
                                label: t("clmSurvey"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "survey",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredSurvey}
                                        cleanFilter={() => {
                                            setFilteredSurvey(false);
                                            setFilterSurvey("");
                                            dispatch(
                                                shouldFetchAllMySkills({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: `&filters=${!!filterSkill ? `filterskill=${filterSkill}` : ""}${
                                                        !!filterLastValue ? `${!!filterSkill ? ";" : ""}filterlastvote=${filterLastValue}` : ""
                                                    }`,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredSurvey(true);
                                                    dispatch(
                                                        shouldFetchAllMySkills({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                                !!filterSkill ? `${!!filterSurvey ? ";" : ""}filterskill=${filterSkill}` : ""
                                                            }${!!filterLastValue ? `${!!filterSurvey || !!filterSkill ? ";" : ""}filterlastvote=${filterLastValue}` : ""}`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.survey")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        name="search"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterSurvey(`${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            {
                                id: "vote",
                                label: t("clmLastValue"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "lastvote",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredLastValue}
                                        cleanFilter={() => {
                                            setFilteredLastValue(false);
                                            setFilterLastValue("");
                                            dispatch(
                                                shouldFetchAllMySkills({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: `&filters=${!!filterSkill ? `filterskill=${filterSkill}` : ""}${
                                                        !!filterSurvey ? `${!!filterSkill ? ";" : ""}filtersurvey=${filterSurvey}` : ""
                                                    }`,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredLastValue(true);
                                                    setFilterLastValue(`${selectLastValue}`);
                                                    dispatch(
                                                        shouldFetchAllMySkills({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${!!filterSurvey ? `filtersurvey=${filterSurvey}` : ""}${
                                                                !!filterSkill ? `${!!filterSurvey ? ";" : ""}filterskill=${filterSkill}` : ""
                                                            }${`${!!filterSurvey || !!filterSkill ? ";" : ""}filterlastvote=${selectLastValue}`}`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.lastValue")}</InputLabel>
                                                <FlexCenter>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectLastValue}
                                                        label={t("filter.lastValue")}
                                                        sx={{ width: "100%" }}
                                                        onChange={(e) => setSelectLastValue(e.target.value as number)}
                                                    >
                                                        <MenuItem value={0}>0</MenuItem>
                                                        <MenuItem value={1}>1</MenuItem>
                                                        <MenuItem value={2}>2</MenuItem>
                                                        <MenuItem value={3}>3</MenuItem>
                                                        <MenuItem value={4}>4</MenuItem>
                                                        <MenuItem value={5}>5</MenuItem>
                                                    </Select>
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                        ]}
                        deleteBtnClick={(id: number) => setModalRemove({ show: true, id: id })}
                        rows={status === EReduxState.pending ? Array.from(Array(record).keys()) : skills}
                        parentOrder={order}
                        fieldToOrder={orderBy}
                        parentSortAscDesc={sort}
                    />
                    <FlexEnd>
                        {data.totalPages > 1 && (
                            <Pagination
                                page={page}
                                count={data.totalPages}
                                onChange={(event: ChangeEvent<unknown>, page: number) => {
                                    setPage(page);
                                    dispatch(shouldFetchAllMySkills({ pageNumber: page, sortOrder: order, sorting: sortBy, records: record, query: buildQuery(), forceCall: true }));
                                }}
                            />
                        )}
                    </FlexEnd>
                </>
            )}
            <DeleteRow apply={deleteRow} cancel={() => setModalRemove((oldState) => ({ ...oldState, show: false }))} open={modalRemove.show} />
        </CustomPage>
    );
};

export default MySkills;
