import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, VaporThemeProvider, useTheme } from "@vapor/react-material";

import Close from "@vapor/react-icons/Xmark";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

interface IDeleteRowProps {
    cancel: any;
    apply: any;
    title?: string;
    description?: string;
    open: boolean;
    loading?: boolean;
}
const DeleteRow = (props: IDeleteRowProps) => {
    const { t } = useTranslation();
    const { cancel, apply, title, description, open, loading } = props;
    const theme = useTheme();
    const red = get(theme, "palette.error.main", "red");
    return (
        <Dialog open={open} onClose={cancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle sx={{ color: "black !important" }}>
                {!!title ? title : t("deleteModalTitle")}
                <IconButton onClick={cancel}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider variant="fullWidth" sx={{ backgroundColor: `${red} !important` }} />
            <DialogContent>
                <DialogContentText id="alert-dialog-description"> {!!description ? description : t("deleteModalDescription")}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button type="button" color="error" variant="outlined" onClick={cancel}>
                    {t("buttons.cancel")}
                </Button>
                <LoadingBtn msg={t("buttons.delete")} color={"error"} type="button" loading={loading ?? false} onClick={apply} />
            </DialogActions>
        </Dialog>
    );
};

export default DeleteRow;
