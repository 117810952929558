import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@vapor/react-material";
import { ICategoryBe, ICustomSelectOptions, IModal, INewCategory } from "../../model";
import { createCategory, getAllAssociableSkill } from "../../api/helper";
import { useCallback, useEffect, useState } from "react";

import Close from "@vapor/react-icons/Xmark";
import CustomSelect from "../../components/customSelect/CustomSelect";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import { InputDivider } from "../../style/div";
import Legends from "../../components/legend/Legend";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { emptyCategory } from "../../pages/categories/NewCategory";
import { newCategorySchema } from "../../validations";
import { openToast } from "../../store/slice/toastSlice";
import { useAppDispatch } from "../../store/hook";
import { useTranslation } from "react-i18next";

interface IModalMacroCategoryProps extends IModal {
    functionAfterCreate?: (cat: ICategoryBe) => void;
}

const ModalCategory = (props: IModalMacroCategoryProps) => {
    const { t } = useTranslation();
    const { open, setOpen, functionAfterCreate } = props;
    const dispatch = useAppDispatch();
    const [roles, setRoles] = useState<ICustomSelectOptions[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [skills, setSkills] = useState<ICustomSelectOptions[]>([]);

    const fetchSkillsForCategory = useCallback(async () => {
        try {
            const data = await getAllAssociableSkill();
            setSkills(data.data.map((sk) => ({ nameEn: sk.nameEn, nameIt: sk.nameIt, id: sk.id })));
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchSkillsForCategory();
    }, []);

    const handleDialog = () => {
        setOpen(!open);
    };

    return (
        <Dialog open={open} maxWidth="xl" fullWidth scroll="paper" onClose={handleDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <Formik
                initialValues={emptyCategory}
                validationSchema={newCategorySchema}
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    try {
                        let result = await createCategory(values);
                        dispatch(openToast({ msg: t("newSurveyForm.success") }));
                        setOpen(!open);
                        if (!!functionAfterCreate) functionAfterCreate(result.data);
                    } catch (err) {
                        dispatch(openToast({ msg: t("newSurveyForm.error"), severity: "error" }));
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogTitle>
                            {t("newCat")}
                            <IconButton color="primary" onClick={handleDialog}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <Divider variant="fullWidth" />
                        <DialogContent>
                            <InputDivider>
                                <FormikInput name="nameEn" label={t("nameEn")} required />
                                <FormikInput name="nameIt" label={t("nameIt")} required />
                            </InputDivider>
                            <InputDivider>
                                <FormikInput name="questionEn" label={t("mainEn")} required />
                                <FormikInput name="questionIt" label={t("mainIt")} required />
                            </InputDivider>
                            <Typography variant="titleMedium" color="primary.textTitleColor">
                                {t("legendTitle")}
                            </Typography>
                            <InputDivider>
                                <Legends />
                            </InputDivider>
                            <InputDivider>
                                <CustomSelect name="skills" options={skills} setOptions={setSkills} addLabel={t("addSkill")} label={t("selectSkill")} />
                            </InputDivider>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="error" type="button" onClick={handleDialog}>
                                {t("buttons.cancel")}
                            </Button>
                            <LoadingBtn loading={loading} type="submit" msg={t("buttons.save")} key={"primary"} />
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default ModalCategory;
