import CustomPage from "../../components/customPage/CustomPage";
import { ELanguages } from "../../localization/i18n";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const IFrame = styled.iframe`
    width: 100%;
    height: calc(100vh - 88px);
`;
const Guide = () => {
    const { t, i18n } = useTranslation();

    let itGuide = "https://tsspa.sharepoint.com/sites/G365-Consuntivazione721-SWEAT/_layouts/15/Doc.aspx?sourcedoc={c2d0fc45-db71-458b-8755-9e50afeafbfe}&action=embedview&wdAr=1.7777777777777777";
    let enGuide = "https://tsspa.sharepoint.com/sites/G365-Consuntivazione721-SWEAT/_layouts/15/Doc.aspx?sourcedoc={71504357-9a12-4ca7-94cd-e51e6bf5b6cd}&action=embedview&wdAr=1.7777777777777777";
    return (
        <CustomPage pageTitle={t("guide")}>
            <IFrame src={i18n.language === ELanguages.it ? itGuide : enGuide}></IFrame>
        </CustomPage>
    );
};
export default Guide;
