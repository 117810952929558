import { ELanguages, changeLanguage } from "../../localization/i18n";
import { EReduxState, IReduxState, IUserSettings, MenuType, UserRoles } from "../model";
import { LOCALSTORAGE_MENU, getMenuType, setLocalStorage } from "../../utils/utils";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { PagesTab } from "../../model/enum";
import { RootState } from "../store";

export enum userSliceAction {
    get = "userSlice/getUser",
}

interface IUser {
    userType: UserRoles;
    userSettings: IUserSettings;
    userId: string;
}

const initialState: IReduxState<IUser> = {
    status: EReduxState.initialState,
    error: undefined,
    data: { userType: UserRoles.normal, userId: "", userSettings: { selectedPage: PagesTab.survey, theme: "", language: ELanguages.it, menu: getMenuType(), profileImg: "" } },
};

export const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        setUserRole: (state, action: PayloadAction<UserRoles>) => {
            state.data.userType = action.payload;
            state.status = EReduxState.fulfilled;
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.data.userId = action.payload;
        },
        setSelectedPage: (state, action: PayloadAction<PagesTab>) => {
            state.data.userSettings.selectedPage = action.payload;
        },
        setProfileImg: (state, action: PayloadAction<string>) => {
            state.data.userSettings.profileImg = action.payload;
        },
        setLanguages: (state, action: PayloadAction<ELanguages>) => {
            state.data.userSettings.language = action.payload;
            changeLanguage(action.payload);
        },
        setMenuType: (state, action: PayloadAction<MenuType>) => {
            setLocalStorage(LOCALSTORAGE_MENU, action.payload);
            state.data.userSettings.menu = action.payload;
        },
    },
});

export const selectUser = (state: RootState) => state.userSlice;
export const selectUserSettings = (state: RootState) => state.userSlice.data.userSettings;
export const { setUserRole, setSelectedPage, setLanguages, setMenuType, setProfileImg, setUserId } = userSlice.actions;

export default userSlice.reducer;
