import { Avatar, IconButton, Menu, MenuItem } from "@vapor/react-material";
import { MenuType, UserRoles } from "../../store/model";
import { selectUser, setMenuType, setSelectedPage } from "../../store/slice/userSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import { PATHS } from "../../routes/routesEnum";
import { PagesTab } from "../../model/enum";
import User from "@vapor/react-icons/User";
import { get } from "lodash";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const NormalUserMenu = ({ handleClose }: { handleClose: () => void }) => {
    const { instance } = useMsal();

    return (
        <MenuItem
            onClick={() => {
                handleClose();
                instance.logoutRedirect();
            }}
        >
            Logout
        </MenuItem>
    );
};

const AdminMenu = ({ handleClose }: { handleClose: () => void }) => {
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectUser);
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            {data.userSettings.menu === MenuType.normal && (
                <>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            dispatch(setMenuType(MenuType.admin));
                            dispatch(setSelectedPage(PagesTab.survey));
                            navigate(PATHS.surveys);
                        }}
                    >
                        {t("adminView")}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            dispatch(setMenuType(MenuType.manager));
                            dispatch(setSelectedPage(PagesTab.myTeam));
                            navigate(PATHS.myTeam);
                            handleClose();
                        }}
                    >
                        {t("managerView")}
                    </MenuItem>
                </>
            )}
            {data.userSettings.menu === MenuType.manager && (
                <>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            dispatch(setMenuType(MenuType.admin));
                            dispatch(setSelectedPage(PagesTab.survey));
                            navigate(PATHS.surveys);
                        }}
                    >
                        {t("adminView")}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            dispatch(setSelectedPage(PagesTab.mySurveys));
                            navigate(PATHS.mySurveys);
                            dispatch(setMenuType(MenuType.normal));
                        }}
                    >
                        {t("userView")}
                    </MenuItem>
                </>
            )}
            {data.userSettings.menu === MenuType.admin && (
                <>
                    <MenuItem
                        onClick={() => {
                            dispatch(setMenuType(MenuType.manager));
                            dispatch(setSelectedPage(PagesTab.myTeam));
                            navigate(PATHS.myTeam);
                            handleClose();
                        }}
                    >
                        {t("managerView")}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            dispatch(setSelectedPage(PagesTab.mySurveys));
                            navigate(PATHS.mySurveys);
                            dispatch(setMenuType(MenuType.normal));
                        }}
                    >
                        {t("userView")}
                    </MenuItem>
                </>
            )}
            <NormalUserMenu handleClose={handleClose} />
        </>
    );
};

const ManagerMenu = ({ handleClose }: { handleClose: () => void }) => {
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectUser);
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            {data.userSettings.menu === MenuType.normal ? (
                <MenuItem
                    onClick={() => {
                        dispatch(setMenuType(MenuType.manager));
                        dispatch(setSelectedPage(PagesTab.myTeam));
                        navigate(PATHS.myTeam);
                        handleClose();
                    }}
                >
                    {t("managerView")}
                </MenuItem>
            ) : (
                <MenuItem
                    onClick={() => {
                        dispatch(setMenuType(MenuType.normal));
                        dispatch(setSelectedPage(PagesTab.mySurveys));
                        navigate(PATHS.mySurveys);
                        handleClose();
                    }}
                >
                    {t("userView")}
                </MenuItem>
            )}
            <NormalUserMenu handleClose={handleClose} />
        </>
    );
};

const CustomAvatar = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { data } = useAppSelector(selectUser);
    const profileImg = get(data, "userSettings.profileImg", "");

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton id="menu-avatar-btn" aria-haspopup="true" onClick={handleClick}>
                {!!profileImg ? (
                    <Avatar src={"data:image/png;base64," + profileImg} sx={{ width: "40px", height: "40px" }} />
                ) : (
                    <Avatar>
                        <User color="white" />
                    </Avatar>
                )}
            </IconButton>

            <Menu
                open={open}
                MenuListProps={{
                    "aria-labelledby": "menu-avatar-btn",
                }}
                anchorEl={anchorEl}
                id="menu-avatar-btn"
                onClose={handleClose}
            >
                {data.userType === UserRoles.admin && <AdminMenu handleClose={handleClose} />}
                {data.userType === UserRoles.manager && <ManagerMenu handleClose={handleClose} />}
                {data.userType === UserRoles.normal && <NormalUserMenu handleClose={handleClose} />}
            </Menu>
        </>
    );
};

export default CustomAvatar;
