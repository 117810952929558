import {
    ICategoryBe,
    IEditMacroCat,
    IEditRole,
    IEditSkill,
    IGetMySkill,
    IGetParams,
    IGetSkillForAdmin,
    IGetSkillForAdminResponse,
    ILegendBe,
    IMacroCatBe,
    INewCategory,
    INewMacroCat,
    INewRole,
    INewSkill,
    INewUser,
    IPaginationRedux,
    IRoleBe,
    ISimpleUserBe,
    ISkillBe,
    ISkillWithSurvey,
    ISurveyBe,
    ISurveyToSubmit,
    IUserBe,
    TGetSkillForAdminResponse,
} from "../model";
import httpClient, { IPagination, IRequest, IResponse, TRequestHeaders } from "./httpClient";
import { serializeNewCategory, serializeNewMacroCategory, serializeRole, serializeSurvey, serializeUser } from "../model/serialize";

import { INewSurvey } from "../pages/survey/NewSurvey";
import { STRING_TOKEN } from "../utils/utils";
import i18n from "../localization/i18n";

const Provider = () => {
    let _baseUrl = process.env.REACT_APP_API_URL ?? "";

    const _createRequest = <D>(_pathname: string, _queryString?: string, _data?: D): IRequest => {
        return {
            url: _queryString ? `${_pathname}?${_queryString}` : _pathname,
            baseURL: _baseUrl,
            data: _data,
        };
    };

    // const callbackAuthAsyncLocal = async (callback: (token: string) => Promise<IResponse<any>>) => {
    //     const token: any = await localStorage.getItem(STRING_TOKEN);
    //     try {
    //         return await callback(token);
    //     } catch (e) {
    //         throw new Error();
    //     }
    // };

    const callbackAuthAsync = async (callback: (token: string) => Promise<IResponse<any>>) => {
        const token: string | null = localStorage.getItem(STRING_TOKEN);
        try {
            if (!!token) return await callback(token);
            throw new Error();
        } catch (e) {
            throw new Error();
        }
    };
    const _createHeaders = (token: string): TRequestHeaders => {
        return { "Accept-Language": i18n.language };
    };
    const _createHeadersToken = (token: string): TRequestHeaders => {
        return { "Accept-Language": i18n.language, Authorization: `Bearer ${token}` };
    };
    const getAllSurvey = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<ISurveyBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `surveys`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const getAllDetailedSurvey = async ({ pageNumber, records, nameFilter, sortOrder }: IGetParams): Promise<IResponse<IPagination<ISurveyBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `detailed-surveys`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${sortOrder ? `&sortOrder=${sortOrder}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const createSurvey = async (survey: INewSurvey): Promise<IResponse> =>
        callbackAuthAsync((token) => httpClient.postAsync(_createRequest(`surveys`, "", serializeSurvey(survey)), _createHeaders(token)));
    const editSurvey = async (survey: INewSurvey): Promise<IResponse> =>
        callbackAuthAsync((token) => httpClient.patchAsync(_createRequest(`surveys`, "", serializeSurvey(survey)), _createHeaders(token)));
    const getSurveyById = async (id: number): Promise<IResponse<ISurveyBe>> => callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`surveys/${id}`), _createHeaders(token)));
    const getLoggedSurveyById = async (id: number): Promise<IResponse<ISurveyBe>> =>
        callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`surveys/getByLoggedUser/${id}`), _createHeaders(token)));

    const getSurveyByRoleId = async (id: number): Promise<IResponse<ISurveyBe>> => callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`surveys/getByRole/${id}`), _createHeaders(token)));
    const getAllSurveyByLoggedUser = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<ISurveyBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `surveys/getByLoggedUser`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const getSkillsForAdmin = async ({ skillId, surveyId, userId }: IGetSkillForAdmin): Promise<IResponse<TGetSkillForAdminResponse>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(`skills/getSkillsForAdmin`, `${skillId ? `skillId=${skillId}` : ""}${surveyId ? `&surveyId=${surveyId}` : ""}${userId ? `&userId=${userId}` : ""}`),
                _createHeaders(token),
            ),
        );
    const getCsvData = async (isForManager?: boolean, date?: string): Promise<IResponse<Array<string[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(_createRequest(`skills/csv`, `${isForManager ? `isForManager=true` : ""}${isForManager && date ? "&" : ""}${date ? `date=${date}` : ""}`), _createHeaders(token)),
        );

    const getSkillsForManager = async ({ skillId, surveyId, userId }: IGetSkillForAdmin): Promise<IResponse<TGetSkillForAdminResponse>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(`skills/getSkillsForManager`, `${skillId ? `skillId=${skillId}` : ""}${surveyId ? `&surveyId=${surveyId}` : ""}${userId ? `&userId=${userId}` : ""}`),
                _createHeaders(token),
            ),
        );
    const getAllSurveyForManager = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<ISurveyBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `getUserWithSurveysForManager`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const getAllSkillsForManager = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<ISkillWithSurvey[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `skillsForManager`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const deleteSurveyById = async (id: number): Promise<IResponse> => callbackAuthAsync((token) => httpClient.deleteAsync(_createRequest(`surveys/${id}`), _createHeaders(token)));
    const getAssociableSurveys = async (roleId?: number): Promise<IResponse<ISurveyBe[]>> =>
        callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`getAssociableSurveys/${!!roleId ? `?roleId=${roleId}` : ""}`), _createHeaders(token)));

    const submitSurvey = async (payload: ISurveyToSubmit): Promise<IResponse> =>
        callbackAuthAsync((token) => httpClient.postAsync(_createRequest(`surveys/submit`, "", payload), _createHeaders(token)));

    //Skills
    const getAllSkills = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<ISkillWithSurvey[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `skills`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const getSkillById = async (id: number): Promise<IResponse<ISkillBe>> => callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`skills/${id}`), _createHeaders(token)));
    const createSkill = async (skill: INewSkill): Promise<IResponse<ISkillBe>> => callbackAuthAsync((token) => httpClient.postAsync(_createRequest(`skills`, "", skill), _createHeaders(token)));
    const editSkill = async (skill: IEditSkill): Promise<IResponse<boolean>> => callbackAuthAsync((token) => httpClient.patchAsync(_createRequest(`skills`, "", skill), _createHeaders(token)));
    const deleteSkillById = async (id: number): Promise<IResponse> => callbackAuthAsync((token) => httpClient.deleteAsync(_createRequest(`skills?skillId=${id}`), _createHeaders(token)));
    const getAllAssociableSkill = async (): Promise<IResponse<ISkillBe[]>> => callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`getAssociableSkills`), _createHeaders(token)));
    const getSkillsForUsers = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<IGetMySkill[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `skills/getSkillsForUser`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );

    //Categories
    const getAllDetailedCategories = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<ICategoryBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `detailed-categories`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );

    const getAllCategories = async ({ pageNumber, records, nameFilter, sortOrder }: IGetParams): Promise<IResponse<IPagination<ICategoryBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `categories`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${sortOrder ? `&sortOrder=${sortOrder}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const getAllCategoriesNoPaginate = async (): Promise<IResponse<ICategoryBe[]>> =>
        callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`getAssociableCategories`), _createHeaders(token)));
    const getCategoryById = async (id: number): Promise<IResponse<ICategoryBe>> => callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`categories/${id}`), _createHeaders(token)));

    const createCategory = async (category: INewCategory): Promise<IResponse<ICategoryBe>> =>
        callbackAuthAsync((token) => httpClient.postAsync(_createRequest(`categories`, "", serializeNewCategory(category)), _createHeaders(token)));

    const editCategory = async (category: INewCategory): Promise<IResponse<boolean>> =>
        callbackAuthAsync((token) => httpClient.patchAsync(_createRequest(`categories`, "", serializeNewCategory(category)), _createHeaders(token)));
    const removeSkillFromCategory = async (catId: number, skillId: number): Promise<IResponse> =>
        callbackAuthAsync((token) => httpClient.putAsync(_createRequest(`categories/${catId}/remove/${skillId}`, ""), _createHeaders(token)));
    const addSkillToCategory = async (catId: number, skillId: number): Promise<IResponse> =>
        callbackAuthAsync((token) => httpClient.putAsync(_createRequest(`categories/${catId}/add/${skillId}`, ""), _createHeaders(token)));
    const deleteCategoryById = async (id: number): Promise<IResponse> => callbackAuthAsync((token) => httpClient.deleteAsync(_createRequest(`categories/${id}`), _createHeaders(token)));

    //Legends
    const getAllLegends = async ({ pageNumber, records }: IPaginationRedux): Promise<IResponse<IPagination<ILegendBe[]>>> =>
        callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`legends`, `${pageNumber ? `page=${pageNumber}` : ""}${records ? `&pageSize=${records}` : ""}`), _createHeaders(token)));
    const createLegend = async (legend: ILegendBe): Promise<IResponse> => callbackAuthAsync((token) => httpClient.postAsync(_createRequest(`legends`, "", legend), _createHeaders(token)));
    const getLegendById = async (id: number): Promise<IResponse<boolean>> => callbackAuthAsync((token) => httpClient.patchAsync(_createRequest(`legends/${id}`, ""), _createHeaders(token)));

    //Macro-Categories
    const getAllDetailedMacroCategories = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<IMacroCatBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `detailed-macrocategories`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );

    const getAllMacroCategories = async ({ pageNumber, records, nameFilter, sortOrder }: IGetParams): Promise<IResponse<IPagination<IMacroCatBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `macrocategories`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${sortOrder ? `&sortOrder=${sortOrder}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const getAllMacroCategoriesNoPaginate = async (): Promise<IResponse<IMacroCatBe[]>> =>
        callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`getAssociableMacroCategories`), _createHeaders(token)));

    const getMacroCategoryById = async (id: number): Promise<IResponse<IMacroCatBe>> =>
        callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`macrocategories/${id}`), _createHeaders(token)));

    const createMacroCategory = async (macroCat: INewMacroCat): Promise<IResponse> =>
        callbackAuthAsync((token) => httpClient.postAsync(_createRequest(`macrocategories`, "", serializeNewMacroCategory(macroCat)), _createHeaders(token)));

    const editMacroCategory = async (macroCat: IEditMacroCat): Promise<IResponse> =>
        callbackAuthAsync((token) => httpClient.patchAsync(_createRequest(`macrocategories`, "", macroCat), _createHeaders(token)));
    const removeCategoryFromMacroCategory = async (macrocatId: number, catId: number): Promise<IResponse> =>
        callbackAuthAsync((token) => httpClient.putAsync(_createRequest(`macrocategories/${macrocatId}/remove/${catId}`, ""), _createHeaders(token)));
    const addCategoryToMacroCategory = async (macrocatId: number, catId: number): Promise<IResponse> =>
        callbackAuthAsync((token) => httpClient.putAsync(_createRequest(`macrocategories/${macrocatId}/add/${catId}`, ""), _createHeaders(token)));
    const deleteMacroCategoryById = async (id: number): Promise<IResponse> => callbackAuthAsync((token) => httpClient.deleteAsync(_createRequest(`macrocategories/${id}`), _createHeaders(token)));

    //Users
    const getAllUsers = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<IUserBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `getUserWithSurveys`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const getAllUsersForCreation = async (): Promise<IResponse<ISimpleUserBe[]>> => callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`getAssociableUsers`), _createHeaders(token)));
    const createUser = async (user: INewUser): Promise<IResponse> => callbackAuthAsync((token) => httpClient.postAsync(_createRequest(`users`, "", serializeUser(user)), _createHeaders(token)));
    const updateUser = async (user: INewUser): Promise<IResponse> => callbackAuthAsync((token) => httpClient.patchAsync(_createRequest(`users`, "", serializeUser(user)), _createHeaders(token)));
    const getToken = async (token: string): Promise<IResponse<{ accessToken: string; role: string; profilePhoto: string; userId: string }>> =>
        httpClient.getAsync(_createRequest(`users/token`, ""), _createHeadersToken(token));
    const deleteUserById = async (id: string): Promise<IResponse> => callbackAuthAsync((token) => httpClient.deleteAsync(_createRequest(`users/${id}`), _createHeaders(token)));
    const getUserById = async (id: string): Promise<IResponse<IUserBe>> => callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`users/${id}`), _createHeaders(token)));

    //Roles
    const getAllRoles = async ({ pageNumber, records, nameFilter, sortOrder, query, sorting }: IGetParams): Promise<IResponse<IPagination<IRoleBe[]>>> =>
        callbackAuthAsync((token) =>
            httpClient.getAsync(
                _createRequest(
                    `roles`,
                    `${pageNumber ? `page=${pageNumber}` : ""}${sorting ? `&sorting=${sorting}` : ""}${records ? `&pageSize=${records}` : ""}${nameFilter ? `&nameFilter=${nameFilter}` : ""}${
                        sortOrder ? `&sortOrder=${sortOrder}` : ""
                    }${query ? `${query}` : ""}`,
                ),
                _createHeaders(token),
            ),
        );
    const getAllRolesNoPaginate = async (): Promise<IResponse<IRoleBe[]>> => callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`getAssociableRoles`), _createHeaders(token)));
    const createRole = async (payload: INewRole): Promise<IResponse> => callbackAuthAsync((token) => httpClient.postAsync(_createRequest(`roles`, "", serializeRole(payload)), _createHeaders(token)));
    const editRole = async (payload: IEditRole): Promise<IResponse<IRoleBe>> =>
        callbackAuthAsync((token) => httpClient.patchAsync(_createRequest(`roles`, "", serializeRole(payload)), _createHeaders(token)));
    const getRoleById = async (id: number): Promise<IResponse<IRoleBe>> => callbackAuthAsync((token) => httpClient.getAsync(_createRequest(`roles/${id}`), _createHeaders(token)));
    const deleteRoleById = async (id: number): Promise<IResponse> => callbackAuthAsync((token) => httpClient.deleteAsync(_createRequest(`roles/${id}`), _createHeaders(token)));

    return {
        getAllSurvey,
        getAllDetailedSurvey,
        createSurvey,
        editSurvey,
        getSurveyById,
        getSurveyByRoleId,
        getAllSurveyByLoggedUser,
        getSkillsForAdmin,
        getAllSurveyForManager,
        deleteSurveyById,
        submitSurvey,
        getLoggedSurveyById,
        getAssociableSurveys,
        getAllSkillsForManager,
        getSkillsForManager,

        createSkill,
        getSkillById,
        getAllSkills,
        editSkill,
        getAllAssociableSkill,
        deleteSkillById,
        getSkillsForUsers,
        getCsvData,

        getAllDetailedCategories,
        getAllCategories,
        getCategoryById,
        createCategory,
        editCategory,
        removeSkillFromCategory,
        addSkillToCategory,
        deleteCategoryById,
        getAllCategoriesNoPaginate,

        getAllLegends,
        createLegend,
        getLegendById,

        getAllDetailedMacroCategories,
        getAllMacroCategories,
        getMacroCategoryById,
        createMacroCategory,
        editMacroCategory,
        removeCategoryFromMacroCategory,
        addCategoryToMacroCategory,
        deleteMacroCategoryById,
        getAllMacroCategoriesNoPaginate,

        getAllUsers,
        createUser,
        getToken,
        deleteUserById,
        getUserById,
        updateUser,
        getAllUsersForCreation,

        getAllRoles,
        createRole,
        editRole,
        getRoleById,
        deleteRoleById,
        getAllRolesNoPaginate,
    };
};
export default Provider();
