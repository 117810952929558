import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import axios, { AxiosHeaderValue, AxiosHeaders, AxiosRequestConfig, AxiosResponseHeaders, HttpStatusCode, Method } from "axios";

import { STRING_TOKEN } from "../utils/utils";
import { _msal } from "../msal/AdHelper";
import { getUserToken } from "./helper";
import { tokenRequest } from "../msal/azureAd";

export const StatusCode = {
    ...HttpStatusCode,
};

export interface IRequest<T = any> {
    url: string;
    baseURL: string;
    data?: T;
}

export interface IResponse<D = any> {
    data: D;
    status: number;
    statusText: string;
    headers: TResponseHeaders;
    config: IRequestConfig;
    request?: any;
}
export interface IPagination<T> {
    items: T;
    page: number;
    pageSize: number;
    totalItems: number;
    hasNextPage: boolean;
    totalPages: number;
}

export type TRequestHeaders = Record<string, AxiosHeaderValue | AxiosHeaders>;

interface IRequestConfig extends AxiosRequestConfig {}

type TResponseHeaders = AxiosResponseHeaders;

type TMethod = Method;

const httpClient = () => {
    const _instance = axios.create({
        timeout: parseInt(process.env.TIMEOUT_HTTPCLIENT as string),
    });

    const _createHeaders = (headers?: TRequestHeaders) => {
        return {
            [process.env.CONTENT_TYPE as string]: headers?.contentType || "application/json",
            ...headers,
        };
    };
    _instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem(STRING_TOKEN);

    const _createRequest = <T = any>(method: TMethod, request: IRequest<T>, headers?: TRequestHeaders): AxiosRequestConfig<T> => {
        return {
            method: method,
            baseURL: request.baseURL,
            url: request.url,
            data: request.data,
            headers: _createHeaders(headers),
        };
    };

    const refreshToken = async (error: any) => {
        const originalRequest = error.config;

        originalRequest._retry = true;
        let request: SilentRequest = {
            ...tokenRequest,
            account: _msal.getActiveAccount() as AccountInfo,
        };
        let tokenSilent = await _msal.acquireTokenSilent(request);
        let token = await getUserToken(tokenSilent.accessToken);
        localStorage.setItem(STRING_TOKEN, token.data.accessToken);
        _instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem(STRING_TOKEN);
        return _instance.request({ ...originalRequest, headers: { ...originalRequest.headers, Authorization: "Bearer " + token.data.accessToken } });
    };

    _instance.interceptors.response.use(
        (res) => res,
        async (error) => {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                let response = await refreshToken(error);
                return response;
            }
            if (error.response.status === 400 && error.config.url.includes("RefreshToken")) {
                // let response = await refreshToken(error);
                // return response;
                _msal.logoutRedirect();
            }
            return Promise.reject(error);
        },
    );

    const getAsync = async <D = any>(request: IRequest, headers?: TRequestHeaders): Promise<IResponse<D>> => {
        return _instance.request(_createRequest("get", request, headers));
    };

    const patchAsync = async <T = any, D = any>(request: IRequest<T>, headers?: TRequestHeaders): Promise<IResponse<D>> => {
        return _instance.request(_createRequest("patch", request, headers));
    };

    const postAsync = async <T = any, D = any>(request: IRequest<T>, headers?: TRequestHeaders): Promise<IResponse<D>> => {
        return _instance.request(_createRequest("post", request, headers));
    };

    const putAsync = async <T = any, D = any>(request: IRequest<T>, headers?: TRequestHeaders): Promise<IResponse<D>> => {
        return _instance.request(_createRequest("put", request, headers));
    };

    const deleteAsync = async <D = any>(request: IRequest, headers?: TRequestHeaders): Promise<IResponse<D>> => {
        return _instance.request(_createRequest("delete", request, headers));
    };

    return {
        getAsync,
        patchAsync,
        postAsync,
        putAsync,
        deleteAsync,
    };
};

export default httpClient();
