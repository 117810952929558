import { Button, IconButton } from "@vapor/react-material";
import { ICustomSelectOptions, IEditRole, INewRole } from "../../model";
import { InputDivider, InputFlexTop } from "../../style/div";
import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";
import { createRole, editRole, getAssociableSurveys } from "../../api/helper";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ArrowLeft from "@vapor/react-icons/ArrowLeft";
import ConfirmModal from "../../modals/confirm/ConfirmModal";
import CustomSelect from "../../components/customSelect/CustomSelect";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import GenericCancelModalWithBtn from "../../modals/genericCancel/GenericCancelModalWithBtn";
import Header from "../../components/header/Header";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import { PATHS } from "../../routes/routesEnum";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { newRoleSchema } from "../../validations";
import { openToast } from "../../store/slice/toastSlice";
import { sleep } from "../../utils/utils";
import { useAppDispatch } from "../../store/hook";
import { useTranslation } from "react-i18next";

export const defaultRole: INewRole = { roleNameIt: "", roleNameEn: "", surveyIds: [] };

interface INewRoleProps {
    roleToEdit?: IEditRole;
}

const NewRole = (props: INewRoleProps) => {
    const { roleToEdit } = props;
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

    const [surveys, setSurveys] = useState<ICustomSelectOptions[]>([]);
    const fetchSurveyForRoles = useCallback(async () => {
        try {
            const data = await getAssociableSurveys(!!id ? parseInt(id) : undefined);
            setSurveys(data.data.map((s) => ({ nameEn: s.titleEn, nameIt: s.titleIt, id: s.id })));
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchSurveyForRoles();
    }, []);

    const submit = useCallback(async (values: INewRole) => {
        setLoading(true);
        try {
            if (!!roleToEdit && !!roleToEdit.id) {
                await editRole({ ...values, id: roleToEdit.id });
            } else {
                await createRole(values);
            }
            dispatch(openToast({ msg: t("newSurveyForm.success") }));
            navigate(PATHS.roles);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <Formik
                initialValues={!!roleToEdit ? roleToEdit : defaultRole}
                validationSchema={newRoleSchema}
                onSubmit={(values, actions) => {
                    submit(values);
                }}
            >
                {({ handleSubmit, submitForm }) => (
                    <form onSubmit={handleSubmit}>
                        <VaporPage
                            title={!!roleToEdit ? t("editRole") : t("newRole")}
                            subtitle=""
                            headerLeft={
                                <IconButton onClick={() => navigate(PATHS.roles)}>
                                    <ArrowLeft color="primary" />
                                </IconButton>
                            }
                            contentToolbar={
                                <VaporToolbar
                                    size="large"
                                    contentRight={[
                                        <GenericCancelModalWithBtn cancel={() => navigate(PATHS.roles)} />,
                                        <LoadingBtn
                                            loading={!roleToEdit && loading}
                                            disabled={loading}
                                            type={!!roleToEdit ? "button" : "submit"}
                                            msg={t("buttons.save")}
                                            key={"primary"}
                                            onClick={!!roleToEdit ? () => setOpenConfirmModal(true) : undefined}
                                        />,
                                    ]}
                                />
                            }
                        >
                            <VaporPage.Section divider>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("newSurveySection1")}
                                </Typography>
                                <InputFlexTop>
                                    <FormikInput name="roleNameEn" label={t("nameEn")} required />
                                    <FormikInput name="roleNameIt" label={t("nameIt")} required />
                                </InputFlexTop>
                            </VaporPage.Section>
                            <VaporPage.Section>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("surveyTitle")}
                                </Typography>
                                <InputDivider>
                                    <CustomSelect name="surveyIds" withRowSelector options={surveys} setOptions={setSurveys} addLabel={t("addSurvey")} label={t("selectSurveys")} />
                                </InputDivider>
                            </VaporPage.Section>
                        </VaporPage>
                        <ConfirmModal apply={submitForm} name={t("roleName")} cancel={() => setOpenConfirmModal(false)} open={openConfirmModal} loading={loading} />
                    </form>
                )}
            </Formik>
        </VaporUIShellNav>
    );
};

export default NewRole;
