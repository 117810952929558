import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton } from "@vapor/react-material";

import Close from "@vapor/react-icons/Xmark";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import { useTranslation } from "react-i18next";

interface IConfirmModalProps {
    cancel: any;
    apply: any;
    title?: string;
    description?: string;
    open: boolean;
    loading: boolean;
    name: string;
}
const ConfirmModal = (props: IConfirmModalProps) => {
    const { t } = useTranslation();
    const { cancel, apply, title, description, open, name, loading } = props;
    return (
        <Dialog open={open} onClose={cancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle>
                {!!title ? title : t("confirmModalTile")}
                <IconButton color="primary" onClick={cancel}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider variant="fullWidth" />
            <DialogContent>
                <DialogContentText id="alert-dialog-description"> {!!description ? description : t("confirmModalDescription", { elementName: name })}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button type="button" variant="outlined" color="error" onClick={cancel}>
                    {t("buttons.cancel")}
                </Button>
                <LoadingBtn type="button" onClick={apply} loading={loading} msg={t("buttons.save")} />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;
