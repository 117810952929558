import { ACTION_ID, ACTION_VIEW_ID, ACTION_WITH_VIEW_ID, STATUS_ID } from "../../utils/utils";
import { EOrder, Order } from "../../model/enum";
import { FlexEnd, FlexStart } from "../../style/div";
import { IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, styled } from "@vapor/react-material";

import ArrowRight from "@vapor/react-icons/ArrowRight";
import BannerStatus from "../BannerStatus/BannerStatus";
import DeleteBtn from "../btns/DeleteBtn";
import { ELanguages } from "../../localization/i18n";
import EditBtn from "../btns/EditBtn";
import EyeBtn from "../btns/EyeBtn";
import { IHeadCells } from "../../model";
import { ModalDetailSurvey } from "./CollapsibleTable";
import { PATHS } from "../../routes/routesEnum";
import Typography from "@vapor/react-extended/Typography";
import { get } from "lodash";
import { selectUser } from "../../store/slice/userSlice";
import { useAppSelector } from "../../store/hook";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface IGenericTableProps {
    headCells: IHeadCells[];
    rows: any[];
    loading: boolean;
    editBtn?: PATHS;
    deleteBtnClick?: (id: number) => void;
    eyeBtn?: (id: number, type: any) => void;
    clickableRow?: Function;
    fieldToOrder?: string;
    parentOrder?: EOrder;
    parentSortAscDesc?: Function;
}

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const GenericTable = (props: IGenericTableProps) => {
    const { headCells, rows, loading, editBtn, deleteBtnClick, eyeBtn, clickableRow, parentOrder, fieldToOrder, parentSortAscDesc } = props;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [order, setOrder] = useState<Order>(parentOrder ?? EOrder.asc);
    const [orderBy, setOrderBy] = useState<string>(fieldToOrder ?? "");
    const userRole: any = useAppSelector(selectUser);

    const handleClick = (event: any, name: string, id: number) => {
        let targetId = event.target.id;
        if (!!clickableRow && targetId.includes("enhanced-table-checkbox")) clickableRow(id);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string, queryName: string) => {
        const isAsc = orderBy === property && order === EOrder.asc;
        if (!!parentSortAscDesc) {
            parentSortAscDesc(property, queryName, isAsc);
            setOrderBy(property);
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
        } else {
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
            setOrderBy(property);
        }
    };

    let cellToRender = headCells.map((item) => item.id);

    return (
        <TableContainer component={Paper}>
            <Table
                sx={{
                    minWidth: 700,
                    cursor: clickableRow ? "pointer" : "",
                }}
                aria-label="customized table"
            >
                <TableHead>
                    <TableRow key={1}>
                        {headCells.map((headCell, index) => (
                            <TableCell key={headCell.id + index} sortDirection={orderBy === headCell.id ? order : false}>
                                <FlexStart>
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : EOrder.asc}
                                        onClick={(event) => handleRequestSort(event, headCell.id as string, headCell?.queryName ?? "")}
                                    >
                                        <Typography variant="bodyLarge700" key={headCell.id + index} component="div">
                                            {headCell.label}
                                        </Typography>
                                    </TableSortLabel>
                                    {!!headCell.filter && headCell.filter}
                                </FlexStart>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any, index: number) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <StyledTableRow hover onClick={(event: any) => handleClick(event, row.name, row.id)} tabIndex={-1} key={labelId}>
                                {cellToRender.map((key, index) => (
                                    <TableCell component="th" id={labelId} scope="row" key={`enhanced-table-cell-${index}`}>
                                        {loading ? (
                                            <Skeleton variant="rectangular" />
                                        ) : (
                                            <>
                                                {key === ACTION_ID && (
                                                    <FlexEnd>
                                                        <DeleteBtn
                                                            onClick={() => {
                                                                if (!!deleteBtnClick) deleteBtnClick(row.id);
                                                            }}
                                                        />

                                                        {!!editBtn && <EditBtn onClick={() => navigate(`${editBtn}/${row.id}`)} />}
                                                    </FlexEnd>
                                                )}
                                                {key === ACTION_WITH_VIEW_ID && (
                                                    <FlexEnd>
                                                        <DeleteBtn
                                                            onClick={() => {
                                                                if (!!deleteBtnClick) deleteBtnClick(row.id);
                                                            }}
                                                        />
                                                        {!!editBtn && <EditBtn onClick={() => navigate(`${editBtn}/${row.id}`)} />}
                                                        <EyeBtn
                                                            onClick={() => {
                                                                if (!!eyeBtn) eyeBtn(row.id, { ...row });
                                                            }}
                                                        />
                                                    </FlexEnd>
                                                )}
                                                {key === ACTION_VIEW_ID && (
                                                    <FlexEnd>
                                                        {!!clickableRow ? (
                                                            <>
                                                                <Tooltip arrow title={t("arrowTooltip")}>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            if (!!clickableRow) clickableRow(row.id);
                                                                        }}
                                                                    >
                                                                        <ArrowRight color="primary" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <ModalDetailSurvey
                                                                    title={`${i18n.language === ELanguages.en ? row.titleEn : row.titleIt}`}
                                                                    surveyId={row.id}
                                                                    userId={get(userRole, "data.userId", "")}
                                                                />
                                                            </>
                                                        ) : (
                                                            <EyeBtn
                                                                onClick={() => {
                                                                    if (!!eyeBtn) eyeBtn(row.id, { ...row });
                                                                }}
                                                            />
                                                        )}
                                                    </FlexEnd>
                                                )}
                                                {key === STATUS_ID && <BannerStatus row={row} keyToSearch={key} />}
                                                {key !== STATUS_ID && key !== ACTION_ID && <Typography variant="bodyLarge500">{get(row, `${key}`, "")}</Typography>}
                                            </>
                                        )}
                                    </TableCell>
                                ))}
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default GenericTable;
