import { useCallback, useEffect, useState } from "react";

import { IEditSkill } from "../../model";
import LoadingPage from "../../components/loadingPage/LoadingPage";
import NewSkill from "./NewSkills";
import { PATHS } from "../../routes/routesEnum";
import { deSerializeSkillBe } from "../../model/deserialize";
import { getSkillById } from "../../api/helper";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditSkill = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [skillToEdit, setSkillToEdit] = useState<IEditSkill>();

    const fetchSkillById = useCallback(async () => {
        setLoading(true);
        try {
            if (!!id) {
                let response = await getSkillById(parseInt(id));
                setSkillToEdit(deSerializeSkillBe(response.data));
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchSkillById();
    }, []);

    return <>{loading ? <LoadingPage title={t("editSkill")} path={PATHS.macrocategories} /> : <NewSkill skillToEdit={skillToEdit} />}</>;
};

export default EditSkill;
