import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton } from "@vapor/react-material";

import Close from "@vapor/react-icons/Xmark";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IGenericCancelModalWithBtnProps {
    cancel: any;
    disabled?: boolean;
}
const GenericCancelModalWithBtn = (props: IGenericCancelModalWithBtnProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const { cancel, disabled } = props;
    return (
        <>
            <Button type="button" variant="outlined" color="error" onClick={() => setOpen(true)} disabled={disabled}>
                {t("buttons.cancel")}
            </Button>
            <Dialog open={open} onClose={cancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle sx={{ color: "black !important" }}>
                    {t("modalUnsavedDataTitle")}
                    <IconButton onClick={cancel}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"> {t("modalUnsavedDataDescription")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button" variant="outlined" onClick={() => setOpen(false)}>
                        {t("radioNo")}
                    </Button>
                    <LoadingBtn loading={false} msg={t("radioYes")} type="button" onClick={cancel} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GenericCancelModalWithBtn;
