import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";

import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import { PATHS } from "../../routes/routesEnum";
import { get } from "lodash";
import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
    const error = useRouteError();
    const { t } = useTranslation();
    const statusText = get(error, "statusText", "");
    const msg = get(error, "message", "");

    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <VaporPage title={t("errorPage")} subtitle="" headerLeft={<Link to={PATHS.home}>{t("backToHome")}</Link>} contentToolbar={<VaporToolbar size="large" contentRight={[]} />}>
                <VaporPage.Section>
                    <h2>{t("errorPage")}</h2>
                    <p>
                        {!!msg && msg}
                        {!!statusText && statusText}
                    </p>
                    <Link to={PATHS.home}>{t("backToHome")}</Link>
                </VaporPage.Section>
            </VaporPage>
        </VaporUIShellNav>
    );
};

export default ErrorPage;
