import { EReduxState, IReduxState } from "../model";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

export type AlertColor = "success" | "info" | "warning" | "error";

interface IToast {
    severity: AlertColor;
    msg: string;
}
interface IOpenToast {
    msg: string;
    severity?: AlertColor;
}

const initialState: IReduxState<IToast> = {
    status: EReduxState.initialState,
    error: undefined,
    data: { severity: "success", msg: "" },
};

export const toastSlice = createSlice({
    name: "toastSlice",
    initialState,
    reducers: {
        openToast: (state, action: PayloadAction<IOpenToast>) => {
            state.data.msg = action.payload.msg;
            state.data.severity = action.payload.severity ?? "success";
        },
        closeToast: (state, action: PayloadAction) => {
            state.data.msg = "";
        },
    },
});

export const selectToast = (state: RootState) => state.toastSlice;

export const { openToast, closeToast } = toastSlice.actions;

export default toastSlice.reducer;
