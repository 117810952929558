import { IEditMacroCat, IEditRole, IEditSkill, IGetParams, IGetSkillForAdmin, ILegendBe, INewCategory, INewMacroCat, INewRole, INewSkill, INewUser, ISurveyToSubmit } from "../model";

import { INewSurvey } from "../pages/survey/NewSurvey";
import Provider from "./provider";

export const getAllSurvey = async (payload: IGetParams) => Provider.getAllSurvey(payload);
export const getAllDetailedSurvey = async (payload: IGetParams) => Provider.getAllDetailedSurvey(payload);
export const createSurvey = async (payload: INewSurvey) => Provider.createSurvey(payload);
export const editSurvey = async (payload: any) => Provider.editSurvey(payload);
export const getSurveyById = async (id: number) => Provider.getSurveyById(id);
export const getLoggedSurveyById = async (id: number) => Provider.getLoggedSurveyById(id);
export const getSurveyByRoleId = async (id: number) => Provider.getSurveyByRoleId(id);
export const getAllSurveyByLoggedUser = async (payload: IGetParams) => Provider.getAllSurveyByLoggedUser(payload);
export const getSkillsForAdmin = async (payload: IGetSkillForAdmin) => Provider.getSkillsForAdmin(payload);
export const getAllSurveyForManager = async (payload: IGetParams) => Provider.getAllSurveyForManager(payload);
export const deleteSurveyById = async (id: number) => Provider.deleteSurveyById(id);
export const submitSurvey = async (payload: ISurveyToSubmit) => Provider.submitSurvey(payload);
export const getAssociableSurveys = async (id?: number) => Provider.getAssociableSurveys(id);

export const getAllSkills = async (payload: IGetParams) => Provider.getAllSkills(payload);
export const getSkillById = async (id: number) => Provider.getSkillById(id);
export const editSkill = async (payload: IEditSkill) => Provider.editSkill(payload);
export const createSkill = async (payload: INewSkill) => Provider.createSkill(payload);
export const deleteSkillById = async (id: number) => Provider.deleteSkillById(id);
export const getAllAssociableSkill = async () => Provider.getAllAssociableSkill();
export const getSkillsForUsers = async (payload: IGetParams) => Provider.getSkillsForUsers(payload);
export const getAllSkillsForManager = async (payload: IGetParams) => Provider.getAllSkillsForManager(payload);
export const getSkillsForManager = async (payload: IGetSkillForAdmin) => Provider.getSkillsForManager(payload);

export const getAllDetailedCategories = async (payload: IGetParams) => Provider.getAllDetailedCategories(payload);
export const getAllCategories = async (payload: IGetParams) => Provider.getAllCategories(payload);
export const getCategoryById = async (id: number) => Provider.getCategoryById(id);
export const createCategory = async (category: INewCategory) => Provider.createCategory(category);
export const editCategory = async (payload: INewCategory) => Provider.editCategory(payload);
export const removeSkillFromCategory = async (catId: number, skillId: number) => Provider.removeSkillFromCategory(catId, skillId);
export const addSkillToCategory = async (catId: number, skillId: number) => Provider.addSkillToCategory(catId, skillId);
export const deleteCategoryById = async (id: number) => Provider.deleteCategoryById(id);
export const getAllCategoriesNoPaginate = async () => Provider.getAllCategoriesNoPaginate();

export const getCsvData = async (isForManager?: boolean, date?: string) => Provider.getCsvData(isForManager, date);

export const getAllLegends = async (payload: IGetParams) => Provider.getAllLegends(payload);
export const createLegend = async (legend: ILegendBe) => Provider.createLegend(legend);
export const getLegendById = async (id: number) => Provider.getLegendById(id);

export const getAllDetailedMacroCategories = async (payload: IGetParams) => Provider.getAllDetailedMacroCategories(payload);
export const getAllMacroCategories = async (payload: IGetParams) => Provider.getAllMacroCategories(payload);
export const getMacroCategoryById = async (id: number) => Provider.getMacroCategoryById(id);
export const createMacroCategory = async (payload: INewMacroCat) => Provider.createMacroCategory(payload);
export const editMacroCategory = async (macroCat: IEditMacroCat) => Provider.editMacroCategory(macroCat);
export const removeCategoryFromMacroCategory = async (macrocatId: number, catId: number) => Provider.removeCategoryFromMacroCategory(macrocatId, catId);
export const addCategoryToMacroCategory = async (macrocatId: number, catId: number) => Provider.addCategoryToMacroCategory(macrocatId, catId);
export const deleteMacroCategoryById = async (id: number) => Provider.deleteMacroCategoryById(id);
export const getAllMacroCategoriesNoPaginate = async () => Provider.getAllMacroCategoriesNoPaginate();

export const getAllUsers = async (payload: IGetParams) => Provider.getAllUsers(payload);
export const getAllUsersForCreation = async () => Provider.getAllUsersForCreation();
export const createUser = async (user: INewUser) => Provider.createUser(user);
export const updateUser = async (user: INewUser) => Provider.updateUser(user);
export const getUserToken = async (token: string) => Provider.getToken(token);
export const deleteUserById = async (id: string) => Provider.deleteUserById(id);
export const getUserById = async (id: string) => Provider.getUserById(id);

export const getAllRoles = async (payload: IGetParams) => Provider.getAllRoles(payload);
export const getRoleById = async (id: number) => Provider.getRoleById(id);
export const editRole = async (payload: IEditRole) => Provider.editRole(payload);
export const createRole = async (payload: INewRole) => Provider.createRole(payload);
export const deleteRoleById = async (id: number) => Provider.deleteRoleById(id);
export const getAllRolesNoPaginate = async () => Provider.getAllRolesNoPaginate();
