import { ACTION_ID, ACTION_VIEW_ID, ARROWS_ID, STATUS_ID, formatDate } from "../../utils/utils";
import { Box, Collapse, IconButton, Paper, Skeleton, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@vapor/react-material";
import { EOrder, Order } from "../../model/enum";
import { FlexEnd, FlexStart } from "../../style/div";
import { IGenericTableProps, StyledTableRow } from "./Table";

import AngleDown from "@vapor/react-icons/AngleDown";
import AngleUp from "@vapor/react-icons/AngleUp";
import BannerStatus from "../BannerStatus/BannerStatus";
import CheckCircle from "@vapor/react-icons/CheckCircle";
import EyeBtn from "../btns/EyeBtn";
import { IGetMySkill } from "../../model";
import Typography from "@vapor/react-extended/Typography";
import { get } from "lodash";
import { useState } from "react";

export interface ICollapsibleTableProps extends IGenericTableProps {}

interface IRowProps extends IGenericTableProps {
    key: number | string;
    index: number;
    loading: boolean;
    row: IGetMySkill;
}

const Row = (props: IRowProps) => {
    const { index, loading, eyeBtn, row, headCells } = props;
    const labelId = `enhanced-table-checkbox-${index}`;
    const [open, setOpen] = useState(false);
    let cellToRender = headCells.map((item) => item.id);
    let stepper = get(row, "histories", []);
    let stepperArray = stepper.map((data: any) => ({ value: `${data.vote}`, date: formatDate(data.date) }));
    return (
        <>
            <StyledTableRow hover onClick={(event: any) => {}} tabIndex={-1} key={row.skillId}>
                {cellToRender.map((key, index) => (
                    <TableCell component="th" id={labelId} padding={key === ARROWS_ID ? "checkbox" : "normal"} scope="row" key={`enhanced-table-cell-${index}`}>
                        {loading ? (
                            <Skeleton variant="rectangular" />
                        ) : (
                            <>
                                {key === ARROWS_ID && (
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        {open ? <AngleUp /> : <AngleDown />}
                                    </IconButton>
                                )}
                                {key === ACTION_VIEW_ID && (
                                    <FlexEnd>
                                        <EyeBtn
                                            onClick={() => {
                                                if (!!eyeBtn) eyeBtn(row.skillId, { ...row });
                                            }}
                                        />
                                    </FlexEnd>
                                )}
                                {key === STATUS_ID && <BannerStatus row={row} keyToSearch={key} />}
                                {key !== STATUS_ID && key !== ACTION_ID && <Typography variant="bodyLarge500">{get(row, `${key}`, "")}</Typography>}
                            </>
                        )}
                    </TableCell>
                ))}
            </StyledTableRow>

            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} borderBottom={false}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: "16px 0", width: 104 * stepperArray.length }}>
                        <Stepper activeStep={stepperArray.length} alternativeLabel>
                            {stepperArray.map((data, index) => (
                                <Step key={index}>
                                    <StepLabel
                                        icon={<CheckCircle iconWeight="solid" color="success" size="32px" />}
                                        optional={
                                            <Typography variant="bodySmall" component="span">
                                                {data.date}
                                            </Typography>
                                        }
                                    >
                                        {data.value}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Collapse>
            </TableCell>
        </>
    );
};

const MySkillsTable = (props: ICollapsibleTableProps) => {
    const { headCells, rows, parentOrder, fieldToOrder, parentSortAscDesc } = props;
    const [order, setOrder] = useState<Order>(parentOrder ?? EOrder.asc);
    const [orderBy, setOrderBy] = useState<string>(fieldToOrder ?? "");
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string, queryName: string) => {
        const isAsc = orderBy === property && order === EOrder.asc;
        if (!!parentSortAscDesc) {
            parentSortAscDesc(property, queryName, isAsc);
            setOrderBy(property);
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
        } else {
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
            setOrderBy(property);
        }
    };
    return (
        <TableContainer component={Paper}>
            <Table
                sx={{
                    minWidth: 700,
                }}
                aria-label="customized table"
            >
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell, index) => (
                            <TableCell key={headCell.id + index} sortDirection={orderBy === headCell.id ? order : false}>
                                {headCell.label && (
                                    <FlexStart>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : EOrder.asc}
                                            onClick={(event) => handleRequestSort(event, headCell.id as string, headCell?.queryName ?? "")}
                                        >
                                            <Typography variant="bodyLarge700" key={headCell.id + index} component="div">
                                                {headCell.label}
                                            </Typography>
                                        </TableSortLabel>
                                        {!!headCell.filter && headCell.filter}
                                    </FlexStart>
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any, index: number) => (
                        <Row row={row} index={index} {...props} key={index} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MySkillsTable;
