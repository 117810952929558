import { Button } from "@vapor/react-material";
import styled from "styled-components";

export const BtnFlexIcon = styled.button<{ $withMargin?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    gap: 8px;
    border: none;
    background-color: transparent;
    cursor: pointer;
`;

export const StyledCsvBtn = styled(Button)`
    &:focus {
        color: hsl(200, 100%, 42%);
        background-color: white;
        border: 1px solid hsl(200, 100%, 42%);
    }
`;
export const StyledCsvBtnManager = styled(Button)`
    &:focus {
        color: #ffffff;
        background: linear-gradient(90deg, #00bfe6 29.58%, #0090d1 100%);
        text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
    }
`;
