import { ELanguages } from "../../localization/i18n";
import { PagesTab } from "../../model/enum";

export enum EReduxState {
    initialState = "initialState",
    pending = "pending",
    fulfilled = "fulfilled",
    rejected = "rejected",
}

export interface IReduxState<T> {
    status?: EReduxState;
    data: T;
    error: any;
}
export enum MenuType {
    normal = 1,
    admin,
    manager,
}

export enum UserRoles {
    admin = "Admin",
    manager = "Manager",
    normal = "User",
}

export interface IUserSettings {
    language: ELanguages;
    theme: string;
    selectedPage: PagesTab;
    menu: MenuType;
    profileImg?: string;
}
