import { ACTION_ID, PAGE_RECORDS, normalizeStringForRest } from "../../utils/utils";
import { Button, FormControl, InputLabel, Pagination, TextField } from "@vapor/react-material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { EOrder, Order } from "../../model/enum";
import { FilterContainer, FlexCenter, FlexContainer, FlexEnd } from "../../style/div";
import { deleteMacroCategory, selectMacros, shouldFetchAllMacro } from "../../store/slice/macroSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import CustomPage from "../../components/customPage/CustomPage";
import DeleteRow from "../../modals/deleteRow/DeleteRow";
import { ELanguages } from "../../localization/i18n";
import { EReduxState } from "../../store/model";
import EmptyState from "@vapor/react-custom/EmptyState";
import FilterBtn from "../../components/btns/FilterBtn";
import GenericTable from "../../components/table/Table";
import { PATHS } from "../../routes/routesEnum";
import Search from "@vapor/react-icons/Search";
import SelectRecord from "../../components/selectRecord/SelectRecord";
import { deleteMacroCategoryById } from "../../api/helper";
import { openToast } from "../../store/slice/toastSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MacroCat = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectMacros);
    const navigate = useNavigate();
    const [record, setRecord] = useState<number>(PAGE_RECORDS[0]);
    const [search, setSearch] = useState<string>("");
    const [loadingRemove, setLoadingRemove] = useState<boolean>(false);
    const { status, macros } = data;
    const [modalRemove, setModalRemove] = useState<{ show: boolean; id: number }>({ show: false, id: -1 });
    const [page, setPage] = useState<number>(1);

    const [order, setOrder] = useState<Order>(EOrder.asc);
    const [orderBy, setOrderBy] = useState<string>(i18n.language === ELanguages.it ? "nameIt" : "nameEn");
    const [sortBy, setSortBy] = useState<string>("");

    const [filterQuery, setFilterQuery] = useState<string>("");

    const [filterSurvey, setFilterSurvey] = useState<string>("");
    const [filterMacro, setFilterMacro] = useState<string>("");

    const [filter, setFilter] = useState<boolean>(false);
    const [filteredSurvey, setFilteredSurvey] = useState<boolean>(false);

    const deleteRow = useCallback(async () => {
        setLoadingRemove(true);
        try {
            if (!!modalRemove.id) {
                let response = await deleteMacroCategoryById(modalRemove.id);
                dispatch(openToast({ msg: t("newSurveyForm.success") }));
                dispatch(deleteMacroCategory(modalRemove.id));
                setModalRemove((oldState) => ({ ...oldState, show: false }));
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingRemove(false);
        }
    }, [modalRemove]);
    const fetchAllMacros = useCallback(async () => {
        dispatch(shouldFetchAllMacro({ pageNumber: 1, records: record, forceCall: true, nameFilter: search, query: filterQuery }));
    }, [record, filterQuery]);

    useEffect(() => {
        fetchAllMacros();
    }, [record, search]);

    const sort = useCallback(
        (cellId: string, fieldName: string, isAsc: boolean) => {
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
            setOrderBy(cellId);
            setSortBy(fieldName);
            setPage(1);
            dispatch(shouldFetchAllMacro({ pageNumber: 1, sortOrder: isAsc ? EOrder.desc : EOrder.asc, sorting: fieldName, records: record, query: filterQuery, forceCall: true }));
        },
        [filterQuery, record],
    );
    return (
        <CustomPage pageTitle={t("macroTitle")}>
            <FilterContainer>
                <SelectRecord record={record} setRecord={setRecord} />

                <Button variant="contained" onClick={() => navigate(PATHS.newMacrocategories)}>
                    {t("newMacro")}
                </Button>
            </FilterContainer>
            {status === EReduxState.rejected ? (
                <FlexContainer>
                    <EmptyState badgeIcon description={t("error")} title={t("apiError")} />
                </FlexContainer>
            ) : (
                <>
                    <GenericTable
                        editBtn={PATHS.macrocategories}
                        loading={status === EReduxState.pending}
                        headCells={[
                            {
                                id: i18n.language === ELanguages.it ? "nameIt" : "nameEn",
                                label: t("clmName"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "macrocategory",
                                filter: (
                                    <FilterBtn
                                        showDelete={filter}
                                        cleanFilter={() => {
                                            setFilter(false);
                                            setFilterQuery(!!filterSurvey ? `&filters=${filterSurvey}` : "");
                                            setFilterMacro("");
                                            dispatch(
                                                shouldFetchAllMacro({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: !!filterSurvey ? `&filters=${filterSurvey}` : "",
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilter(true);
                                                    setFilterQuery(`&filters=${!!filterSurvey ? `${filterSurvey};` : ""}${filterMacro}`);
                                                    dispatch(
                                                        shouldFetchAllMacro({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${!!filterSurvey ? `${filterSurvey};` : ""}${filterMacro}`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.name")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        name="search"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterMacro(`filtermacrocategory=${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            {
                                id: i18n.language === ELanguages.it ? "surveyIt" : "surveyEn",
                                label: t("clmSurvey"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "survey",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredSurvey}
                                        cleanFilter={() => {
                                            setFilteredSurvey(false);
                                            setFilterQuery(!!filterMacro ? `&filters=${filterMacro}` : "");
                                            setFilterSurvey("");
                                            dispatch(
                                                shouldFetchAllMacro({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: !!filterMacro ? `&filters=${filterMacro}` : "",
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredSurvey(true);
                                                    setFilterQuery(`&filters=${!!filterMacro ? `${filterMacro};` : ""}${filterSurvey}`);
                                                    dispatch(
                                                        shouldFetchAllMacro({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${!!filterMacro ? `${filterMacro};` : ""}${filterSurvey}`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.survey")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        autoFocus
                                                        name="search"
                                                        onChange={(e) => {
                                                            setFilterSurvey(`filtersurvey=${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            { id: ACTION_ID, label: "", numeric: false, disablePadding: false },
                        ]}
                        deleteBtnClick={(id: number) => setModalRemove({ show: true, id: id })}
                        rows={status === EReduxState.pending ? Array.from(Array(record).keys()) : macros}
                        parentOrder={order}
                        fieldToOrder={orderBy}
                        parentSortAscDesc={sort}
                    />
                    <FlexEnd>
                        {data.totalPages > 1 && (
                            <Pagination
                                count={data.totalPages}
                                page={page}
                                onChange={(event: ChangeEvent<unknown>, page: number) => {
                                    setPage(page);
                                    dispatch(shouldFetchAllMacro({ pageNumber: page, sortOrder: order, sorting: sortBy, records: record, query: filterQuery, forceCall: true }));
                                }}
                            />
                        )}
                    </FlexEnd>
                </>
            )}
            <DeleteRow loading={loadingRemove} apply={deleteRow} cancel={() => setModalRemove((oldState) => ({ ...oldState, show: false }))} open={modalRemove.show} />
        </CustomPage>
    );
};

export default MacroCat;
