import { RouteObject, createBrowserRouter } from "react-router-dom";

import Categories from "../pages/categories/Categories";
import EditCategory from "../pages/categories/EditCategory";
import EditMacroCat from "../pages/macroCategories/EditMacroCat";
import EditRole from "../pages/roles/EditRole";
import EditSkill from "../pages/skills/EditSkill";
import EditSurvey from "../pages/survey/EditSurvey";
import EditUser from "../pages/users/EditUser";
import ErrorPage from "../pages/generic/Error";
import Guide from "../pages/Guide/Guide";
import MacroCat from "../pages/macroCategories/MacroCat";
import MySkills from "../pages/mySkills/MySkills";
import MySurveys from "../pages/mySurveys/MySurveys";
import MyTeam from "../pages/myTeam/MyTeam";
import MyTeamSkills from "../pages/myTeamSkills/MyTeamSkills";
import NewCategory from "../pages/categories/NewCategory";
import NewMacro from "../pages/macroCategories/NewMacro";
import NewRole from "../pages/roles/NewRole";
import NewSkill from "../pages/skills/NewSkills";
import NewSurvey from "../pages/survey/NewSurvey";
import NewUser from "../pages/users/NewUser";
import NoMatch from "../pages/generic/NoMatch";
import { PATHS } from "./routesEnum";
import Roles from "../pages/roles/Roles";
import Skills from "../pages/skills/Skills";
import SurveyToFill from "../pages/survey/SurveyToFill";
import Surveys from "../pages/survey/Surveys";
import { UserRoles } from "../store/model";
import Users from "../pages/users/Users";

const defaultRoute: RouteObject[] = [
    {
        path: PATHS.home,
        element: <MySurveys />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.mySurveys,
        element: <MySurveys />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.mySkills,
        element: <MySkills />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.surveyToFill,
        element: <SurveyToFill />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.guide,
        element: <Guide />,
        errorElement: <ErrorPage />,
    },
    {
        path: "*",
        element: <NoMatch />,
        errorElement: <ErrorPage />,
    },
];

const managerRoutes = createBrowserRouter([
    ...defaultRoute,
    {
        index: true,
        path: "/",
        element: <MySurveys />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.myTeam,
        element: <MyTeam />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.myTeamSkill,
        element: <MyTeamSkills />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.myTeamSurvey,
        element: <SurveyToFill readonly />,
        errorElement: <ErrorPage />,
    },
]);

export const normalUserRoute = createBrowserRouter([...defaultRoute]);

const routerAdmin = createBrowserRouter([
    {
        index: true,
        path: "/",
        element: <Surveys />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.surveys,
        element: <Surveys />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.newSurveys,
        element: <NewSurvey />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.editSurveys,
        element: <EditSurvey />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.editMacrocategories,
        element: <EditMacroCat />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.macrocategories,
        element: <MacroCat />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.newMacrocategories,
        element: <NewMacro />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.editMacrocategories,
        element: <EditMacroCat />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.categories,
        element: <Categories />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.newCategories,
        element: <NewCategory />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.editCategories,
        element: <EditCategory />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.skills,
        element: <Skills />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.newSkills,
        element: <NewSkill />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.editSkills,
        element: <EditSkill />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.roles,
        element: <Roles />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.newRoles,
        element: <NewRole />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.editRoles,
        element: <EditRole />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.users,
        element: <Users />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.newUsers,
        element: <NewUser />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.editUsers,
        element: <EditUser />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.editUsers,
        element: <EditUser />,
        errorElement: <ErrorPage />,
    },
    {
        index: true,
        path: "/",
        element: <MySurveys />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.myTeam,
        element: <MyTeam />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.myTeamSkill,
        element: <MyTeamSkills />,
        errorElement: <ErrorPage />,
    },
    {
        path: PATHS.myTeamSurvey,
        element: <SurveyToFill readonly />,
        errorElement: <ErrorPage />,
    },
    ...defaultRoute,
]);

export const Routes = {
    [UserRoles.admin]: routerAdmin,
    [UserRoles.normal]: normalUserRoute,
    [UserRoles.manager]: managerRoutes,
};
