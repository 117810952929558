import { Autocomplete, Box, Checkbox, Divider, FormControlLabel, IconButton, TextField } from "@vapor/react-material";
import { Dispatch, SetStateAction, useCallback } from "react";
import { FlexClm, FlexEnd } from "../../style/div";

import CirclePlus from "@vapor/react-icons/CirclePlus";
import CircleXmark from "@vapor/react-icons/CircleXmark";
import { ELanguages } from "../../localization/i18n";
import { ICustomSelectOptions } from "../../model";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { get } from "lodash";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

export interface ICustomSelectProps {
    options: ICustomSelectOptions[];
    setOptions: Dispatch<SetStateAction<ICustomSelectOptions[]>>;
    name: string;
    addLabel: string;
    openModal?: Dispatch<SetStateAction<boolean>>;
    label: string;
    withRowSelector?: boolean;
    singleOption?: boolean;
}

interface IAutocompleteRow extends ICustomSelectOptions {
    remove?: (id: number) => void;
    withRowSelector?: boolean;
    name: string;
}

export const AutoCompleteContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    margin: 8px 0;
`;
const AutoCompleteRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    width: 100%;
`;

export const AutocompleteRow = (props: IAutocompleteRow) => {
    const { nameIt, nameEn, id, remove, withRowSelector, name } = props;
    const { i18n, t } = useTranslation();
    const { values, setFieldValue } = useFormikContext();
    const autocompleteValues: ICustomSelectOptions[] = get(values, `${name}`, []);

    return (
        <>
            <AutoCompleteRowContainer>
                <Typography variant="bodyLarge500">
                    {i18n.language === ELanguages.it ? nameIt : nameEn}
                    {withRowSelector && (
                        <FormControlLabel
                            value="right"
                            control={<Checkbox checked={autocompleteValues.find((data: ICustomSelectOptions) => data.id === id)?.mandatory} />}
                            label={t("required")}
                            labelPlacement="end"
                            sx={{ marginLeft: 1 }}
                            onChange={(e, value) => {
                                setFieldValue(
                                    name,
                                    autocompleteValues.map((data: ICustomSelectOptions) => {
                                        if (data.id === id) {
                                            return { ...data, mandatory: value };
                                        }
                                        return data;
                                    })
                                );
                            }}
                        />
                    )}
                </Typography>
                {!!remove && (
                    <IconButton type="button" onClick={() => remove(id)}>
                        <CircleXmark color="critical" />
                    </IconButton>
                )}
            </AutoCompleteRowContainer>
            <Divider light />
        </>
    );
};

/**
 * @param props: ICustomSelectProps
 * * The Formik parent is needed !
 */
const CustomSelect = (props: ICustomSelectProps) => {
    const { options, name, setOptions, addLabel, label, openModal, withRowSelector } = props;
    const { values, setFieldValue } = useFormikContext();
    const { i18n } = useTranslation();

    const autocompleteValues = get(values, `${name}`, []);

    const onRemove = useCallback(
        (id: number) => {
            setFieldValue(
                name,
                autocompleteValues.filter((data: any) => data.id !== id)
            );
            setOptions([...options, ...autocompleteValues.filter((data: any) => data.id === id)]);
        },
        [values, options, setOptions, autocompleteValues, name, setFieldValue]
    );
    let fieldName = i18n.language === ELanguages.it ? "nameIt" : "nameEn";
    return (
        <FlexClm>
            <Autocomplete
                onChange={(e, data: any) => {
                    let autoCompleteData: ICustomSelectOptions = data;
                    setFieldValue(name, [autoCompleteData, ...autocompleteValues]);
                    setOptions(options.filter((opt) => autoCompleteData.id !== opt.id));
                }}
                options={options}
                getOptionLabel={(option: any) => get(option, `${fieldName}`, "")}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                        onClick={(e) => {
                            if (options.length === 1) {
                                setFieldValue(name, [options[0], ...autocompleteValues]);
                                setOptions(options.filter((opt) => options[0].id !== opt.id));
                            } else {
                                if (!!props.onClick) props.onClick(e);
                            }
                        }}
                        key={get(option, "id", "")}
                    >
                        <Typography variant="bodyLarge500" component="div">
                            {get(option, `${fieldName}`, "")}
                        </Typography>
                    </Box>
                )}
                renderInput={(params: any) => <TextField {...params} label={label} />}
                sx={{
                    width: "100%",
                }}
            />
            <AutoCompleteContainer>
                {autocompleteValues.length > 0 &&
                    autocompleteValues.map((data: any) => (
                        <AutocompleteRow name={name} key={data.id} nameIt={data.nameIt} nameEn={data.nameEn} id={data.id} remove={onRemove} withRowSelector={withRowSelector} />
                    ))}
                {!!openModal && (
                    <FlexEnd>
                        <IconButton type="button" onClick={() => openModal(true)}>
                            <CirclePlus color="primary" />
                        </IconButton>
                        <Typography variant="bodyLarge700" component="div" color="primary.interactiveDefault">
                            {addLabel}
                        </Typography>
                    </FlexEnd>
                )}
            </AutoCompleteContainer>
        </FlexClm>
    );
};

export default CustomSelect;
