import { useCallback, useEffect, useState } from "react";

import { IEditCategory } from "../../model";
import LoadingPage from "../../components/loadingPage/LoadingPage";
import NewCategory from "./NewCategory";
import { PATHS } from "../../routes/routesEnum";
import { get } from "lodash";
import { getCategoryById } from "../../api/helper";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditCategory = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [categoryToEdit, setCategoryToEdit] = useState<IEditCategory>();

    const fetchCategoryById = useCallback(async () => {
        setLoading(true);
        try {
            if (!!id) {
                let response = await getCategoryById(parseInt(id));
                let cat = get(response, "data");
                setCategoryToEdit({ ...cat, macroCat: cat.macroCategories.map((data) => ({ nameEn: data.nameEn, nameIt: data.nameIt, id: data.id })) });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchCategoryById();
    }, []);

    return <>{loading ? <LoadingPage title={t("editCat")} path={PATHS.categories} /> : <NewCategory categoryToEdit={categoryToEdit} />}</>;
};

export default EditCategory;
