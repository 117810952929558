import { DEFAULT_RECORD, checkTimeElapsed, sleep } from "../../utils/utils";
import { EReduxState, IReduxState } from "../model";
import { IGetParams, IPaginationRedux, ISurveyFe } from "../../model";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { get } from "lodash";
import { getAllSurvey } from "../../api/helper";

export enum surveyAction {
    get = "survey/getAll",
}

interface ISurvey {
    survey: ISurveyFe[];
    page: number;
    lastFetch: number;
    record: number;
    totalPages: number;
    status: EReduxState;
}

const initialState: IReduxState<ISurvey> = {
    error: undefined,
    data: { survey: [], page: 1, lastFetch: 0, totalPages: 1, record: DEFAULT_RECORD, status: EReduxState.initialState },
};

export const surveySlice = createSlice({
    name: "surveySlice",
    initialState,
    reducers: {
        setRecordSurvey: (state, action: PayloadAction<number>) => {
            state.data.record = action.payload;
        },
        deleteSurvey: (state, action: PayloadAction<number>) => {
            state.data.survey = [...state.data.survey.filter((s) => s.id !== action.payload)];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(shouldFetchAllSurveys.pending, (state) => {
                state.data.status = EReduxState.pending;
            })
            .addCase(shouldFetchAllSurveys.fulfilled, (state, action) => {
                state.data.status = EReduxState.fulfilled;
                state.data.lastFetch = new Date().getTime() / 1000;
                state.data.survey = get(action, "payload.surveys", []);
                state.data.page = get(action, "payload.page", 1);
                state.data.totalPages = get(action, "payload.totalPages", 1);
            })
            .addCase(shouldFetchAllSurveys.rejected, (state, action) => {
                state.data.status = EReduxState.rejected;
            });
    },
});
export const shouldFetchAllSurveys = createAsyncThunk(surveyAction.get, async (pagination: IGetParams, { rejectWithValue, getState }) => {
    try {
        const state = getState() as RootState;
        let { lastFetch, page } = state.surveySlice.data;
        if (!lastFetch || (!!lastFetch && checkTimeElapsed(lastFetch)) || page !== pagination.pageNumber || pagination.forceCall) {
            let data = await getAllSurvey(pagination);
            return { surveys: data.data.items, page: pagination.pageNumber, totalPages: data.data.totalPages };
        } else {
            return state.surveySlice.data.survey;
        }
    } catch (error: any) {
        return rejectWithValue(error.response);
    }
});

export const selectSurvey = (state: RootState) => state.surveySlice;
export const { setRecordSurvey, deleteSurvey } = surveySlice.actions;

export default surveySlice.reducer;
