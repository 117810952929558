import { ACTION_ID, ARROWS_ID, PAGE_RECORDS, normalizeStringForRest } from "../../utils/utils";
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, Pagination, Stack, TextField } from "@vapor/react-material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { EOrder, Order } from "../../model/enum";
import { FilterContainer, FlexCenter, FlexContainer, FlexEnd, FlexStart } from "../../style/div";
import { deleteUser, selectUsers, shouldFetchAllUsers } from "../../store/slice/usersSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import CustomPage from "../../components/customPage/CustomPage";
import CustomSearch from "../../components/customSearch/CustomSearch";
import DeleteRow from "../../modals/deleteRow/DeleteRow";
import { ELanguages } from "../../localization/i18n";
import { EReduxState } from "../../store/model";
import EmptyState from "@vapor/react-custom/EmptyState";
import FilterBtn from "../../components/btns/FilterBtn";
import { PATHS } from "../../routes/routesEnum";
import Search from "@vapor/react-icons/Search";
import SelectRecord from "../../components/selectRecord/SelectRecord";
import UsersTableWithCollapse from "../../components/table/CollapsibleTable";
import { deleteUserById } from "../../api/helper";
import { openToast } from "../../store/slice/toastSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Users = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectUsers);
    const navigate = useNavigate();
    const [record, setRecord] = useState<number>(PAGE_RECORDS[0]);
    const [modalRemove, setModalRemove] = useState<{ show: boolean; id: string }>({ show: false, id: "" });
    const { status, users } = data;
    const [search, setSearch] = useState<string>("");

    const [order, setOrder] = useState<Order>(EOrder.asc);
    const [orderBy, setOrderBy] = useState<string>("name");
    const [sortBy, setSortBy] = useState<string>("");

    const [filterQuery, setFilterQuery] = useState<string>("");

    const [filterUser, setFilterUser] = useState<string>("");
    const [filterRole, setFilterRole] = useState<string>("");
    const [page, setPage] = useState<number>(1);

    const [filteredRole, setFilteredRole] = useState<boolean>(false);
    const [filteredName, setFilteredName] = useState<boolean>(false);

    const [statusSurvey, setStatusSurvey] = useState<boolean>(false);

    const deleteRow = useCallback(async () => {
        const { id } = modalRemove;
        if (!!id) {
            deleteUserById(id);
            dispatch(openToast({ msg: t("newSurveyForm.success") }));
            dispatch(deleteUser(id));
            setModalRemove((oldState) => ({ ...oldState, show: false }));
        }
    }, [modalRemove]);

    const fetchAllUsers = useCallback(async () => {
        dispatch(shouldFetchAllUsers({ pageNumber: 1, sortOrder: order, sorting: sortBy, records: record, query: filterQuery, forceCall: true }));
    }, [record, filterQuery]);

    useEffect(() => {
        fetchAllUsers();
    }, [record, search]);

    const sort = useCallback(
        (cellId: string, fieldName: string, isAsc: boolean) => {
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
            setOrderBy(cellId);
            setPage(1);
            setSortBy(fieldName);
            dispatch(shouldFetchAllUsers({ pageNumber: 1, sortOrder: isAsc ? EOrder.desc : EOrder.asc, sorting: fieldName, records: record, query: filterQuery, forceCall: true }));
        },
        [filterQuery, record],
    );

    return (
        <CustomPage pageTitle={t("usersTitle")}>
            <FilterContainer>
                <FilterContainer>
                    <SelectRecord record={record} setRecord={setRecord} />
                    <FormControlLabel
                        value="right"
                        control={<Checkbox checked={statusSurvey} />}
                        label={t("filter.userStatus")}
                        labelPlacement="end"
                        sx={{ marginLeft: 1 }}
                        onChange={(e, value) => {
                            setStatusSurvey(value);
                            setFilterQuery(
                                `&filters=${!!filterUser ? filterUser : ""}${!!filterRole ? `${!!filterUser ? ";" : ""}${filterRole}` : ""}${
                                    value ? `${!!filterUser || !!filterRole ? ";" : ""}filterstatus=true` : ""
                                }`,
                            );
                            setPage(1);
                            dispatch(
                                shouldFetchAllUsers({
                                    pageNumber: 1,
                                    sortOrder: order,
                                    sorting: sortBy,
                                    records: record,
                                    query: `&filters=${!!filterUser ? filterUser : ""}${!!filterRole ? `${!!filterUser ? ";" : ""}${filterRole}` : ""}${
                                        !!value ? `${!!filterUser || !!filterRole ? ";" : ""}filterstatus=true` : ""
                                    }`,
                                    forceCall: true,
                                }),
                            );
                        }}
                    />
                </FilterContainer>

                <Button variant="contained" onClick={() => navigate(PATHS.newUsers)}>
                    {t("newUser")}
                </Button>
            </FilterContainer>
            {status === EReduxState.rejected ? (
                <FlexContainer>
                    <EmptyState badgeIcon description={t("error")} title={t("apiError")} />
                </FlexContainer>
            ) : (
                <>
                    <UsersTableWithCollapse
                        loading={status === EReduxState.pending}
                        headCells={[
                            { id: ARROWS_ID, label: "", numeric: false, disablePadding: false },
                            {
                                id: "name",
                                label: t("clmName"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "user",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredName}
                                        cleanFilter={() => {
                                            setFilteredName(false);
                                            let query = !!filterRole ? `&filters=${filterRole}` : "" + statusSurvey ? `${!!filterRole ? ";" : ""}filterstatus=true` : "";
                                            setFilterQuery(query);
                                            setFilterUser("");
                                            dispatch(
                                                shouldFetchAllUsers({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: query,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredName(true);
                                                    let query = `&filters=${!!filterUser ? filterUser : ""}${!!filterRole ? `${!!filterUser ? ";" : ""}${filterRole}` : ""}${
                                                        !!statusSurvey ? `${!!filterUser || !!filterRole ? ";" : ""}filterstatus=true` : ""
                                                    }`;
                                                    setFilterQuery(query);
                                                    dispatch(
                                                        shouldFetchAllUsers({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: query,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.name")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        name="search"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterUser(`filteruser=${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            {
                                id: i18n.language === ELanguages.en ? "roleEn" : "roleIt",
                                label: t("clmRoles"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "role",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredRole}
                                        cleanFilter={() => {
                                            setFilteredRole(false);
                                            let query = !!filterUser ? `&filters=${filterUser}` : "" + statusSurvey ? `${!!filterUser ? ";" : ""}filterstatus=true` : "";

                                            setFilterQuery(query);
                                            setFilterRole("");
                                            dispatch(
                                                shouldFetchAllUsers({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: query,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredRole(true);
                                                    let query = `&filters=${!!filterRole ? filterRole : ""}${!!filterUser ? `${!!filterRole ? ";" : ""}${filterUser}` : ""}${
                                                        !!statusSurvey ? `${!!filterUser || !!filterRole ? ";" : ""}filterstatus=true` : ""
                                                    }`;
                                                    setFilterQuery(query);
                                                    dispatch(
                                                        shouldFetchAllUsers({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: query,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.role")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        name="search"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterRole(`filterrole=${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            { id: ACTION_ID, label: "", numeric: false, disablePadding: false },
                        ]}
                        editBtn={PATHS.users}
                        deleteBtnClick={(id: number) => setModalRemove({ show: true, id: `${id}` })}
                        rows={
                            status === EReduxState.pending
                                ? Array.from(Array(record).keys())
                                : users.map((data) => ({ ...data, name: `${data.lastName} ${data.firstName}`, roleEn: data.role.roleNameEn, roleIt: data.role.roleNameIt }))
                        }
                        parentOrder={order}
                        fieldToOrder={orderBy}
                        parentSortAscDesc={sort}
                    />
                    <FlexEnd>
                        {data.totalPages > 1 && (
                            <Pagination
                                count={data.totalPages}
                                page={page}
                                onChange={(event: ChangeEvent<unknown>, page: number) => {
                                    setPage(page);
                                    dispatch(shouldFetchAllUsers({ pageNumber: page, sortOrder: order, sorting: sortBy, records: record, query: filterQuery, forceCall: true }));
                                }}
                            />
                        )}
                    </FlexEnd>
                </>
            )}
            <DeleteRow apply={deleteRow} cancel={() => setModalRemove((oldState) => ({ ...oldState, show: false }))} open={modalRemove.show} />
        </CustomPage>
    );
};

export default Users;
