import { Button, IconButton } from "@vapor/react-material";
import CustomSelect, { AutoCompleteContainer, AutocompleteRow } from "../../components/customSelect/CustomSelect";
import { ICustomSelectOptions, IEditCategory, INewCategory, ISkillBe } from "../../model";
import { InputDivider, InputFlexBtm, InputFlexTop } from "../../style/div";
import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";
import { createCategory, editCategory, getAllAssociableSkill } from "../../api/helper";
import { useCallback, useEffect, useState } from "react";

import ArrowLeft from "@vapor/react-icons/ArrowLeft";
import ConfirmModal from "../../modals/confirm/ConfirmModal";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import GenericCancelModalWithBtn from "../../modals/genericCancel/GenericCancelModalWithBtn";
import Header from "../../components/header/Header";
import Legends from "../../components/legend/Legend";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import ModalSkill from "../../modals/newSkill/ModalSkill";
import { PATHS } from "../../routes/routesEnum";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { get } from "lodash";
import { newCategorySchema } from "../../validations";
import { openToast } from "../../store/slice/toastSlice";
import { useAppDispatch } from "../../store/hook";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface INewCategoryProps {
    categoryToEdit?: IEditCategory;
}
export const emptyCategory: INewCategory = {
    nameIt: "",
    nameEn: "",
    legends: [
        { nameIt: "", nameEn: "", id: 0 },
        { nameIt: "", nameEn: "", id: 1 },
        { nameIt: "", nameEn: "", id: 2 },
        { nameIt: "", nameEn: "", id: 3 },
        { nameIt: "", nameEn: "", id: 4 },
        { nameIt: "", nameEn: "", id: 5 },
    ],
    skills: [],
    questionIt: "",
    questionEn: "",
};

const NewCategory = (props: INewCategoryProps) => {
    const { categoryToEdit } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openModalSkill, setOpenModalSkill] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

    const [skills, setSkills] = useState<ICustomSelectOptions[]>([]);
    const fetchSkillsForCategory = useCallback(async () => {
        try {
            const data = await getAllAssociableSkill();
            setSkills(data.data.map((sk) => ({ nameEn: sk.nameEn, nameIt: sk.nameIt, id: sk.id })));
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchSkillsForCategory();
    }, []);

    const submit = useCallback(async (values: INewCategory) => {
        setLoading(true);
        try {
            if (!!categoryToEdit) {
                let result = await editCategory({ ...values, id: categoryToEdit.id });
            } else {
                let result = await createCategory(values);
            }
            dispatch(openToast({ msg: t("newSurveyForm.success") }));
            navigate(PATHS.categories);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <Formik
                initialValues={!!categoryToEdit ? categoryToEdit : emptyCategory}
                validationSchema={newCategorySchema}
                onSubmit={(values, actions) => {
                    submit(values);
                }}
            >
                {({ handleSubmit, setFieldValue, values, submitForm }) => (
                    <form onSubmit={handleSubmit}>
                        <VaporPage
                            title={!!categoryToEdit ? t("editCat") : t("newCat")}
                            subtitle=""
                            headerLeft={
                                <IconButton onClick={() => navigate(PATHS.categories)}>
                                    <ArrowLeft color="primary" />
                                </IconButton>
                            }
                            contentToolbar={
                                <VaporToolbar
                                    size="large"
                                    contentRight={[
                                        <GenericCancelModalWithBtn cancel={() => navigate(PATHS.categories)} />,
                                        <LoadingBtn
                                            loading={!categoryToEdit && loading}
                                            disabled={loading}
                                            type={!!categoryToEdit ? "button" : "submit"}
                                            msg={t("buttons.save")}
                                            key={"primary"}
                                            onClick={!!categoryToEdit ? () => setOpenConfirmModal(true) : undefined}
                                        />,
                                    ]}
                                />
                            }
                        >
                            <VaporPage.Section divider>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("newSurveySection1")}
                                </Typography>
                                <InputFlexTop>
                                    <FormikInput name="nameEn" label={t("nameEn")} required />
                                    <FormikInput name="nameIt" label={t("nameIt")} required />
                                </InputFlexTop>
                                <InputFlexBtm>
                                    <FormikInput name="questionEn" label={t("mainEn")} required />
                                    <FormikInput name="questionIt" label={t("mainIt")} required />
                                </InputFlexBtm>
                            </VaporPage.Section>
                            <VaporPage.Section divider>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("legendTitle")}
                                </Typography>
                                <InputDivider>
                                    <Legends />
                                </InputDivider>
                            </VaporPage.Section>
                            <VaporPage.Section>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("skillsTitle")}
                                </Typography>
                                <InputDivider>
                                    <CustomSelect name="skills" options={skills} setOptions={setSkills} addLabel={t("addSkill")} label={t("selectSkill")} openModal={setOpenModalSkill} />
                                </InputDivider>
                            </VaporPage.Section>
                            <VaporPage.Section>
                                {!!categoryToEdit && (
                                    <>
                                        <Typography variant="titleMedium" color="primary.textTitleColor">
                                            {t("macroTitle")}
                                        </Typography>
                                        <InputFlexBtm>
                                            <AutoCompleteContainer>
                                                {categoryToEdit.macroCat.length > 0 &&
                                                    categoryToEdit.macroCat.map((data: any) => <AutocompleteRow name={""} key={data.id} nameEn={data.nameEn} nameIt={data.nameIt} id={data.id} />)}
                                            </AutoCompleteContainer>
                                        </InputFlexBtm>
                                    </>
                                )}
                            </VaporPage.Section>
                        </VaporPage>
                        <ModalSkill
                            open={openModalSkill}
                            setOpen={setOpenModalSkill}
                            functionAfterCreate={(skill: ISkillBe) => {
                                // setSkills([{ nameEn: skill.nameEn, nameIt: skill.nameIt, id: skill.id }, ...skills]);
                                setFieldValue("skills", [skill, ...get(values, "skills", [])]);
                            }}
                        />
                        <ConfirmModal apply={submitForm} name={t("categoryName")} cancel={() => setOpenConfirmModal(false)} open={openConfirmModal} loading={loading} />
                    </form>
                )}
            </Formik>
        </VaporUIShellNav>
    );
};

export default NewCategory;
