import { Button, IconButton } from "@vapor/react-material";
import CustomSelect, { AutoCompleteContainer, AutocompleteRow } from "../../components/customSelect/CustomSelect";
import { ICategoryBe, ICustomSelectOptions, IEditMacroCat, INewMacroCat } from "../../model";
import { InputFlexBtm, InputFlexTop } from "../../style/div";
import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";
import { createMacroCategory, editMacroCategory, getAllCategories, getAllCategoriesNoPaginate } from "../../api/helper";
import { get, values } from "lodash";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { useCallback, useEffect, useState } from "react";

import ArrowLeft from "@vapor/react-icons/ArrowLeft";
import ConfirmModal from "../../modals/confirm/ConfirmModal";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import GenericCancelModalWithBtn from "../../modals/genericCancel/GenericCancelModalWithBtn";
import Header from "../../components/header/Header";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import ModalCategory from "../../modals/newCat/ModalCategory";
import { PATHS } from "../../routes/routesEnum";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { newMacroCategorySchema } from "../../validations";
import { openToast } from "../../store/slice/toastSlice";
import { selectSurvey } from "../../store/slice/surveySlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface INewMacroProps {
    macroToEdit?: IEditMacroCat;
}

const formikInitialValues: INewMacroCat = { nameIt: "", nameEn: "", categories: [], questionIt: "", questionEn: "" };

const NewMacro = (props: INewMacroProps) => {
    const { macroToEdit } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openModalCategory, setOpenModalCategory] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { data } = useAppSelector(selectSurvey);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [categories, setCategories] = useState<ICustomSelectOptions[]>([]);

    const fetchCategoriesForMacroCategory = useCallback(async () => {
        try {
            const response = await getAllCategoriesNoPaginate();
            let categories = get(response, "data", []);
            setCategories(categories.map((c) => ({ nameEn: c.nameEn, nameIt: c.nameIt, id: c.id })));
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchCategoriesForMacroCategory();
    }, []);

    const submit = useCallback(async (values: INewMacroCat) => {
        setLoading(true);
        try {
            if (!!macroToEdit) {
                let result = await editMacroCategory({ ...values, id: macroToEdit.id });
            } else {
                let result = await createMacroCategory(values);
            }
            dispatch(openToast({ msg: t("newSurveyForm.success") }));
            navigate(PATHS.macrocategories);
        } catch (err) {
            dispatch(openToast({ msg: t("newSurveyForm.error"), severity: "error" }));
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <Formik
                initialValues={!!macroToEdit ? { ...macroToEdit } : formikInitialValues}
                validationSchema={newMacroCategorySchema}
                onSubmit={(values, actions) => {
                    submit(values);
                }}
            >
                {({ handleSubmit, setFieldValue, submitForm, values }) => (
                    <form onSubmit={handleSubmit}>
                        <VaporPage
                            title={!!macroToEdit ? t("editMacro") : t("newMacro")}
                            subtitle=""
                            headerLeft={
                                <IconButton onClick={() => navigate(PATHS.macrocategories)}>
                                    <ArrowLeft color="primary" />
                                </IconButton>
                            }
                            contentToolbar={
                                <VaporToolbar
                                    size="large"
                                    contentRight={[
                                        <GenericCancelModalWithBtn cancel={() => navigate(PATHS.macrocategories)} />,
                                        <LoadingBtn
                                            loading={!macroToEdit && loading}
                                            disabled={loading}
                                            type={!!macroToEdit ? "button" : "submit"}
                                            msg={t("buttons.save")}
                                            key={"primary"}
                                            onClick={!!macroToEdit ? () => setOpenConfirmModal(true) : undefined}
                                        />,
                                    ]}
                                />
                            }
                        >
                            <VaporPage.Section divider>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("newSurveySection1")}
                                </Typography>
                                <InputFlexTop>
                                    <FormikInput name="nameEn" label={t("newSurveyForm.nameEng")} required />
                                    <FormikInput name="nameIt" label={t("newSurveyForm.nameIt")} required />
                                </InputFlexTop>
                                <InputFlexBtm>
                                    <FormikInput name="questionEn" label={t("newSurveyForm.mqEn")} required />
                                    <FormikInput name="questionIt" label={t("newSurveyForm.mqIt")} required />
                                </InputFlexBtm>
                            </VaporPage.Section>
                            <VaporPage.Section>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("categoriesTitle")}
                                </Typography>
                                <InputFlexTop>
                                    <CustomSelect
                                        name="categories"
                                        options={categories}
                                        setOptions={setCategories}
                                        addLabel={t("newCat")}
                                        label={t("selectCategories")}
                                        openModal={setOpenModalCategory}
                                    />
                                </InputFlexTop>
                                {!!macroToEdit && (
                                    <>
                                        <Typography variant="titleMedium" color="primary.textTitleColor">
                                            {t("surveyTitle")}
                                        </Typography>
                                        <InputFlexBtm>
                                            <AutoCompleteContainer>
                                                {!!macroToEdit.surveys &&
                                                    macroToEdit.surveys.length > 0 &&
                                                    macroToEdit.surveys.map((data: any) => <AutocompleteRow {...data} key={data.id} name={data.name} id={data.id} />)}
                                            </AutoCompleteContainer>
                                        </InputFlexBtm>
                                    </>
                                )}
                            </VaporPage.Section>
                        </VaporPage>
                        <ModalCategory
                            open={openModalCategory}
                            setOpen={setOpenModalCategory}
                            functionAfterCreate={(category: ICategoryBe) => {
                                setFieldValue("categories", [category, ...get(values, "categories", [])]);
                            }}
                        />
                        <ConfirmModal apply={submitForm} name={t("macroCategoryName")} cancel={() => setOpenConfirmModal(false)} open={openConfirmModal} loading={loading} />
                    </form>
                )}
            </Formik>
        </VaporUIShellNav>
    );
};

export default NewMacro;
