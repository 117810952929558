import { ICategoryBe, IEditRole, IEditSkill, ILegendBe, IMacroCatBe, INewCategory, INewMacroCat, IRoleBe, ISkillBe, ISkillWithSurvey, ISurveyBe, ISurveyToFillFormik, SkillWithVote } from "..";

import { INewSurvey } from "../../pages/survey/NewSurvey";
import { SurveyStatus } from "../enum";
import { createKey } from "../../utils/utils";
import { get } from "lodash";

export const deSerializeCategories = (categories: ICategoryBe[]): INewCategory[] => {
    return categories.map((c) => ({
        id: c.id,
        nameIt: c.nameIt,
        nameEn: c.nameEn,
        questionIt: c.questionIt,
        questionEn: c.questionEn,
        legends: c.legends.map((l: ILegendBe) => ({ id: l.id, nameIt: l.nameIt, nameEn: l.nameEn })),
        skills: c.skills.map((s: ISkillBe) => ({ id: s.id, nameIt: s.nameIt, nameEn: s.nameEn })),
        mcsIt: c.macroCategories.map((mc: IMacroCatBe) => mc.nameIt).join(", "),
        mcsEn: c.macroCategories.map((mc: IMacroCatBe) => mc.nameEn).join(", "),
    }));
};
export const deSerializeMacro = (macros: IMacroCatBe[]): INewMacroCat[] => {
    return macros.map((m) => ({
        id: m.id,
        nameIt: m.nameIt,
        nameEn: m.nameEn,
        questionIt: m.questionIt,
        questionEn: m.questionEn,
        categories: m.categories.map((c: ICategoryBe) => ({ id: c.id, nameIt: c.nameIt, nameEn: c.nameEn })),
        surveyEn: m.surveys.map((s) => s.titleEn).join(", "),
        surveyIt: m.surveys.map((s) => s.titleIt).join(", "),
    }));
};

export const deSerializeSkillBe = (skill: ISkillBe): IEditSkill => {
    return { id: skill.id, descriptionEn: skill.descriptionEn, descriptionIt: skill.descriptionIt, nameEn: skill.nameEn, nameIt: skill.nameIt };
};

export const deSerializeSkill = (skill: ISkillWithSurvey): IEditSkill => {
    return {
        id: skill.id,
        descriptionEn: skill.descriptionEn,
        descriptionIt: skill.descriptionIt,
        nameEn: skill.nameEn,
        nameIt: skill.nameIt,
        surveyTitleEn: get(skill, "survey.titleEn"),
        surveyTitleIt: get(skill, "survey.titleIt"),
        idSurvey: get(skill, "survey.id"),
        catEn: get(skill, "category.nameEn"),
        catIt: get(skill, "category.nameIt"),
    };
};
export const deSerializeRole = (role: IRoleBe): IEditRole => {
    return {
        id: role.id,
        roleNameEn: role.roleNameEn,
        roleNameIt: role.roleNameIt,
        surveyIds: role.surveys.map((s) => ({ nameEn: s.titleEn, nameIt: s.titleIt, id: s.id, mandatory: s.isMandatory })),
    };
};

export const deserializeSurvey = (survey: ISurveyBe): INewSurvey => {
    return {
        nameIt: survey.titleIt,
        nameEn: survey.titleEn,
        macros: survey.macroCategories.map((m) => ({ id: m.id, nameEn: m.nameEn, nameIt: m.nameIt })),
        roles: survey.roles.map((m) => ({ id: m.id, nameEn: m.roleNameEn, nameIt: m.roleNameIt, mandatory: m.isMandatory })),
        expired: survey.expireInMonths,
        id: survey.id,
    };
};

export const deSerializeSurveyToFill = (survey: ISurveyBe): ISurveyToFillFormik => {
    let data: ISurveyToFillFormik = {};
    survey.macroCategories.forEach((m) => {
        m.categories.forEach((c) => {
            c.skills.forEach((sk) => {
                data[`${m.id}`] = {
                    ...data[`${m.id}`],
                    isMacroCategoryResponse: survey.status === SurveyStatus.New ? m.isMacroCategoryResponse : !!m.isMacroCategoryResponse ? m.isMacroCategoryResponse : false,
                    [`${sk.id}`]: {
                        macroCategoryId: m.id,
                        skillId: sk.id,
                        catId: c.id,
                        vote: sk.vote !== null && sk.vote >= 0 ? sk.vote : -1,
                        lastVote: sk.vote !== null && sk.vote >= 0 ? sk.vote : -1,
                    },
                };
            });
        });
    });

    return data;
};
