import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@vapor/react-material";
import { IModal, ISkillBe } from "../../model";

import Close from "@vapor/react-icons/Xmark";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import { InputDivider } from "../../style/div";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import { createSkill } from "../../api/helper";
import { emptySkill } from "../../pages/skills/NewSkills";
import { newSkillSchema } from "../../validations";
import { openToast } from "../../store/slice/toastSlice";
import { useAppDispatch } from "../../store/hook";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IModalMacroCategoryProps extends IModal {
    functionAfterCreate?: (skill: ISkillBe) => void;
}

const ModalSkill = (props: IModalMacroCategoryProps) => {
    const { open, setOpen, functionAfterCreate } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const handleDialog = () => {
        setOpen(!open);
    };

    return (
        <Dialog open={open} maxWidth="xl" fullWidth scroll="paper" onClose={handleDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <Formik
                initialValues={emptySkill}
                validationSchema={newSkillSchema}
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    try {
                        let result = await createSkill(values);
                        dispatch(openToast({ msg: t("newSurveyForm.success") }));
                        setOpen(!open);
                        if (!!functionAfterCreate) functionAfterCreate(result.data);
                    } catch (err) {
                        dispatch(openToast({ msg: t("newSurveyForm.error") }));
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogTitle>
                            {t("newSkills")}
                            <IconButton color="primary" onClick={handleDialog}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <Divider variant="fullWidth" />
                        <DialogContent>
                            <InputDivider>
                                <FormikInput name="nameEn" label={t("nameEn")} required />
                                <FormikInput name="nameIt" label={t("nameIt")} required />
                            </InputDivider>
                            <InputDivider>
                                <FormikInput name="descriptionEn" label={t("descriptionEn")} />
                                <FormikInput name="descriptionIt" label={t("descriptionIt")} />
                            </InputDivider>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="error" type="button" onClick={handleDialog}>
                                {t("buttons.cancel")}
                            </Button>
                            <LoadingBtn loading={loading} type="submit" msg={t("buttons.save")} key={"primary"} />
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default ModalSkill;
