import categorySlice from "./slice/categorySlice";
import { configureStore } from "@reduxjs/toolkit";
import macroSlice from "./slice/macroSlice";
import mySkillsSlice from "./slice/mySkillsSlice";
import mySurveys from "./slice/mySurveys";
import myTeamSkills from "./slice/myTeamSkills";
import myTeamSlice from "./slice/myTeamSurveySlice";
import roleSlice from "./slice/roleSlice";
import skillsSlice from "./slice/skillsSlice";
import surveySlice from "./slice/surveySlice";
import toastSlice from "./slice/toastSlice";
import userSlice from "./slice/userSlice";
import usersSlice from "./slice/usersSlice";

export const redux = configureStore({
    reducer: {
        userSlice: userSlice,
        surveySlice: surveySlice,
        macroSlice: macroSlice,
        categorySlice: categorySlice,
        skillsSlice: skillsSlice,
        usersSlice: usersSlice,
        roleSlice: roleSlice,
        mySurveysSlice: mySurveys,
        mySkillsSlice: mySkillsSlice,
        toastSlice: toastSlice,
        myTeamSurveySlice: myTeamSlice,
        myTeamSkill: myTeamSkills,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof redux.getState>;
export type AppDispatch = typeof redux.dispatch;
