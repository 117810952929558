import { Accordion, AccordionDetails, AccordionSummary } from "@vapor/react-material";
import { ReactNode, useId, useState } from "react";

import Expanded from "@vapor/react-icons/AngleDown";
import { ExtendedTypography } from "@vapor/react-extended";
import styled from "styled-components";

interface ICustomAccordionProps {
    title: string;
    description?: string;
    children: ReactNode;
    withRedCircle?: boolean;
    defaulOpen?: boolean;
    onClick?: any;
}
const RedCircle = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: red;
`;

const AccordionStyle = {
    border: "none",
    boxShadow: "none",
};
const CustomAccordion = (props: ICustomAccordionProps) => {
    const { title, description, children, withRedCircle, defaulOpen, onClick } = props;

    const id = useId();
    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        if (!!onClick) onClick(isExpanded);
    };

    return (
        <Accordion expanded={defaulOpen || false} onChange={handleChange(id)} variant={"elevation"} sx={AccordionStyle}>
            <AccordionSummary expandIcon={<Expanded />} aria-controls={`${id}bh-content`} id={`${id}bh-header`} sx={{}}>
                <ExtendedTypography variant="titleSmall">{title}</ExtendedTypography>
                {!!description && <ExtendedTypography variant="body">{description}</ExtendedTypography>}
                {withRedCircle && <RedCircle />}
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export default CustomAccordion;
