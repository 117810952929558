import { SurveyStatus } from "../../model/enum";
import Tag from "@vapor/react-custom/Tag";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

interface IBannerStatus {
    row: any;
    keyToSearch: string | number;
}
const BannerStatus = (props: IBannerStatus) => {
    const { row, keyToSearch: key } = props;
    const { t } = useTranslation();
    const getStatusData = (): {
        label: string;
        variant: "ghost" | "standard" | "default" | undefined;
        type: "islamicGreen" | "rustyRed" | "blueSapphire" | "russianViolet" | "maximumPurple" | "mediumSlateBlue" | "prune" | "royalFuchsia" | undefined;
    } => {
        let status = get(row, `${key}`, SurveyStatus.New);

        switch (status) {
            case SurveyStatus.Completed:
                return {
                    label: t("completed"),
                    variant: "ghost",
                    type: "islamicGreen",
                };
            case SurveyStatus.New:
                return {
                    label: t("new"),
                    variant: "standard",
                    type: "rustyRed",
                };
            case SurveyStatus.Pending:
            case SurveyStatus.Draft:
                return {
                    label: t("pending"),
                    variant: "standard",
                    type: "rustyRed",
                };
            case SurveyStatus.Expired:
                return {
                    label: t("expired"),
                    variant: "standard",
                    type: "prune",
                };
            case SurveyStatus.Deleted:
                return {
                    label: t("deleted"),
                    variant: "standard",
                    type: "prune",
                };
            default:
                return {
                    label: t("new"),
                    variant: "standard",
                    type: "rustyRed",
                };
        }
    };
    return <Tag label={getStatusData().label} variant={getStatusData().variant} type={getStatusData().type} />;
};

export default BannerStatus;
