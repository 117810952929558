import { DEFAULT_RECORD, checkTimeElapsed } from "../../utils/utils";
import { EReduxState, IReduxState } from "../model";
import { IEditSkill, IGetParams, ISurveyFe } from "../../model";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllSkillsForManager, getAllSurveyByLoggedUser } from "../../api/helper";

import { RootState } from "../store";
import { deSerializeSkill } from "../../model/deserialize";
import { get } from "lodash";

export enum myTeamSkillAction {
    get = "survey/getAll",
}

interface IMyTeamSkill {
    skills: IEditSkill[];
    page: number;
    lastFetch: number;
    record: number;
    status: EReduxState;
    totalPages: number;
}

const initialState: IReduxState<IMyTeamSkill> = {
    error: undefined,
    data: { skills: [], page: 1, lastFetch: 0, record: DEFAULT_RECORD, status: EReduxState.initialState, totalPages: 1 },
};

export const myTeamSkill = createSlice({
    name: "myTeamSkill",
    initialState,
    reducers: {
        setRecordSurvey: (state, action: PayloadAction<number>) => {
            state.data.record = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(shouldFetchAllMyTeamSkills.pending, (state) => {
                state.data.status = EReduxState.pending;
            })
            .addCase(shouldFetchAllMyTeamSkills.fulfilled, (state, action) => {
                state.data.status = EReduxState.fulfilled;
                state.data.lastFetch = new Date().getTime() / 1000;
                state.data.skills = get(action, "payload.skills", []);
                state.data.page = get(action, "payload.page", 1);
                state.data.totalPages = get(action, "payload.totalPages", 1);
            })
            .addCase(shouldFetchAllMyTeamSkills.rejected, (state, action) => {
                state.data.status = EReduxState.rejected;
            });
    },
});
export const shouldFetchAllMyTeamSkills = createAsyncThunk(myTeamSkillAction.get, async (pagination: IGetParams, { rejectWithValue, getState }) => {
    try {
        const state = getState() as RootState;
        let { lastFetch, page } = state.myTeamSkill.data;
        if (!lastFetch || (!!lastFetch && checkTimeElapsed(lastFetch)) || page !== pagination.pageNumber || pagination.forceCall) {
            let data = await getAllSkillsForManager(pagination);
            return { skills: data.data.items.map((item) => deSerializeSkill(item)), page: pagination.pageNumber, totalPages: data.data.totalPages };
        } else {
            return state.myTeamSkill.data.skills;
        }
    } catch (error: any) {
        console.log(error);
        return rejectWithValue(error.response);
    }
});

export const selectMyTeamSkills = (state: RootState) => state.myTeamSkill;
export const { setRecordSurvey } = myTeamSkill.actions;

export default myTeamSkill.reducer;
