import { Button, IconButton } from "@vapor/react-material";
import { IEditSkill, INewSkill } from "../../model";
import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";
import { createSkill, editSkill } from "../../api/helper";
import { useCallback, useState } from "react";

import ArrowLeft from "@vapor/react-icons/ArrowLeft";
import ConfirmModal from "../../modals/confirm/ConfirmModal";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import GenericCancelModalWithBtn from "../../modals/genericCancel/GenericCancelModalWithBtn";
import Header from "../../components/header/Header";
import { InputFlexTop } from "../../style/div";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import { PATHS } from "../../routes/routesEnum";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { newSkillSchema } from "../../validations";
import { openToast } from "../../store/slice/toastSlice";
import { useAppDispatch } from "../../store/hook";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const emptySkill: INewSkill = { nameIt: "", nameEn: "", descriptionEn: "", descriptionIt: "" };

interface INewSkillProps {
    skillToEdit?: IEditSkill;
}

const NewSkill = (props: INewSkillProps) => {
    const { skillToEdit } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const submit = useCallback(
        async (values: INewSkill) => {
            setLoading(true);
            try {
                if (!!skillToEdit) {
                    await editSkill({ ...values, id: skillToEdit.id });
                } else {
                    await createSkill(values);
                }
                dispatch(openToast({ msg: t("newSurveyForm.success") }));
                navigate(PATHS.skills);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        },
        [skillToEdit]
    );

    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <Formik
                initialValues={!!skillToEdit ? skillToEdit : emptySkill}
                validationSchema={newSkillSchema}
                onSubmit={(values) => {
                    submit(values);
                }}
            >
                {({ handleSubmit, submitForm }) => (
                    <form onSubmit={handleSubmit}>
                        <VaporPage
                            title={!!skillToEdit ? t("editSkill") : t("newSkills")}
                            subtitle=""
                            headerLeft={
                                <IconButton onClick={() => navigate(PATHS.skills)}>
                                    <ArrowLeft color="primary" />
                                </IconButton>
                            }
                            contentToolbar={
                                <VaporToolbar
                                    size="large"
                                    contentRight={[
                                        <GenericCancelModalWithBtn cancel={() => navigate(PATHS.skills)} />,
                                        <LoadingBtn
                                            loading={!skillToEdit && loading}
                                            disabled={loading}
                                            type={!!skillToEdit ? "button" : "submit"}
                                            msg={t("buttons.save")}
                                            key={"primary"}
                                            onClick={!!skillToEdit ? () => setOpenConfirmModal(true) : undefined}
                                        />,
                                    ]}
                                />
                            }
                        >
                            <VaporPage.Section>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("newSurveySection1")}
                                </Typography>
                                <InputFlexTop>
                                    <FormikInput name="nameEn" label={t("nameEn")} required />
                                    <FormikInput name="nameIt" label={t("nameIt")} required />
                                </InputFlexTop>
                                <InputFlexTop>
                                    <FormikInput name="descriptionEn" label={t("descriptionEn")} />
                                    <FormikInput name="descriptionIt" label={t("descriptionIt")} />
                                </InputFlexTop>
                            </VaporPage.Section>
                        </VaporPage>
                        <ConfirmModal apply={submitForm} name={t("skillName")} cancel={() => setOpenConfirmModal(false)} open={openConfirmModal} loading={loading} />
                    </form>
                )}
            </Formik>
        </VaporUIShellNav>
    );
};

export default NewSkill;
