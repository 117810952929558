import { Button, FormControl, IconButton, InputLabel, MenuItem, Select } from "@vapor/react-material";
import { ICustomSelectOptions, IEditUser, INewUser } from "../../model";
import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";
import { createUser, getAllRoles, getAllRolesNoPaginate, getAllUsers, getAllUsersForCreation, updateUser } from "../../api/helper";
import { useCallback, useEffect, useState } from "react";

import ArrowLeft from "@vapor/react-icons/ArrowLeft";
import ConfirmModal from "../../modals/confirm/ConfirmModal";
import { ELanguages } from "../../localization/i18n";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import GenericCancelModalWithBtn from "../../modals/genericCancel/GenericCancelModalWithBtn";
import Header from "../../components/header/Header";
import { InputFlexTop } from "../../style/div";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import { PATHS } from "../../routes/routesEnum";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { get } from "lodash";
import { openToast } from "../../store/slice/toastSlice";
import { useAppDispatch } from "../../store/hook";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const formikInitialValues: INewUser = { user: "", role: "", businessUnit: "", skills: [] };

interface INewUserProps {
    userToEdit?: IEditUser;
}

const NewUser = (props: INewUserProps) => {
    const { userToEdit } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [users, setUser] = useState<any[]>([]);
    const [roles, setRoles] = useState<any[]>([]);

    const submit = useCallback(async (values: INewUser) => {
        setLoading(true);
        try {
            if (!!userToEdit) {
                let response = await updateUser(values);
            } else {
                let response = await createUser(values);
            }
            dispatch(openToast({ msg: t("newSurveyForm.success") }));
            navigate(PATHS.users);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);
    const fetchRolesAndUsers = useCallback(async () => {
        const [userResponse, rolesResponse] = await Promise.all([getAllUsersForCreation(), getAllRolesNoPaginate()]);
        const user = get(userResponse, "data", []);
        const roles = get(rolesResponse, "data", []);
        setUser(user.map((m) => ({ nameEn: `${m.surname} ${m.name}`, nameIt: `${m.surname} ${m.name}`, id: m.id })));
        setRoles(roles.map((r) => ({ nameEn: r.roleNameEn, nameIt: r.roleNameIt, id: r.id })));
    }, []);

    useEffect(() => {
        fetchRolesAndUsers();
    }, []);

    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <Formik
                initialValues={!!userToEdit ? userToEdit : formikInitialValues}
                onSubmit={(values, actions) => {
                    submit(values);
                }}
            >
                {({ handleSubmit, submitForm, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <VaporPage
                            title={!!userToEdit ? t("editUser") : t("newUser")}
                            subtitle=""
                            headerLeft={
                                <IconButton onClick={() => navigate(PATHS.users)}>
                                    <ArrowLeft color="primary" />
                                </IconButton>
                            }
                            contentToolbar={
                                <VaporToolbar
                                    size="large"
                                    contentRight={[
                                        <GenericCancelModalWithBtn cancel={() => navigate(PATHS.users)} key={"secondary"} />,
                                        <LoadingBtn
                                            loading={!userToEdit && loading}
                                            disabled={loading}
                                            type={!!userToEdit ? "button" : "submit"}
                                            msg={t("buttons.save")}
                                            key={"primary"}
                                            onClick={!!userToEdit ? () => setOpenConfirmModal(true) : undefined}
                                        />,
                                    ]}
                                />
                            }
                        >
                            <VaporPage.Section>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("newSurveySection1")}
                                </Typography>
                                <InputFlexTop>
                                    <FormControl
                                        sx={{
                                            m: 1,
                                            width: "50%",
                                        }}
                                        disabled={!!userToEdit}
                                    >
                                        <InputLabel id="simple-select-label">{t("user")}</InputLabel>

                                        {users.length > 0 && (
                                            <Select
                                                id="simple-select-label"
                                                label={t("user")}
                                                defaultValue={userToEdit?.user}
                                                disabled={!!userToEdit}
                                                onChange={(e) => {
                                                    if (!userToEdit) setFieldValue("user", e.target.value as string);
                                                }}
                                            >
                                                {!!userToEdit && (
                                                    <MenuItem value={userToEdit.user} id={`${userToEdit.user}`} key={userToEdit.user}>
                                                        {userToEdit.lastName} {userToEdit.name}
                                                    </MenuItem>
                                                )}
                                                {users.map((data: ICustomSelectOptions) => (
                                                    <MenuItem value={data.id} id={`${data.id}`} key={data.id}>
                                                        {i18n.language === ELanguages.it ? data.nameIt : data.nameEn}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            m: 1,
                                            width: "50%",
                                        }}
                                    >
                                        <InputLabel id="simple-select-label">{t("role")}</InputLabel>
                                        <Select id="simple-select-label" label={t("role")} defaultValue={userToEdit?.role} onChange={(e) => setFieldValue("role", e.target.value as number)}>
                                            {roles.map((data: ICustomSelectOptions) => (
                                                <MenuItem value={data.id} id={`${data.id}`} key={data.id}>
                                                    {i18n.language === ELanguages.it ? data.nameIt : data.nameEn}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </InputFlexTop>
                            </VaporPage.Section>
                        </VaporPage>
                        <ConfirmModal apply={submitForm} name={t("userName")} cancel={() => setOpenConfirmModal(false)} open={openConfirmModal} loading={loading} />
                    </form>
                )}
            </Formik>
        </VaporUIShellNav>
    );
};

export default NewUser;
