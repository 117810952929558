import styled from "styled-components";

export const FlexContainer = styled.div<{ $withMargin?: boolean; $clm?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 8px;
    flex-direction: ${(props) => (props.$clm ? "column" : "row")};
    margin: ${(props) => (props.$withMargin ? " 0 16px" : "")};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    height: 100%;
    gap: 16px;
`;
export const ContainerSmallGap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    height: 100%;
    gap: 8px;
`;
export const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;
`;
export const FlexClm = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
`;
export const FlexEnd = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
`;
export const FlexStart = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
`;
export const InputDivider = styled.div`
    margin: 32px 0;
    display: flex;
    gap: 16px;
`;
export const InputFlexTop = styled.div`
    margin-top: 32px;
    margin-bottom: 16px;
    display: flex;
    gap: 16px;
`;
export const InputFlexBtm = styled.div`
    margin-top: 16px;
    margin-bottom: 32px;
    display: flex;
    gap: 16px;
`;

export const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
