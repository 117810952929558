import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, TextField } from "@vapor/react-material";
import { ICustomSelectOptions, IModal, INewMacroCat } from "../../model";
import { createMacroCategory, getAllCategories, getAllCategoriesNoPaginate } from "../../api/helper";
import { useCallback, useEffect, useState } from "react";

import Close from "@vapor/react-icons/Xmark";
import CustomSelect from "../../components/customSelect/CustomSelect";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import { InputDivider } from "../../style/div";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import { get } from "lodash";
import { newMacroCategorySchema } from "../../validations";
import { openToast } from "../../store/slice/toastSlice";
import { useAppDispatch } from "../../store/hook";
import { useTranslation } from "react-i18next";

interface IModalMacroCategoryProps extends IModal {}

interface IFormikMacroCat {
    nameIt: string;
    nameEn: string;
    questionEn: string;
    questionIt: string;
    categories: ICustomSelectOptions[];
}

export const IFormikMacroCatInitValues = {
    nameIt: "",
    nameEn: "",
    questionEn: "",
    questionIt: "",
    categories: [],
};

const ModalMacroCategory = (props: IModalMacroCategoryProps) => {
    const { open, setOpen, functionAfterCreate } = props;
    const { t } = useTranslation();
    const [categories, setCategories] = useState<ICustomSelectOptions[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const fetchCategoriesForMacroCategory = useCallback(async () => {
        try {
            const response = await getAllCategoriesNoPaginate();
            let categories = get(response, "data", []);

            setCategories(categories.map((c) => ({ nameEn: c.nameEn, nameIt: c.nameIt, id: c.id })));
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchCategoriesForMacroCategory();
    }, []);

    const handleDialog = () => {
        setOpen(!open);
    };

    const submit = useCallback(async (values: INewMacroCat) => {
        setLoading(true);
        try {
            let result = await createMacroCategory(values);
            dispatch(openToast({ msg: t("newSurveyForm.success") }));
            setOpen(false);
            if (!!functionAfterCreate) functionAfterCreate(result.data);
        } catch (err) {
            dispatch(openToast({ msg: t("newSurveyForm.error"), severity: "error" }));
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <Dialog open={open} maxWidth="xl" fullWidth scroll="paper" onClose={handleDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <Formik
                initialValues={IFormikMacroCatInitValues}
                validationSchema={newMacroCategorySchema}
                onSubmit={(values, actions) => {
                    submit(values);
                }}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogTitle>
                            {t("newMacro")}
                            <IconButton color="primary" onClick={handleDialog}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <Divider variant="fullWidth" />
                        <DialogContent>
                            <InputDivider>
                                <FormikInput name="nameEn" label={t("nameEn")} required />
                                <FormikInput name="nameIt" label={t("nameIt")} required />
                            </InputDivider>
                            <InputDivider>
                                <FormikInput name="questionEn" label={t("newSurveyForm.mqEn")} required />
                                <FormikInput name="questionIt" label={t("newSurveyForm.mqIt")} required />
                            </InputDivider>
                            <CustomSelect name="categories" options={categories} setOptions={setCategories} addLabel={""} label={t("selectCategories")} />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="error" type="button" onClick={handleDialog}>
                                {t("buttons.cancel")}
                            </Button>
                            <LoadingBtn loading={loading} type="submit" msg={t("buttons.save")} key={"primary"} />
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default ModalMacroCategory;
