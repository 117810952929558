import { TextField } from "@vapor/react-material";
import { get } from "lodash";
import { useFormikContext } from "formik";

interface IFormikInputProps {
    name: string;
    label?: string;
    required?: boolean;
    sx?: any;
    placeholder?: string;
    hideError?: boolean;
}

const FormikInput = (props: IFormikInputProps) => {
    const { name, label, required, sx, placeholder, hideError } = props;
    const { handleChange, values, errors } = useFormikContext();
    let defaultValue = get(values, `${name}`, "");
    let error = get(errors, `${name}`, "");

    return (
        <TextField
            value={defaultValue}
            placeholder={placeholder}
            sx={{ ...sx }}
            // defaultValue={defaultValue}
            name={name}
            onChange={handleChange}
            id="outlined-basic"
            label={`${!!label ? label : ""}${required ? " *" : ""}`}
            variant="outlined"
            error={!!error && !hideError}
            helperText={!hideError && error}
        />
    );
};

export default FormikInput;
