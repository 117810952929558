import { IconButton, Tooltip } from "@vapor/react-material";

import TrashAlt from "@vapor/react-icons/TrashAlt";
import { useTranslation } from "react-i18next";

export interface IDeleteBtnProps {
    onClick?: any;
}
const DeleteBtn = (props: IDeleteBtnProps) => {
    const { t } = useTranslation();
    const { onClick } = props;
    return (
        <Tooltip arrow title={t("buttons.delete")}>
            <IconButton
                onClick={() => {
                    if (!!onClick) onClick();
                }}
            >
                <TrashAlt color="critical" />
            </IconButton>
        </Tooltip>
    );
};
export default DeleteBtn;
