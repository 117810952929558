import { Button, CircularProgress } from "@vapor/react-material";

interface ILoadingBtn {
    msg: string;
    onClick?: (() => void) | undefined;
    loading: boolean;
    type: "button" | "submit" | "reset" | undefined;
    color?: any;
    variant?: "text" | "contained" | "outlined" | undefined;
    disabled?: boolean;
    id?: string;
}

const LoadingBtn = (props: ILoadingBtn) => {
    const { msg, loading, type, onClick, color, variant, disabled, id } = props;
    return (
        <>
            {loading ? (
                <CircularProgress />
            ) : (
                <Button
                    disabled={disabled}
                    id={id}
                    color={!!color ? color : "primary"}
                    type={type ? type : "button"}
                    key={"primary"}
                    variant={variant ? variant : "contained"}
                    onClick={!!onClick ? onClick : () => {}}
                >
                    {msg}
                </Button>
            )}
        </>
    );
};

export default LoadingBtn;
