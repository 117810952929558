import { MenuType, UserRoles } from "../store/model";

import { PagesTab } from "../model/enum";

export enum PATHS {
    home = "/home",
    login = "/",
    surveys = "/surveys",
    newSurveys = "/surveys/new",
    editSurveys = "/surveys/:id",
    macrocategories = "/macrocategories",
    newMacrocategories = "/macrocategories/new",
    editMacrocategories = "/macrocategories/:id",
    categories = "/categories",
    newCategories = "/categories/new",
    editCategories = "/categories/:id",
    skills = "/skills",
    newSkills = "/skills/new",
    editSkills = "/skills/:id",
    roles = "/roles",
    newRoles = "/roles/new",
    editRoles = "/roles/:id",
    users = "/users",
    newUsers = "/users/new",
    editUsers = "/users/:id",
    mySkills = "/myskills",
    mySkillsView = "/myskills/:id",
    mySurveys = "/mysurveys",
    surveyToFill = "/mysurveys/:id",
    myTeam = "/myteam",
    guide="/guide",
    myTeamSurvey = "/myteamsurveys/:id",
    myTeamSkill = "/myteamskills",
}

export const GENERIC_PATHS = [
    { path: PATHS.mySurveys, label: "mySurveys", valueTab: PagesTab.mySurveys },
    { path: PATHS.mySkills, label: "mySkills", valueTab: PagesTab.mySkills },
];

export const PATHS_ADMIN = [
    { path: PATHS.surveys, label: "surveyTitle", valueTab: PagesTab.survey },
    { path: PATHS.macrocategories, label: "macroTitle", valueTab: PagesTab.macro },
    { path: PATHS.categories, label: "categoriesTitle", valueTab: PagesTab.cat },
    { path: PATHS.skills, label: "skillsTitle", valueTab: PagesTab.skills },
    { path: PATHS.roles, label: "rolesTitle", valueTab: PagesTab.roles },
    { path: PATHS.users, label: "usersTitle", valueTab: PagesTab.user },
];

export const PATHS_MANAGER = [
    { path: PATHS.myTeam, label: "myTeam", valueTab: PagesTab.myTeam },
    { path: PATHS.myTeamSkill, label: "myTeamSkills", valueTab: PagesTab.myTeamSkills },
];

export const PATHS_BY_ROLE = {
    [UserRoles.admin]: { [MenuType.admin]: PATHS_ADMIN, [MenuType.manager]: PATHS_MANAGER, [MenuType.normal]: GENERIC_PATHS },
    [UserRoles.manager]: { [MenuType.manager]: PATHS_MANAGER, [MenuType.normal]: GENERIC_PATHS },
    [UserRoles.normal]: { [MenuType.normal]: GENERIC_PATHS },
};
