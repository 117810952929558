import { IEditMacroCat, INewMacroCat } from "../../model";
import { selectMacroById, selectMacros } from "../../store/slice/macroSlice";
import { useCallback, useEffect, useState } from "react";

import LoadingPage from "../../components/loadingPage/LoadingPage";
import NewMacro from "./NewMacro";
import { PATHS } from "../../routes/routesEnum";
import { get } from "lodash";
import { getMacroCategoryById } from "../../api/helper";
import { sleep } from "../../utils/utils";
import { useAppSelector } from "../../store/hook";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditMacroCat = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const macroById = useAppSelector((state) => selectMacroById(state, parseInt(!!id ? id : "")));
    const [macroToEdit, setMacroToEdit] = useState<IEditMacroCat>();

    const fetchMacroById = useCallback(async () => {
        setLoading(true);
        try {
            if (!!id) {
                let response = await getMacroCategoryById(parseInt(id));
                let macroCat = get(response, "data");
                setMacroToEdit({ ...macroCat, surveys: macroCat.surveys.map((s) => ({ id: s.id, nameEn: s.titleEn, nameIt: s.titleIt })) });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchMacroById();
    }, []);

    return <>{loading ? <LoadingPage title={t("editMacro")} path={PATHS.macrocategories} /> : <NewMacro macroToEdit={macroToEdit} />}</>;
};

export default EditMacroCat;
