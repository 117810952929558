import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@vapor/react-material";
import { ICustomSelectOptions, IMacroCatBe, IRoleBe } from "../../model";
import { InputDivider, InputFlexBtm, InputFlexTop } from "../../style/div";
import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";
import { createSurvey, editSurvey, getAllMacroCategoriesNoPaginate, getAllRolesNoPaginate } from "../../api/helper";
import { useCallback, useEffect, useState } from "react";

import ArrowLeft from "@vapor/react-icons/ArrowLeft";
import ConfirmModal from "../../modals/confirm/ConfirmModal";
import CustomSelect from "../../components/customSelect/CustomSelect";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import GenericCancelModalWithBtn from "../../modals/genericCancel/GenericCancelModalWithBtn";
import Header from "../../components/header/Header";
import LoadingBtn from "../../components/loadingBtn/LoadingBtn";
import ModalMacroCategory from "../../modals/newMacroCat/ModalMacroCategory";
import ModalNewRole from "../../modals/newRole/ModalNewRole";
import { PATHS } from "../../routes/routesEnum";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { get } from "lodash";
import { newSurvey } from "../../validations";
import { openToast } from "../../store/slice/toastSlice";
import { useAppDispatch } from "../../store/hook";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface INewSurvey {
    nameIt: string;
    nameEn: string;
    macros: ICustomSelectOptions[];
    roles: ICustomSelectOptions[];
    expired: number | string;
    id?: number;
}

const formikInitialValues: INewSurvey = { nameIt: "", nameEn: "", macros: [], roles: [], expired: "" };
interface INewSurveyProps {
    surveyToEdit?: INewSurvey;
}

const NewSurvey = ({ surveyToEdit }: INewSurveyProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openModalMacro, setOpenModalMacro] = useState<boolean>(false);
    const [openModalRoles, setOpenModalRoles] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [macros, setMacros] = useState<ICustomSelectOptions[]>([]);
    const [roles, setRoles] = useState<ICustomSelectOptions[]>([]);

    const submit = useCallback(
        async (values: INewSurvey) => {
            setLoading(true);
            try {
                if (!!surveyToEdit) {
                    let response = await editSurvey({ ...values, id: surveyToEdit?.id });
                } else {
                    let response = await createSurvey(values);
                }
                dispatch(openToast({ msg: t("newSurveyForm.success") }));
                navigate(PATHS.surveys);
            } catch (err) {
                console.log(err);
                dispatch(openToast({ msg: t("newSurveyForm.error"), severity: "error" }));
            } finally {
                setLoading(false);
            }
        },
        [editSurvey]
    );

    const fetchRolesAndMacros = useCallback(async () => {
        const [macroResponse, rolesResponse] = await Promise.all([getAllMacroCategoriesNoPaginate(), getAllRolesNoPaginate()]);
        const macros = get(macroResponse, "data", []);
        const roles = get(rolesResponse, "data", []);
        if (!!surveyToEdit) {
            let selectedmacros = surveyToEdit.macros.map((m) => m.id);
            let selectedRoles = surveyToEdit.roles.map((r) => r.id);
            let filteredMacros = macros.filter((m) => !selectedmacros.includes(m.id));
            let filteredRoles = roles.filter((m) => !selectedRoles.includes(m.id));

            setMacros(filteredMacros.map((m) => ({ nameEn: m.nameEn, nameIt: m.nameIt, id: m.id })));
            setRoles(filteredRoles.map((r) => ({ nameEn: r.roleNameEn, nameIt: r.roleNameIt, id: r.id })));
        } else {
            setMacros(macros.map((m) => ({ nameEn: m.nameEn, nameIt: m.nameIt, id: m.id })));
            setRoles(roles.map((r) => ({ nameEn: r.roleNameEn, nameIt: r.roleNameIt, id: r.id })));
        }
    }, []);

    useEffect(() => {
        fetchRolesAndMacros();
    }, [surveyToEdit]);

    let expiredData = [3, 6, 9, 12, 24];
    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <Formik
                validationSchema={newSurvey}
                initialValues={!!surveyToEdit ? surveyToEdit : formikInitialValues}
                onSubmit={(values, actions) => {
                    submit(values);
                }}
            >
                {({ handleSubmit, errors, values, setFieldValue, submitForm }) => (
                    <form onSubmit={handleSubmit}>
                        <VaporPage
                            title={!!surveyToEdit ? t("editSurvey") : t("newSurvey")}
                            subtitle=""
                            headerLeft={
                                <IconButton onClick={() => navigate(PATHS.surveys)}>
                                    <ArrowLeft color="primary" />
                                </IconButton>
                            }
                            contentToolbar={
                                <VaporToolbar
                                    size="large"
                                    contentRight={[
                                        <GenericCancelModalWithBtn cancel={() => navigate(PATHS.surveys)} key={"navigate"} />,
                                        <LoadingBtn
                                            loading={!surveyToEdit && loading}
                                            disabled={loading}
                                            type={!!surveyToEdit ? "button" : "submit"}
                                            msg={t("buttons.save")}
                                            key={"primary"}
                                            onClick={!!surveyToEdit ? () => setOpenConfirmModal(true) : undefined}
                                        />,
                                    ]}
                                />
                            }
                        >
                            <VaporPage.Section divider>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("newSurveySection1")}
                                </Typography>
                                <InputFlexTop>
                                    <FormikInput name="nameEn" label={t("newSurveyForm.nameEng")} />
                                    <FormikInput name="nameIt" label={t("newSurveyForm.nameIt")} />
                                </InputFlexTop>
                                <InputFlexBtm>
                                    <FormControl fullWidth>
                                        <InputLabel id="simple-select-label">{t("newSurveyForm.expirationDate")}</InputLabel>
                                        <Select
                                            id="simple-select-label"
                                            label={t("newSurveyForm.expirationDate")}
                                            value={values.expired}
                                            error={!!errors.expired}
                                            onChange={(e) => setFieldValue("expired", e.target.value as number)}
                                        >
                                            {expiredData.map((e) => (
                                                <MenuItem value={e} id={`${e}`} key={e}>
                                                    {e}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        {!!errors.expired ? (
                                            <div style={{ marginTop: "8px", fontSize: "14.3px", display: "flex", fontWeight: 400 }}>
                                                <RemoveCircleIcon fontSize="inherit" style={{ color: "red", marginRight: "4px" }} />
                                                <Typography variant="caption" color="primary.error" component="div">
                                                    {t("required")}
                                                </Typography>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </FormControl>
                                </InputFlexBtm>
                            </VaporPage.Section>
                            <VaporPage.Section divider>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("newSurveySection2")}
                                </Typography>
                                <InputDivider>
                                    <CustomSelect name="macros" options={macros} setOptions={setMacros} addLabel={t("newMacro")} label={t("selectMacro")} openModal={setOpenModalMacro} />
                                </InputDivider>
                            </VaporPage.Section>
                            <VaporPage.Section>
                                <Typography variant="titleMedium" color="primary.textTitleColor">
                                    {t("newSurveySection3")}
                                </Typography>
                                <InputDivider>
                                    <CustomSelect name="roles" withRowSelector options={roles} setOptions={setRoles} addLabel={t("newRole")} label={t("selectRole")} openModal={setOpenModalRoles} />
                                </InputDivider>
                            </VaporPage.Section>
                        </VaporPage>
                        <ModalMacroCategory
                            open={openModalMacro}
                            setOpen={setOpenModalMacro}
                            functionAfterCreate={(macro: IMacroCatBe) => {
                                setFieldValue("macros", [macro, ...get(values, "macros", [])]);
                            }}
                        />
                        <ModalNewRole
                            open={openModalRoles}
                            setOpen={setOpenModalRoles}
                            functionAfterCreate={(role: IRoleBe) => {
                                setFieldValue("roles", [{ nameEn: role.roleNameEn, nameIt: role.roleNameIt, id: role.id }, ...get(values, "roles", [])]);
                            }}
                        />
                        <ConfirmModal apply={submitForm} name={t("surveyName")} cancel={() => setOpenConfirmModal(false)} open={openConfirmModal} loading={loading} />
                    </form>
                )}
            </Formik>
        </VaporUIShellNav>
    );
};

export default NewSurvey;
