import "./App.css";
import "./localization/i18n";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Container, Skeleton } from "@vapor/react-material";
import { Routes, normalUserRoute } from "./routes/routes";
import { VaporPage, VaporUIShellNav } from "@vapor/react-custom";
import { selectUser, setProfileImg, setUserId, setUserRole } from "./store/slice/userSlice";
import { useAppDispatch, useAppSelector } from "./store/hook";
import { useEffect, useState } from "react";

import Header from "./components/header/Header";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import Landing from "./pages/landing/Landing";
import { RouterProvider } from "react-router-dom";
import { STRING_TOKEN } from "./utils/utils";
import { UserRoles } from "./store/model";
import { get } from "lodash";
import { getUserToken } from "./api/helper";
import { loginRequest } from "./msal/azureAd";

const App = () => {
    const user = useAppSelector(selectUser);
    const role = get(user, "data.userType");
    const route = get(Routes, `[${role}]`, normalUserRoute);
    const dispatch = useAppDispatch();
    const { accounts, instance } = useMsal();
    const [loading, setLoading] = useState<boolean>(false);

    const checkRedirect = async () => {
        setLoading(true);
        try {
            await instance.handleRedirectPromise();
            let activeAcc = instance.getActiveAccount();
            let tokenSilent;

            if (!activeAcc && accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
                tokenSilent = await instance.acquireTokenSilent({ account: accounts[0], ...loginRequest });
            }

            if (!!activeAcc) {
                tokenSilent = await instance.acquireTokenSilent({ account: activeAcc, ...loginRequest });
            }
            if (!!tokenSilent) {
                let token = await getUserToken(tokenSilent.accessToken);
                dispatch(setUserRole(token.data.role as UserRoles));
                dispatch(setUserId(token.data.userId));
                if (!!token && !!token.data.profilePhoto) dispatch(setProfileImg(token.data.profilePhoto));
                localStorage.setItem(STRING_TOKEN, token.data.accessToken);
            }
        } catch (err) {
            console.log(err);
            if (err instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return instance.acquireTokenRedirect(loginRequest);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkRedirect();
    }, [accounts]);

    return (
        <>
            <AuthenticatedTemplate>
                {loading ? (
                    <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header loading />} renderDrawer={<></>}>
                        <VaporPage variant="basic">
                            <Container>
                                <Skeleton width={"100%"} height={400} />
                            </Container>
                        </VaporPage>
                    </VaporUIShellNav>
                ) : (
                    <RouterProvider router={route} />
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Landing loading={loading} />
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;
