import { Dispatch, SetStateAction } from "react";
import { ELanguages, t } from "../localization/i18n";
import { PagesTab, SurveyStatus, YesNo } from "./enum";

import { PATHS } from "../routes/routesEnum";
import { UserRoles } from "../store/model";

export const languagesLabel = {
    [ELanguages.en]: t("en"),
    [ELanguages.it]: t("it"),
};
export const yesNoLabel = {
    [YesNo.yes]: t("radioYes"),
    [YesNo.no]: t("radioNo"),
};
export const genericLabelTab = {
    [PagesTab.mySurveys]: PATHS.mySurveys,
    [PagesTab.mySkills]: PATHS.mySkills,
};
export const pageLabelTabManager = {
    ...genericLabelTab,
    [PagesTab.myTeam]: PATHS.myTeam,
    [PagesTab.myTeamSkills]: PATHS.myTeamSkill,
};

export const pageLabelTabAdmin = {
    ...genericLabelTab,
    [PagesTab.cat]: PATHS.categories,
    [PagesTab.macro]: PATHS.macrocategories,
    [PagesTab.roles]: PATHS.roles,
    [PagesTab.skills]: PATHS.skills,
    [PagesTab.survey]: PATHS.surveys,
    [PagesTab.user]: PATHS.users,
};

export const pageLabelTabNormalUser = {
    ...genericLabelTab,
};

export const pageLabelTab = {
    [PagesTab.cat]: PATHS.categories,
    [PagesTab.macro]: PATHS.macrocategories,
    [PagesTab.roles]: PATHS.roles,
    [PagesTab.skills]: PATHS.skills,
    [PagesTab.survey]: PATHS.surveys,
    [PagesTab.user]: PATHS.users,
};

export const tabLabelPage = {
    [PATHS.mySurveys]: PagesTab.mySurveys,
    [PATHS.mySkills]: PagesTab.mySkills,
    [PATHS.categories]: PagesTab.cat,
    [PATHS.newCategories]: PagesTab.cat,
    [PATHS.macrocategories]: PagesTab.macro,
    [PATHS.newMacrocategories]: PagesTab.macro,
    [PATHS.editMacrocategories]: PagesTab.macro,
    [PATHS.roles]: PagesTab.roles,
    [PATHS.newRoles]: PagesTab.roles,
    [PATHS.skills]: PagesTab.skills,
    [PATHS.newSkills]: PagesTab.skills,
    [PATHS.surveys]: PagesTab.survey,
    [PATHS.newSurveys]: PagesTab.survey,
    [PATHS.users]: PagesTab.user,
    [PATHS.newUsers]: PagesTab.user,
    [PATHS.myTeam]: PagesTab.myTeam,
    [PATHS.myTeamSkill]: PagesTab.myTeamSkills,
};

export const Role = {
    [UserRoles.admin]: pageLabelTabAdmin,
    [UserRoles.manager]: pageLabelTabManager,
    [UserRoles.normal]: pageLabelTabNormalUser,
};

export interface IHeadCells {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string | number;
    queryName?: string;
    filter?: JSX.Element;
}

export interface ISurveyToFillFormik {
    [key: string]: any;
}
// export interface ICategoryToFillFormik {
//     [catId: string]: ISkillToFillFormik;
// }
export interface ISkillToFillFormik {
    [key: string]: SkillWithVote | boolean;
    isMacroCategoryResponse: boolean;
}
export interface SkillWithVote {
    macroCategoryId: number;
    skillId: number;
    vote: number;
    lastVote?: number;
    isMacroCategoryResponse: boolean;
}
export interface ISurveyToSubmit {
    surveyId: number;
    isDraft: boolean;
    skillWithVote: SkillWithVote[];
}

export interface ISurveyBe {
    id: number;
    titleIt: string;
    titleEn: string;
    descriptionIt: string;
    descriptionEn: string;
    macroCategories: IMacroCatBe[];
    expireInMonths: number;
    status: SurveyStatus;
    roles: IRoleBe[];
    required?: boolean;
    isMandatory?: boolean;
    lastVote: string;
}
export interface ISurveyInMacroBe {
    id: number;
    titleIt: string;
    titleEn: string;
}
export interface ISurveyFe extends ISurveyBe {
    requiredFe?: string;
}

export interface ICreateEditSurvey {
    id?: number;
    titleIt: string;
    titleEn: string;
    descriptionIt: string;
    descriptionEn: string;
    macroCategoryIds: number[];
    expirationInMonths: number;
    roles: {
        roleId: number;
        isMandatory: boolean;
    }[];
}
export interface IPaginationRedux {
    forceCall?: boolean;
    pageNumber?: number;
    records?: number;
    query?: string;
    sorting?: string;
}
export interface IGetParams extends IPaginationRedux {
    nameFilter?: string;
    sortOrder?: string;
}
export interface IGetSkillForAdmin {
    skillId?: number;
    surveyId?: number;
    userId?: string;
}

export interface ISkillForAdminResponse {
    surveyId: number;
    skillId: number;
    vote: number;
    createdAt: string;
    surveyTitleEn: string;
    surveyTitleIt: string;
    skillNameEn: string;
    skillNameIt: string;
    userFirstName: string;
    userLastName: string;
}
export type TGetSkillForAdminResponse = IGetSkillForAdminResponse[];

export interface IGetSkillForAdminResponse {
    userId: string;
    skills: ISkillForAdminResponse[];
}

export interface ICustomSelectOptions {
    nameIt: string;
    nameEn: string;
    id: number;
    mandatory?: boolean;
}

export interface IModal {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    functionAfterCreate?: any;
}

export interface ISkillBe {
    id: number;
    nameIt: string;
    nameEn: string;
    vote: number;
    descriptionIt: string;
    descriptionEn: string;
}

export interface ISkillSurvey {
    id: number;
    titleIt: string;
    titleEn: string;
}
export interface ISkillWithSurvey {
    id: number;
    nameIt: string;
    nameEn: string;
    descriptionIt: string;
    descriptionEn: string;
    survey: ISkillSurvey;
    category: ICategoryOnSkill;
}
export interface ILegendBe {
    id: number;
    nameEn: string;
    nameIt: string;
    level: number;
}
export interface ICategoryOnSkill {
    id: number;
    nameIt: string;
    nameEn: string;
    questionIt: string;
    questionEn: string;
}
export interface ICategoryBe {
    id: number;
    nameIt: string;
    nameEn: string;
    questionIt: string;
    questionEn: string;
    skills: ISkillBe[];
    legends: ILegendBe[];
    macroCategories: IMacroCatBe[];
}

export interface INewCategorySerialized {
    nameIt: string;
    nameEn: string;
    questionIt: string;
    questionEn: string;
    skillIds: number[];
    legends: string;
    id?: string | number;
}
export interface IEditCategorySerialized extends INewCategorySerialized {}

export interface INewMacroCategorySerialized {
    id?: number;
    nameIt: string;
    nameEn: string;
    questionIt: string;
    questionEn: string;
    categoryIds: number[];
}
export interface IMacroCatBe {
    id: number;
    nameIt: string;
    nameEn: string;
    descriptionIt: string;
    descriptionEn: string;
    questionIt: string;
    questionEn: string;
    isMacroCategoryResponse: boolean;
    questionEnable: boolean;
    categories: ICategoryBe[];
    surveys: ISurveyInMacroBe[];
}
export interface INewMacroCat {
    id?: number;
    nameIt: string;
    nameEn: string;
    questionIt: string;
    questionEn: string;
    categories: ICustomSelectOptions[];
    surveyIt?: string;
    surveyEn?: string;
}
export interface IEditMacroCat extends INewMacroCat {
    surveys?: ICustomSelectOptions[];
}

export interface INewCategory {
    id?: string | number;
    nameIt: string;
    nameEn: string;
    questionIt: string;
    questionEn: string;
    legends: ICustomSelectOptions[];
    skills: ICustomSelectOptions[];
    mcsIt?: string;
    mcsEn?: string;
}
export interface IEditCategory extends INewCategory {
    macroCat: ICustomSelectOptions[];
}

export interface INewSkill {
    nameIt: string;
    nameEn: string;
    descriptionIt: string;
    descriptionEn: string;
}
export interface IEditSkill extends INewSkill {
    id: number;
    surveyTitleIt?: string;
    surveyTitleEn?: string;
    idSurvey?: number;
    catEn?: string;
    catIt?: string;
}

export interface INewRole {
    roleNameEn: string;
    roleNameIt: string;
    surveyIds: ICustomSelectOptions[];
}
export interface INewRoleBe {
    roleNameEn: string;
    roleNameIt: string;
    id?: number;
    surveys: { surveyId: number; isMandatory?: boolean }[];
}
export interface IEditRole extends INewRole {
    id?: number;
}
export interface INewUser {
    user: string;
    role: string;
    businessUnit: string;
    skills: any[];
}

export interface IEditUser extends INewUser {
    name?: string;
    lastName?: string;
}

export interface IRoleBe {
    id: number;
    roleNameEn: string;
    roleNameIt: string;
    isMandatory?: boolean;
    surveys: ISurveyBe[];
}
export interface IBusinessUnitBe {
    id: number;
    name: string;
}
export interface IUserBe {
    id: string;
    firstName: string;
    lastName: string;
    role: IRoleBe;
    businessUnit: IBusinessUnitBe;
}

export interface ISimpleUserBe {
    id: string;
    name: string;
    surname: string;
}
export interface SkillWithVoteFormik {
    [key: string]: SkillWithVote;
    // skillWithVote: SkillWithVote[];
}

export interface IGetMySkill {
    surveyId: number;
    skillId: number;
    vote: number;
    createdAt: string;
    surveyTitleEn: string;
    surveyTitleIt: string;
    skillNameEn: string;
    skillNameIt: string;
    userFirstName: string;
    userLastName: string;
    histories: History[];
}

export interface History {
    vote: number;
    date: string;
}
