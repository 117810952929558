import { DEFAULT_RECORD, checkTimeElapsed } from "../../utils/utils";
import { EReduxState, IReduxState } from "../model";
import { IEditSkill, IGetParams, ISkillBe, ISkillWithSurvey } from "../../model";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { deSerializeSkill } from "../../model/deserialize";
import { get } from "lodash";
import { getAllSkills } from "../../api/helper";

export enum skillAction {
    get = "skill/getAll",
}

interface ISkills {
    skills: IEditSkill[];
    page: number;
    lastFetch: number;
    record: number;
    status: EReduxState;
    totalPages: number;
}

const initialState: IReduxState<ISkills> = {
    error: undefined,
    data: { skills: [], page: 1, totalPages: 1, lastFetch: 0, record: DEFAULT_RECORD, status: EReduxState.initialState },
};

export const skillsSlice = createSlice({
    name: "skillsSlice",
    initialState,
    reducers: {
        setRecordSurvey: (state, action: PayloadAction<number>) => {
            state.data.record = action.payload;
        },
        deleteSkill: (state, action: PayloadAction<number>) => {
            state.data.skills = [...state.data.skills.filter((sk) => sk.id !== action.payload)];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(shouldFetchAllSkills.pending, (state) => {
                state.data.status = EReduxState.pending;
            })
            .addCase(shouldFetchAllSkills.fulfilled, (state, action) => {
                state.data.status = EReduxState.fulfilled;
                state.data.lastFetch = new Date().getTime() / 1000;
                state.data.skills = get(action, "payload.skills", []);
                state.data.page = get(action, "payload.page", 1);
                state.data.totalPages = get(action, "payload.totalPages", 1);
            })
            .addCase(shouldFetchAllSkills.rejected, (state, action) => {
                state.data.status = EReduxState.rejected;
            });
    },
});
export const shouldFetchAllSkills = createAsyncThunk(skillAction.get, async (pagination: IGetParams, { rejectWithValue, getState }) => {
    try {
        const state = getState() as RootState;
        let { lastFetch, page } = state.skillsSlice.data;
        if (!lastFetch || (!!lastFetch && checkTimeElapsed(lastFetch)) || page !== pagination.pageNumber || pagination.forceCall) {
            let data = await getAllSkills(pagination);
            return { skills: data.data.items.map((item) => deSerializeSkill(item)), page: pagination.pageNumber, totalPages: data.data.totalPages };
        } else {
            return { skills: state.skillsSlice.data.skills, page: state.skillsSlice.data.page, totalPages: state.skillsSlice.data.totalPages };
        }
    } catch (error: any) {
        return rejectWithValue(error.response);
    }
});

export const selectSkills = (state: RootState) => state.skillsSlice;
export const { setRecordSurvey, deleteSkill } = skillsSlice.actions;

export default skillsSlice.reducer;
