import {
    ICategoryBe,
    ICreateEditSurvey,
    IEditRole,
    IEditSkill,
    ILegendBe,
    IMacroCatBe,
    INewCategory,
    INewCategorySerialized,
    INewMacroCat,
    INewMacroCategorySerialized,
    INewRole,
    INewRoleBe,
    INewUser,
    ISkillBe,
    ISurveyBe,
    ISurveyFe,
} from "..";

import { INewSurvey } from "../../pages/survey/NewSurvey";

export const serializeNewCategory = (category: INewCategory): INewCategorySerialized => {
    return {
        nameEn: category.nameEn,
        nameIt: category.nameIt,
        questionIt: category.questionIt,
        questionEn: category.questionEn,
        skillIds: category.skills.map((sk) => sk.id),
        legends: JSON.stringify(category.legends),
        id: category.id,
    };
};
export const serializeNewMacroCategory = (macroCat: INewMacroCat): INewMacroCategorySerialized => {
    return {
        nameEn: macroCat.nameEn,
        nameIt: macroCat.nameIt,
        questionIt: macroCat.questionIt,
        questionEn: macroCat.questionEn,
        categoryIds: macroCat.categories.map((c) => c.id),
        id: macroCat.id,
    };
};
export const serializeUser = (user: INewUser): { roleId: number; userId: string } => {
    return { roleId: parseInt(user.role), userId: user.user };
};

export const serializeSurvey = (survey: INewSurvey): ICreateEditSurvey => {
    return {
        id: survey.id,
        macroCategoryIds: survey.macros.map((m) => m.id),
        descriptionEn: "",
        descriptionIt: "",
        titleEn: survey.nameEn,
        titleIt: survey.nameIt,
        expirationInMonths: survey.expired as number,
        roles: survey.roles.map((r) => ({ roleId: r.id, isMandatory: !!r.mandatory ? r.mandatory : false })),
    };
};
export const serializeRole = (role: IEditRole): INewRoleBe => {
    return {
        roleNameEn: role.roleNameEn,
        roleNameIt: role.roleNameIt,
        surveys: role.surveyIds.map((data) => ({ surveyId: data.id, isMandatory: data.mandatory })),
        id: role?.id,
    };
};
