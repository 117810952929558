import { ACTION_ID, ARROWS_ID, PAGE_RECORDS, normalizeStringForRest } from "../../utils/utils";
import { Button, FormControl, InputLabel, Pagination, Stack, TextField } from "@vapor/react-material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { EOrder, Order } from "../../model/enum";
import { FilterContainer, FlexCenter, FlexContainer, FlexEnd } from "../../style/div";
import { selectMyTeamSurveys, shouldFetchAllMyTeamSurveys } from "../../store/slice/myTeamSurveySlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import CustomPage from "../../components/customPage/CustomPage";
import CustomSearch from "../../components/customSearch/CustomSearch";
import DeleteRow from "../../modals/deleteRow/DeleteRow";
import { ELanguages } from "../../localization/i18n";
import { EReduxState } from "../../store/model";
import EmptyState from "@vapor/react-custom/EmptyState";
import FilterBtn from "../../components/btns/FilterBtn";
import Search from "@vapor/react-icons/Search";
import SelectRecord from "../../components/selectRecord/SelectRecord";
import UsersTableWithCollapse from "../../components/table/CollapsibleTable";
import { useTranslation } from "react-i18next";

const MyTeam = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectMyTeamSurveys);
    const [record, setRecord] = useState<number>(PAGE_RECORDS[0]);
    const [modalRemove, setModalRemove] = useState<{ show: boolean; id: number }>({ show: false, id: -1 });
    const deleteRow = useCallback(async () => {}, [modalRemove]);
    const { status, users } = data;
    const [search, setSearch] = useState<string>("");

    const [order, setOrder] = useState<Order>(EOrder.asc);
    const [orderBy, setOrderBy] = useState<string>("name");
    const [sortBy, setSortBy] = useState<string>("");

    const [filterQuery, setFilterQuery] = useState<string>("");

    const [filterUser, setFilterUser] = useState<string>("");
    const [filterRole, setFilterRole] = useState<string>("");

    const [filteredRole, setFilteredRole] = useState<boolean>(false);
    const [filteredName, setFilteredName] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);

    const fetchAllTeamSurvey = useCallback(async () => {
        dispatch(shouldFetchAllMyTeamSurveys({ pageNumber: 1, records: record, forceCall: true, nameFilter: search, query: filterQuery }));
    }, [record, filterQuery]);

    useEffect(() => {
        fetchAllTeamSurvey();
    }, [record, search]);

    const sort = useCallback(
        (cellId: string, fieldName: string, isAsc: boolean) => {
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
            setOrderBy(cellId);
            setPage(1);
            setSortBy(fieldName);
            dispatch(shouldFetchAllMyTeamSurveys({ pageNumber: 1, sortOrder: isAsc ? EOrder.desc : EOrder.asc, sorting: fieldName, records: record, query: filterQuery, forceCall: true }));
        },
        [filterQuery, record],
    );

    return (
        <CustomPage pageTitle={t("myTeam")}>
            {status === EReduxState.rejected ? (
                <FlexContainer>
                    <EmptyState badgeIcon description={t("error")} title={t("apiError")} />
                </FlexContainer>
            ) : (
                <>
                    <SelectRecord record={record} setRecord={setRecord} />
                    <UsersTableWithCollapse
                        loading={status === EReduxState.pending}
                        headCells={[
                            { id: ARROWS_ID, label: "", numeric: false, disablePadding: false },
                            {
                                id: "name",
                                label: t("clmName"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "user",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredName}
                                        cleanFilter={() => {
                                            setFilteredName(false);
                                            setFilterQuery(!!filterRole ? `&filters=${filterRole}` : "");
                                            setFilterUser("");
                                            dispatch(
                                                shouldFetchAllMyTeamSurveys({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: !!filterRole ? `&filters=${filterRole}` : "",
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredName(true);
                                                    setFilterQuery(`&filters=${filterUser}${!!filterRole ? `;${filterRole}` : ""}`);
                                                    dispatch(
                                                        shouldFetchAllMyTeamSurveys({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${filterUser}${!!filterRole ? `;${filterRole}` : ""}`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.name")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        name="search"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterUser(`filteruser=${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            {
                                id: i18n.language === ELanguages.en ? "roleEn" : "roleIt",
                                label: t("clmRoles"),
                                numeric: false,
                                disablePadding: false,
                                queryName: "role",
                                filter: (
                                    <FilterBtn
                                        showDelete={filteredRole}
                                        cleanFilter={() => {
                                            setFilteredRole(false);
                                            setFilterQuery(!!filterUser ? `&filters=${filterUser}` : "");
                                            setFilterRole("");
                                            dispatch(
                                                shouldFetchAllMyTeamSurveys({
                                                    pageNumber: 1,
                                                    sortOrder: order,
                                                    sorting: sortBy,
                                                    records: record,
                                                    query: !!filterUser ? `&filters=${filterUser}` : "",
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setFilteredRole(true);
                                                    setFilterQuery(`&filters=${filterRole}${!!filterUser ? `;${filterUser}` : ""}`);
                                                    dispatch(
                                                        shouldFetchAllMyTeamSurveys({
                                                            pageNumber: 1,
                                                            sortOrder: order,
                                                            sorting: sortBy,
                                                            records: record,
                                                            query: `&filters=${filterRole}${!!filterUser ? `;${filterUser}` : ""}`,
                                                            forceCall: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <InputLabel id="simple-search-text">{t("filter.role")}</InputLabel>
                                                <FlexCenter>
                                                    <TextField
                                                        id="simple-search-text"
                                                        name="search"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setFilterRole(`filterrole=${normalizeStringForRest(e.target.value)}`);
                                                        }}
                                                    />
                                                    <Button
                                                        size="medium"
                                                        sx={{
                                                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
                                                                borderRadius: 0,
                                                                minWidth: 40,
                                                            },
                                                        }}
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <Search color="white" />
                                                    </Button>
                                                </FlexCenter>
                                            </form>
                                        </FormControl>
                                    </FilterBtn>
                                ),
                            },
                            { id: ACTION_ID, label: "", numeric: false, disablePadding: false },
                        ]}
                        rows={
                            status === EReduxState.pending
                                ? Array.from(Array(record).keys())
                                : users.map((data) => ({ ...data, name: `${data.lastName} ${data.firstName}`, roleEn: data.role.roleNameEn, roleIt: data.role.roleNameIt }))
                        }
                        parentOrder={order}
                        fieldToOrder={orderBy}
                        parentSortAscDesc={sort}
                    />
                    <FlexEnd>
                        {data.totalPages > 1 && (
                            <Pagination
                                count={data.totalPages}
                                page={page}
                                onChange={(event: ChangeEvent<unknown>, page: number) => {
                                    setPage(page);
                                    dispatch(
                                        shouldFetchAllMyTeamSurveys({
                                            pageNumber: page,
                                            sortOrder: order,
                                            sorting: sortBy,
                                            records: record,
                                            query: filterQuery,
                                            forceCall: true,
                                        }),
                                    );
                                }}
                            />
                        )}
                    </FlexEnd>
                </>
            )}
            <DeleteRow apply={deleteRow} cancel={() => setModalRemove((oldState) => ({ ...oldState, show: false }))} open={modalRemove.show} />
        </CustomPage>
    );
};

export default MyTeam;
