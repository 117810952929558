import { VaporPage, VaporUIShellNav } from "@vapor/react-custom";

import { Container } from "../../style/div";
import Header from "../header/Header";
import Typography from "@vapor/react-extended/ExtendedTypography";

interface ICustomPageProps {
    children: any;
    pageTitle: string;
}

const CustomPage = (props: ICustomPageProps) => {
    const { children, pageTitle } = props;
    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <VaporPage
                variant="basic"
                // contentToolbar={<VaporToolbar size="large" contentLeft={[<Button variant="outlined">Secondary Left</Button>, <Button variant="contained">Primary Left</Button>]} />}
            >
                <Container>
                    {pageTitle && (
                        <Typography color="primary.main" component="div" variant="titleMedium">
                            {pageTitle}
                        </Typography>
                    )}
                    {/* <VaporPage.Section divider>
                        <Typography variant="body500">Section 1</Typography>
                    </VaporPage.Section>
                    <VaporPage.Section>
                        <Typography variant="body5
                        00">Section 2</Typography>
                    </VaporPage.Section> */}
                    {children}
                </Container>
            </VaporPage>
        </VaporUIShellNav>
    );
};

export default CustomPage;
