import { MenuType } from "../store/model";
import { SkillWithVote } from "../model";
import { format } from "date-fns";

export function sleep(n: number = 500) {
    return new Promise((r) => setTimeout(r, n));
}
export const ACTION_ID = "actions";
export const ARROWS_ID = "arrows";
export const ACTION_VIEW_ID = "actions_view";
export const ACTION_WITH_VIEW_ID = "actions_with_view";
export const STRING_TOKEN = "c-token";
export const STATUS_ID = "status";
export const MACRO_CAT_KEY = "macro-cat-";
export const CAT_KEY = "cat-";
export const SKILL_KEY = "skill-";

export const DEFAULT_RECORD = 10;
export const PAGE_RECORDS = [10, 20, 30];
export const LOCALSTORAGE_MENU = "user_menu";
const FIVE_MIN_IN_SEC = 300;

/**
 *
 * @param startDate date to check in seconds
 * @param timeToCheck option param in seconds (default 5 min)
 * @returns
 */
export const checkTimeElapsed = (startDate: number, timeToCheck = FIVE_MIN_IN_SEC): boolean => {
    let currentTime = new Date().getTime() / 1000; // convert milliseconds to seconds.
    return startDate - currentTime >= timeToCheck;
};

export const createMacroCatKey = (id: string | number): string => `${MACRO_CAT_KEY}${id}`;
export const createCatKey = (id: string | number): string => `${CAT_KEY}${id}`;
export const createSkillKey = (id: string | number): string => `${SKILL_KEY}${id}`;

export const getMacroCatIdFromKey = (value: string): number => parseInt(value.replace(MACRO_CAT_KEY, ""));
export const getCatIdFromKey = (value: string): number => parseInt(value.replace(CAT_KEY, ""));
export const getSkillIdFromKey = (value: string): number => parseInt(value.replace(SKILL_KEY, ""));

export const setLocalStorage = (key: string, value: any) => window.localStorage.setItem(key, value);
export const getLocalStorage = (key: string) => window.localStorage.getItem(key);

export const getMenuType = (): MenuType => {
    const menuTypeFromStorage = getLocalStorage(LOCALSTORAGE_MENU);
    const menuType = !!menuTypeFromStorage ? parseInt(menuTypeFromStorage) : 0;
    return !!menuType && !isNaN(menuType) ? menuType : MenuType.normal;
};

export const formatDate = (date: string): string => {
    if (!date) return "";
    try {
        let dateToShow = format(new Date(date), "dd/MM/yyyy");
        return dateToShow;
    } catch (err) {
        return "";
    }
};

/**
 *  key composed by macroid-catid-skillid
 */
export const createKey = (mId: number, catId: number, skId: number): string => {
    return `${mId}-${catId}-${skId}`;
};

export const normalizeSurveyToFillData = (values: any, submit?: boolean) => {
    let answersYes: any = [];
    let answersNo: any = [];
    let normalizedData: any = [];
    let returnEmpty: boolean = false;
    Object.keys(values).forEach((key) => {
        if (values[key].isMacroCategoryResponse) {
            answersYes = [...answersYes, ...Object.values(values[key]).map((data: any) => ({ ...data, isMacroCategoryResponse: true }))];
        } else if (typeof values[key].isMacroCategoryResponse === "boolean") {
            answersNo = [...answersNo, ...Object.values(values[key]).map((data: any) => ({ ...data, isMacroCategoryResponse: false }))];
        } else {
            returnEmpty = true;
        }
    });

    if (answersYes.length > 0) {
        let normalizedYesData = answersYes.filter((data: SkillWithVote) => !!data && !!data.macroCategoryId && data.vote >= 0);
        if (submit) {
            let allField = answersYes.filter((data: SkillWithVote) => !!data && data.vote < 0);
            if (allField.length > 0) {
                returnEmpty = true;
            }
        }
        normalizedData = [...normalizedData, ...normalizedYesData];
    }

    if (answersNo.length > 0) {
        let filteredNoData = answersNo.filter((data: any) => !!data && !!data.macroCategoryId);
        normalizedData = [...normalizedData, ...filteredNoData.map((data: SkillWithVote) => ({ ...data, vote: 0 }))];
    }
    return !submit ? normalizedData : returnEmpty ? [] : normalizedData;
};

export const csvFileName = (snapshot?: string): string => {
    let exportDate = new Date().toISOString().substring(0, 10);
    if (!!snapshot) {
        return `${exportDate.replaceAll("-", ".")}_${snapshot.replaceAll("-", ".")}_SkillMatrix.csv`;
    } else {
        return `${exportDate.replaceAll("-", ".")}_${exportDate.replaceAll("-", ".")}_SkillMatrix.csv`;
    }
};

export const normalizeStringForRest = (input: string): string => {
    return input.replaceAll("%", "%25").replaceAll("&", "%26");
};

const TRUNCATE_CHAR = 100;
export const truncateString = (stringToFix: string): string => {
    if (!stringToFix) return "";
    return stringToFix.length > TRUNCATE_CHAR ? stringToFix.slice(0, TRUNCATE_CHAR) + "..." : stringToFix;
};
