import { NotificationInline, Toast } from "@vapor/react-material";
import { closeToast, selectToast } from "../../store/slice/toastSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import { useEffect } from "react";

const ToastInfo = () => {
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectToast);
    const { severity, msg } = data;
    useEffect(() => {
        const displayTimer = setTimeout(() => {
            if (!!msg) {
                dispatch(closeToast());
            }
        }, 10000);
        return () => clearTimeout(displayTimer);
    }, [msg]);
    return (
        <Toast
            open={!!msg}
            anchorOrigin={{
                horizontal: "right",
                vertical: "top",
            }}
            sx={{ top: "64px !important", right: "16px !important" }}
            autoHideDuration={6000}
            onClose={() => dispatch(closeToast())}
        >
            <NotificationInline
                // action={<IconButton onClick={function Ha() {}} size="small"></IconButton>}
                onClose={() => dispatch(closeToast())}
                severity={severity ? severity : "success"}
                sx={{
                    width: "100%",
                }}
                variant="outlined"
            >
                {msg}
            </NotificationInline>
        </Toast>
    );
};

export default ToastInfo;
