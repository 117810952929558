import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import it_res from "./languages/it.json";
import en_res from "./languages/en.json";

const resources = {
    it: {
        translation: it_res,
    },
    en: {
        translation: en_res,
    },
};

const options = {
    order: ["localStorage", "navigator"],
    lookupLocalStorage: "i18nextLng",
};

const defaultLanguage = "it";

export const getLanguage = (): string => window.localStorage.getItem(options.lookupLocalStorage)!;

export const changeLanguage = (language: string) => i18n.changeLanguage(language);

export enum ELanguages {
    it = "it",
    en = "en",
}

export const languages = {
    [ELanguages.it]: "Italian",
    [ELanguages.en]: "English",
};

export type TLanguages = keyof typeof languages;

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: defaultLanguage,
        debug: true,
        detection: options,
        interpolation: {
            escapeValue: false,
        },
    });

if (!resources[getLanguage() as TLanguages]) {
    i18n.changeLanguage(defaultLanguage);
}
export const t = i18n.t;
export default i18n;
