import { Divider, List, ListItemButton, ListItemText, Popover, Skeleton, Tooltip, styled } from "@vapor/react-material";
import { ELanguages, t } from "../../localization/i18n";
import { GENERIC_PATHS, PATHS, PATHS_BY_ROLE } from "../../routes/routesEnum";
import { Role, genericLabelTab, languagesLabel, tabLabelPage } from "../../model";
import { Tab, Tabs } from "@vapor/react-extended";
import { selectUser, selectUserSettings, setLanguages, setSelectedPage } from "../../store/slice/userSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AngleDown from "@vapor/react-icons/AngleDown";
import AngleUp from "@vapor/react-icons/AngleUp";
import { BtnFlexIcon } from "../../style/buttons";
import CustomAvatar from "../avatar/CustomAvatar";
import FileCircleInfo from "@vapor/react-icons/FileCircleInfo";
import { FlexContainer } from "../../style/div";
import { PagesTab } from "../../model/enum";
import Typography from "@vapor/react-extended/ExtendedTypography";
import VaporAppBar from "@vapor/react-custom/VaporAppBar";
import { get } from "lodash";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

const CustomTab = styled(Tab)`
    border-bottom: none !important;
    color: white !important;
    .Mui-selected {
        background: #0090d1 !important;
    }
`;

const CustomTabs = styled(Tabs)`
    margin-left: 8px !important;
    .Mui-selected {
        background: #0090d1 !important;
    }
`;

const CustomDivider = styled(Divider)`
    margin: 0 8px;
`;

const LeftContent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    const { pathname } = useLocation();
    const settings = useAppSelector(selectUserSettings);
    const { selectedPage } = settings;
    const { t } = useTranslation();
    const role = get(user, "data.userType");
    const routesByRole = get(Role, `[${role}]`, genericLabelTab);
    const tabsByRole = get(PATHS_BY_ROLE, `[${role}][${settings.menu}]`, GENERIC_PATHS);

    useEffect(() => {
        const paths = pathname.split("/");
        let name = get(paths, `[1]`, "");
        if (`/${name}` !== get(tabLabelPage, `${[selectedPage]}`)) {
            let path: PagesTab = get(tabLabelPage, `[/${name}]`, PagesTab.mySurveys);
            dispatch(setSelectedPage(path));
        }
    }, [pathname]);

    return (
        <>
            <Typography
                variant="bodyLarge500"
                sx={{
                    color: "white",
                    ml: 2,
                }}
            >
                {t("appTitle")}
            </Typography>

            <CustomTabs
                onChange={(event, data: PagesTab) => {
                    dispatch(setSelectedPage(data));
                    navigate(get(routesByRole, `${[data]}`));
                }}
                size="small"
                value={selectedPage}
                variant="standard"
            >
                {tabsByRole.map((tabs: { path: PATHS; label: string; valueTab: PagesTab }, index) => (
                    <CustomTab
                        key={index}
                        label={t(`${tabs.label}`)}
                        value={tabs.valueTab}
                        onClick={() => {
                            navigate(tabs.path);
                        }}
                    />
                ))}
            </CustomTabs>
        </>
    );
};

const GuideBtn = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Tooltip arrow title={t("userGuide")}>
            <BtnFlexIcon
                onClick={() => {
                    navigate(PATHS.guide);
                }}
            >
                <FileCircleInfo color="white" />
            </BtnFlexIcon>
        </Tooltip>
    );
};

const RightContent = ({ loading }: { loading?: boolean }) => {
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const { accounts } = useMsal();
    const username = get(accounts, "[0].name", "");

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const language: ELanguages = i18n.language as ELanguages;
    return (
        <FlexContainer $withMargin>
            {loading ? <></> : <GuideBtn />}
            <CustomAvatar />
            <Typography color="white" component="div" variant="button">
                {username}
            </Typography>
            <CustomDivider flexItem orientation="vertical" variant="middle" />
            <BtnFlexIcon type="button" onClick={handleClick}>
                <Typography color="white" component="div" variant="button">
                    {languagesLabel[language]}
                </Typography>
                {open ? <AngleUp color="white" /> : <AngleDown color="white" />}
            </BtnFlexIcon>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <List
                    disablePadding
                    sx={{
                        width: 80,
                    }}
                >
                    <ListItemButton
                        onClick={() => {
                            dispatch(setLanguages(language === ELanguages.en ? ELanguages.it : ELanguages.en));
                            handleClose();
                        }}
                    >
                        <ListItemText primary={language === ELanguages.en ? languagesLabel[ELanguages.it] : languagesLabel[ELanguages.en]} />
                    </ListItemButton>
                </List>
            </Popover>
        </FlexContainer>
    );
};
interface IHeaderProps {
    loading?: boolean;
}
const Header = ({ loading }: IHeaderProps) => (
    <VaporAppBar
        disableDrawer={true}
        leftContent={
            loading ? (
                <>
                    <Typography
                        variant="bodyLarge500"
                        sx={{
                            color: "white",
                            ml: 2,
                        }}
                    >
                        {t("appTitle")}
                    </Typography>
                    <Skeleton variant="rectangular" />
                    <Skeleton variant="rectangular" />
                    <Skeleton variant="rectangular" />
                </>
            ) : (
                <LeftContent />
            )
        }
        rightContent={<RightContent loading={loading} />}
    />
);

export default Header;
