import { Dispatch, SetStateAction, memo, useEffect, useMemo, useState } from "react";
import { FlexClm, InputDivider } from "../../style/div";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@vapor/react-material";
import { ICategoryBe, ILegendBe, IMacroCatBe, SkillWithVoteFormik } from "../../model";
import { SurveyStatus, YesNo } from "../../model/enum";

import CustomAccordion from "../accordion/CustomAccordion";
import { ELanguages } from "../../localization/i18n";
import { ExtendedTypography } from "@vapor/react-extended";
import { LegendsTable } from "../table/LegendsTable";
import { createMacroCatKey } from "../../utils/utils";
import { get } from "lodash";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

interface IViewMacro {
    macro: IMacroCatBe;
    readonly: boolean;
    surveyStatus: SurveyStatus;
    firstOpen: boolean;
}

const ViewMacro = ({ macro, readonly, surveyStatus, firstOpen }: IViewMacro) => {
    const { i18n, t } = useTranslation();
    const { setFieldValue, values } = useFormikContext<SkillWithVoteFormik>();
    let answer = get(values, `${macro.id}.isMacroCategoryResponse`, false);
    let macroData = get(values, `${macro.id}`);
    const [categoryRedCircle, setCategoryRedCircle] = useState<boolean>(false);
    const [categoryOpened, setCategoryOpened] = useState<number>(-1);
    const [isOpen, setIsOpen] = useState<number>(-1);

    useEffect(() => {
        if (firstOpen) setIsOpen(macro.id);
    }, []);

    useEffect(() => {
        let noResponseObj = { ...macroData };
        let votes = Object.keys(noResponseObj).map((data: string) => {
            const vote = get(noResponseObj, `${data}.vote`);
            if (typeof vote === "number") {
                return vote;
            }
            return 0;
        });
        let filteredVotes = votes.filter((v) => v === -1);
        setCategoryRedCircle(filteredVotes.length > 0);
    }, [macroData, values]);

    let showCircle = typeof answer === "boolean" && !answer ? answer : categoryRedCircle;

    return (
        <CustomAccordion
            defaulOpen={isOpen === macro.id}
            onClick={(data: boolean) => {
                setIsOpen(data ? macro.id : -1);
            }}
            title={i18n.language === ELanguages.en ? macro.nameEn : macro.nameIt}
            withRedCircle={showCircle}
        >
            <FlexClm>
                <ExtendedTypography variant="displaySmall">{i18n.language === ELanguages.en ? macro.questionEn : macro.questionIt}</ExtendedTypography>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name={createMacroCatKey(macro.id)}
                        onChange={(e, value) => {
                            let answerValue = parseInt(value);
                            setFieldValue(`${macro.id}`, { ...macroData, isMacroCategoryResponse: answerValue === YesNo.yes });
                        }}
                    >
                        <FormControlLabel checked={!!answer} control={<Radio />} label={t("radioYes")} value={YesNo.yes} disabled={readonly} />
                        <FormControlLabel checked={typeof answer === "boolean" && !answer} control={<Radio />} label={t("radioNo")} value={YesNo.no} disabled={readonly} />
                    </RadioGroup>
                </FormControl>
            </FlexClm>
            {!!answer &&
                macro.categories.map((c, index) => (
                    <ViewCategory
                        {...c}
                        setCategoryRedCircle={setCategoryRedCircle}
                        key={c.id}
                        readonly={readonly}
                        macroId={macro.id}
                        firstOpen={index === 0}
                        surveyStatus={surveyStatus}
                        categoryOpened={categoryOpened}
                        setCategoryOpened={setCategoryOpened}
                    />
                ))}
        </CustomAccordion>
    );
};

interface IViewCategory extends ICategoryBe {
    readonly: boolean;
    macroId: number;
    surveyStatus: SurveyStatus;
    categoryOpened: number;
    firstOpen: boolean;
    setCategoryOpened: Dispatch<SetStateAction<number>>;
    setCategoryRedCircle: Dispatch<SetStateAction<boolean>>;
}
const ViewCategory = memo((category: IViewCategory) => {
    const { i18n } = useTranslation();
    const { values } = useFormikContext<SkillWithVoteFormik>();
    const { legends, readonly, macroId, surveyStatus, id, setCategoryRedCircle, firstOpen, setCategoryOpened, categoryOpened } = category;
    let macroData = get(values, `${macroId}`);

    let withRedCircle = useMemo(() => {
        let data = !!macroData ? Object.values(macroData) : [];
        let filteredValues = !!data && Array.isArray(data) ? data.filter((d) => !!d && typeof d !== "boolean" && !!d.catId && d.catId === category.id) : [];
        let redCircle = surveyStatus !== SurveyStatus.Expired && filteredValues.filter((data) => data.vote < 0).length > 0;
        if (redCircle) setCategoryRedCircle(redCircle);
        return redCircle;
    }, [macroId, values]);

    useEffect(() => {
        if (firstOpen) setCategoryOpened(id);
    }, []);

    return (
        <CustomAccordion
            title={i18n.language === ELanguages.en ? category.nameEn : category.nameIt}
            defaulOpen={categoryOpened === id}
            onClick={(data: boolean) => {
                setCategoryOpened(data ? id : -1);
            }}
            withRedCircle={withRedCircle}
        >
            <FlexClm>
                <ExtendedTypography variant="displaySmall">{i18n.language === ELanguages.en ? category.questionEn : category.questionIt}</ExtendedTypography>
                <InputDivider>
                    <FlexClm>
                        {legends.map((legends: ILegendBe) => (
                            <ExtendedTypography variant="body2" key={legends.id}>
                                {legends.id} - {i18n.language === ELanguages.en ? legends.nameEn : legends.nameIt}
                            </ExtendedTypography>
                        ))}
                    </FlexClm>
                </InputDivider>
                <InputDivider>
                    <LegendsTable skills={category.skills} legends={legends} readonly={readonly} macroId={macroId} surveyStatus={surveyStatus} />
                </InputDivider>
            </FlexClm>
        </CustomAccordion>
    );
});

export default ViewMacro;
