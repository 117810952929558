import { IconButton, Tooltip } from "@vapor/react-material";

import Eye from "@vapor/react-icons/Eye";
import { useTranslation } from "react-i18next";

export interface IEyeBtnProps {
    onClick?: any;
}
const EyeBtn = (props: IEyeBtnProps) => {
    const { t } = useTranslation();
    const { onClick } = props;
    return (
        <Tooltip arrow title={t("buttons.view")}>
            <IconButton
                onClick={() => {
                    if (!!onClick) onClick();
                }}
            >
                <Eye color="primary" />
            </IconButton>
        </Tooltip>
    );
};
export default EyeBtn;
