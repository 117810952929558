import { FieldArray, useFormikContext } from "formik";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@vapor/react-material";
import { useEffect, useState } from "react";

import { FlexClm } from "../../style/div";
import FormikInput from "../formikInput/FormikInput";
import { INewCategory } from "../../model";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { get } from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ArrayContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
const RowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
//TODO Ask be to create a db table with an api for templates
const templates = [
    {
        id: 1,
        name: "Template 1",
        template: [
            { id: 0, nameIt: "Zero", nameEn: "Zero" },
            { id: 1, nameIt: "Ho idea di cosa si parli", nameEn: "I heard about it" },
            { id: 2, nameIt: "Ho una competenza ma non ho mai approfondito i dettagli", nameEn: "I know it but never delved into the details" },
            { id: 3, nameIt: "Lo conosco e ne ho a che fare spesso", nameEn: "I know it and face it quite often" },
            { id: 4, nameIt: "Ho una conoscenza avanzata, potrei insegnarlo a qualcun altro", nameEn: "I have advanced knowledge, I could teach about it to someone else" },
            { id: 5, nameIt: "Lo conosco in tutte le sue sfumature e potrei tenere una conferenza su di esso", nameEn: "I know it in all its nuances and could give a lecture on it" },
        ],
    },
    {
        id: 2,
        name: "Template 2",
        template: [
            { id: 0, nameIt: "Zero", nameEn: "Zero" },
            { id: 1, nameIt: 'So "leggerlo" e orientarmi in un file/progetto', nameEn: "I can read it and navigate in a file/project" },
            { id: 2, nameIt: "Posso mettere mano a un file/progetto", nameEn: "I can slightly modify a file/project" },
            { id: 3, nameIt: "So utilizzarlo in progetti complessi", nameEn: "I can use it in advanced projects" },
            { id: 4, nameIt: "Ho una conoscenza avanzata, potrei insegnarlo a qualcun altro", nameEn: "I have advanced knowledge, I could teach about it to someone else" },
            { id: 5, nameIt: "Lo conosco in tutte le sue sfumature e potrei tenere una conferenza su di esso", nameEn: "I know it in all its nuances and could give a lecture on it" },
        ],
    },
    {
        id: 3,
        name: "Template 3",
        template: [
            { id: 0, nameIt: "Zero", nameEn: "Zero" },
            { id: 1, nameIt: "So di cosa si tratta, ma non ho competenze dirette", nameEn: "I know what it's about, but I don't have direct expertise" },
            {
                id: 2,
                nameIt: "Ho una competenza di base che uso saltuariamente, non ho mai approfondito i dettagli",
                nameEn: "I have basic knowledge that I use occasionally, but I have never delved into the details",
            },
            { id: 3, nameIt: "Me la cavo", nameEn: "Can manage" },
            { id: 4, nameIt: "Me la cavo, anche in situazioni complesse", nameEn: "I can manage complex situations" },
            {
                id: 5,
                nameIt: "Utilizzo regolarmente e padroneggio l'argomento, posso aiutare colleghi in difficoltà",
                nameEn: "I use regularly and master the subject, I can help my colleagues if needed",
            },
        ],
    },
    {
        id: 4,
        name: "Template 4",
        template: [
            { id: 0, nameIt: "Nessuna competenza - Non comprendo o parlo la lingua", nameEn: "No knowledge - Does not understand or speak the language" },
            { id: 1, nameIt: "Principiante - Capisco alcune parole e frasi di base", nameEn: "Beginner - Understands some basic words and phrases" },
            { id: 2, nameIt: "Intermedio - In grado di comunicare in situazioni quotidiane con limitazioni", nameEn: "Intermediate - Can communicate in everyday situations with limitations" },
            { id: 3, nameIt: "Avanzato - Competenza solida, posso gestire conversazioni complesse", nameEn: "Advanced - Solid proficiency, able to handle complex conversations" },
            {
                id: 4,
                nameIt: "Fluido - Fluentemente in grado di comunicare e comprendere, anche in contesti formali",
                nameEn: "Fluent - Fluent in communication and comprehension, even in formal contexts",
            },
            { id: 5, nameIt: "Madrelingua - Parlante nativo, competenza completa nella lingua", nameEn: "Native Speaker - Native speaker, complete proficiency in the language" },
        ],
    },
];

const Legends = () => {
    const { t } = useTranslation();
    const { values, setFieldValue, errors } = useFormikContext<INewCategory>();

    const [template, setTemplate] = useState(0);

    useEffect(() => {
        if (template > 0) {
            let templateToUse = templates.filter((t) => t.id === template);
            setFieldValue("legends", templateToUse[0].template);
        }
    }, [template]);
    const legendError = get(errors, "legends", []);
    return (
        <FlexClm>
            <FormControl
                sx={{
                    width: 240,
                }}
            >
                <InputLabel id="simple-select-label">{t("selectTemplate")} *</InputLabel>
                <Select id="simple-select-label" label="Record" value={template} onChange={(e) => setTemplate(e.target.value as number)}>
                    {templates.map((t) => (
                        <MenuItem value={t.id} id={`${t.id}`} key={t.id}>
                            {t.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FieldArray
                name="legends"
                render={() => (
                    <ArrayContainer>
                        {values.legends.map((legend, index) => (
                            <RowContainer key={legend.id}>
                                <TextField value={legend.id} name={`legends.${index}.id`} readOnly sx={{ width: "5%", minWidth: "56px", "& input": { textAlign: "center" } }} />
                                <FormikInput hideError placeholder={t("insertEn")} name={`legends.${index}.nameEn`} sx={{ width: "47.5%" }} />
                                <FormikInput hideError placeholder={t("insertIt")} name={`legends.${index}.nameIt`} sx={{ width: "47.5%" }} />
                            </RowContainer>
                        ))}
                    </ArrayContainer>
                )}
            />
            {legendError.length > 0 && (
                <div style={{ marginTop: "8px", fontSize: "14.3px", display: "flex", fontWeight: 400 }}>
                    <RemoveCircleIcon fontSize="inherit" style={{ color: "red", marginRight: "4px" }} />
                    <Typography variant="caption" color="primary.error" component="div">
                        {t("required")}
                    </Typography>
                </div>
            )}
        </FlexClm>
    );
};

export default Legends;
