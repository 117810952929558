import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";

import { FunctionComponent } from "react";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import { PATHS } from "../../routes/routesEnum";
import { useTranslation } from "react-i18next";

interface INoMatchProps {}

const NoMatch: FunctionComponent<INoMatchProps> = () => {
    const { t } = useTranslation();
    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <VaporPage title={t("nomatch")} subtitle="" headerLeft={<Link to={PATHS.home}>{t("backToHome")}</Link>} contentToolbar={<VaporToolbar size="large" contentRight={[]} />}>
                <VaporPage.Section>
                    <h2>{t("nomatchdescr")}</h2>
                    <p>
                        <Link to={PATHS.home}>{t("backToHome")}</Link>
                    </p>
                </VaporPage.Section>
            </VaporPage>
        </VaporUIShellNav>
    );
};

export default NoMatch;
