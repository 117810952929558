import { Box, IconButton, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@vapor/react-material";
import { ILegendBe, ISkillBe, ISurveyToFillFormik } from "../../model";
import { memo, useMemo } from "react";

import { ELanguages } from "../../localization/i18n";
import { ExtendedTypography } from "@vapor/react-extended";
import InfoSquare from "@vapor/react-icons/InfoSquare";
import { StyledTableRow } from "./Table";
import { SurveyStatus } from "../../model/enum";
import Typography from "@vapor/react-extended/Typography";
import { get } from "lodash";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

export interface ILegendsTableProps {
    legends: ILegendBe[];
    skills: ISkillBe[];
    readonly: boolean;
    macroId: number;
    surveyStatus: SurveyStatus;
}

export const LegendsTable = memo(function LegendsTable(props: ILegendsTableProps) {
    const { readonly, skills, macroId, surveyStatus, legends } = props;
    const { i18n, t } = useTranslation();

    const { setFieldValue, values } = useFormikContext<ISurveyToFillFormik>();
    let data = [0, 1, 2, 3, 4, 5];
    const headCells = useMemo(() => {
        let newLegends = legends.map((sk) => {
            return {
                id: `${sk.id}`,
                numeric: false,
                disablePadding: true,
                label: sk.id,
            };
        });
        return newLegends;
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell key={"name"}>
                            <Typography variant="bodyLarge700" component="div" color=""></Typography>
                        </TableCell>
                        {surveyStatus !== SurveyStatus.New && surveyStatus !== SurveyStatus.Draft && (
                            <TableCell key={"lastValue"}>
                                <Typography variant="bodyLarge700" component="div" color="">
                                    {t("clmLastValue")}
                                </Typography>
                            </TableCell>
                        )}
                        {headCells.map((headCell) => (
                            <TableCell key={headCell.id} sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="bodyLarge700" component="div" color="">
                                    {headCell.label}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {skills.map((row: ISkillBe, index: number) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let dataFromFormik: any = get(values, `${macroId}.${row.id}`);
                        let isChecked = get(dataFromFormik, "vote");
                        let withDescription = !!row.descriptionEn || !!row.descriptionIt;

                        return (
                            <StyledTableRow hover onClick={() => {}} tabIndex={-1} key={row.id}>
                                <TableCell component="th" id={labelId} scope="row" key={`enhanced-table-cell-${index}`}>
                                    <Tooltip arrow title={i18n.language === ELanguages.en ? row.descriptionEn : row.descriptionIt}>
                                        <ExtendedTypography variant="body2">
                                            {i18n.language === ELanguages.en ? row.nameEn : row.nameIt}
                                            {withDescription && (
                                                <>
                                                    {" "}
                                                    <InfoSquare color="primary" size="12px" />
                                                </>
                                            )}
                                        </ExtendedTypography>
                                    </Tooltip>
                                </TableCell>
                                {surveyStatus !== SurveyStatus.New && surveyStatus !== SurveyStatus.Draft && (
                                    <TableCell key={"lastValue"}>
                                        <Typography variant="bodyLarge700" component="div" color="">
                                            {(get(dataFromFormik, "lastVote", 0) as number) >= 0 ? get(dataFromFormik, "lastVote", 0) : ""}
                                        </Typography>
                                    </TableCell>
                                )}
                                {data.map((key) => (
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        key={`enhanced-table-cell-${key}-${row.id}`}
                                        sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <Radio
                                            checked={isChecked === key}
                                            onClick={() => {
                                                setFieldValue(`${macroId}.${row.id}`, { ...dataFromFormik, vote: key });
                                            }}
                                            disabled={readonly}
                                        />
                                    </TableCell>
                                ))}
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

export default LegendsTable;
