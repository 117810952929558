import { DEFAULT_RECORD, checkTimeElapsed, sleep } from "../../utils/utils";
import { EReduxState, IReduxState } from "../model";
import { IGetParams, INewCategory, IPaginationRedux } from "../../model";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllCategories, getAllDetailedCategories } from "../../api/helper";

import { RootState } from "../store";
import { deSerializeCategories } from "../../model/deserialize";
import { get } from "lodash";

export enum categoryAction {
    get = "category/getAll",
}

interface ICategory {
    categories: INewCategory[];
    page: number;
    lastFetch: number;
    record: number;
    status: EReduxState;
    totalPages: number;
}

const initialState: IReduxState<ICategory> = {
    error: undefined,
    data: { categories: [], page: 1, totalPages: 1, lastFetch: 0, record: DEFAULT_RECORD, status: EReduxState.initialState },
};

export const categorySlice = createSlice({
    name: "categorySlice",
    initialState,
    reducers: {
        setRecordSurvey: (state, action: PayloadAction<number>) => {
            state.data.record = action.payload;
        },
        deleteCategory: (state, action: PayloadAction<number>) => {
            state.data.categories = [...state.data.categories.filter((c) => c.id !== action.payload)];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(shouldFetchAllCategory.pending, (state) => {
                state.data.status = EReduxState.pending;
            })
            .addCase(shouldFetchAllCategory.fulfilled, (state, action) => {
                state.data.status = EReduxState.fulfilled;
                state.data.lastFetch = new Date().getTime() / 1000;
                state.data.categories = get(action, "payload.categories", []);
                state.data.page = get(action, "payload.page", 1);
                state.data.totalPages = get(action, "payload.totalPages", 1);
            })
            .addCase(shouldFetchAllCategory.rejected, (state, action) => {
                state.data.status = EReduxState.rejected;
            });
    },
});
export const shouldFetchAllCategory = createAsyncThunk(categoryAction.get, async (pagination: IGetParams, { rejectWithValue, getState }) => {
    try {
        const state = getState() as RootState;
        let { lastFetch, page } = state.categorySlice.data;
        if (!lastFetch || (!!lastFetch && checkTimeElapsed(lastFetch)) || page !== pagination.pageNumber || pagination.forceCall) {
            let data = await getAllDetailedCategories(pagination);
            return { categories: deSerializeCategories(data.data.items), page: pagination.pageNumber, totalPages: data.data.totalPages };
        } else {
            return state.categorySlice.data;
        }
    } catch (error: any) {
        return rejectWithValue(error.response);
    }
});

export const selectCategories = (state: RootState) => state.categorySlice;
export const selectCategoryById = (state: RootState, id?: number) => {
    const cats = state.categorySlice.data.categories;
    return cats.find((c) => c.id === id);
};
export const { setRecordSurvey, deleteCategory } = categorySlice.actions;

export default categorySlice.reducer;
