import { Box, Button, Popover, Tooltip, Typography } from "@vapor/react-material";

import Filter from "@vapor/react-icons/Filter";
import { FlexStart } from "../../style/div";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import OctagonXmark from "@vapor/react-icons/OctagonXmark";
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons";
import { relative } from "path";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface IFilterBtnProp {
    onClick?: any;
    children?: any;
    showDelete?: boolean;
    cleanFilter?: any;
}
const FilterBtn = (props: IFilterBtnProp) => {
    const { t } = useTranslation();
    const { onClick, children, showDelete, cleanFilter } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <FlexStart>
            <Tooltip arrow title={showDelete ? t("buttons.filterRemove") : t("buttons.filter")}>
                <Button
                    onClick={(e) => {
                        if (!!showDelete && !!cleanFilter) {
                            cleanFilter();
                        } else {
                            handleClick(e);
                            if (!!onClick) onClick();
                        }
                    }}
                    sx={{ display: "flex", gap: "8px" }}
                >
                    {!showDelete && <Filter color="primary" size="20px" />}
                    {showDelete && <FontAwesomeIcon size="lg" icon={faFilterSlash as IconProp} />}
                </Button>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box
                    sx={{
                        width: 400,
                        px: 2,
                        py: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                    }}
                >
                    {children}
                </Box>
            </Popover>
        </FlexStart>
    );
};
export default FilterBtn;
