import { t } from "../localization/i18n";

export enum PagesTab {
    survey,
    macro,
    cat,
    skills,
    user,
    roles,
    myTeam,
    myTeamSkills,
    mySkills,
    mySurveys,
}
export enum YesNo {
    yes,
    no,
}

export enum SurveyStatus {
    Completed = "Completed",
    Pending = "Pending",
    Draft = "Draft",
    New = "New",
    Expired = "Expired",
    Deleted = "Deleted",
}
export enum EOrder {
    asc = "asc",
    desc = "desc",
}
export type Order = EOrder.asc | EOrder.desc;
