import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, TextField } from "@vapor/react-material";
import { ICustomSelectOptions, IModal, IRoleBe } from "../../model";
import { createRole, getAssociableSurveys } from "../../api/helper";
import { useCallback, useEffect, useState } from "react";

import Close from "@vapor/react-icons/Xmark";
import CustomSelect from "../../components/customSelect/CustomSelect";
import { Formik } from "formik";
import FormikInput from "../../components/formikInput/FormikInput";
import { InputDivider } from "../../style/div";
import { Typography } from "@vapor/react-extended";
import { defaultRole } from "../../pages/roles/NewRole";
import { newRoleSchema } from "../../validations";
import { openToast } from "../../store/slice/toastSlice";
import { useAppDispatch } from "../../store/hook";
import { useTranslation } from "react-i18next";

interface IModalNewRole extends IModal {
    functionAfterCreate?: (role: IRoleBe) => void;
}

const ModalNewRole = (props: IModalNewRole) => {
    const { open, setOpen, functionAfterCreate } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const [surveys, setSurveys] = useState<ICustomSelectOptions[]>([]);
    const fetchSurveyForRoles = useCallback(async () => {
        try {
            const data = await getAssociableSurveys(undefined);
            setSurveys(data.data.map((s) => ({ nameEn: s.titleEn, nameIt: s.titleIt, id: s.id })));
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchSurveyForRoles();
    }, []);
    const { t } = useTranslation();

    const handleDialog = () => {
        setOpen(!open);
    };

    return (
        <Dialog open={open} maxWidth="xl" fullWidth scroll="paper" onClose={handleDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <Formik
                initialValues={defaultRole}
                validationSchema={newRoleSchema}
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    try {
                        let result = await createRole(values);
                        dispatch(openToast({ msg: t("newSurveyForm.success") }));
                        setOpen(!open);
                        if (!!functionAfterCreate) functionAfterCreate(result.data);
                    } catch (err) {
                        dispatch(openToast({ msg: t("newSurveyForm.error"), severity: "error" }));
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogTitle>
                            {t("newRole")}
                            <IconButton color="primary" onClick={handleDialog}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <Divider variant="fullWidth" />
                        <DialogContent>
                            <InputDivider>
                                <FormikInput name="roleNameEn" label={t("nameEn")} required />
                                <FormikInput name="roleNameIt" label={t("nameIt")} required />
                            </InputDivider>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="error" type="button" onClick={handleDialog}>
                                {t("buttons.cancel")}
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    mr: 1,
                                }}
                            >
                                {t("buttons.save")}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default ModalNewRole;
