import { useCallback, useEffect, useState } from "react";

import { IEditRole } from "../../model";
import LoadingPage from "../../components/loadingPage/LoadingPage";
import NewRole from "./NewRole";
import { PATHS } from "../../routes/routesEnum";
import { deSerializeRole } from "../../model/deserialize";
import { getRoleById } from "../../api/helper";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditRole = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [roleToEdit, setRoleToEdit] = useState<IEditRole>();

    const fetchMacroById = useCallback(async () => {
        setLoading(true);
        try {
            if (!!id) {
                let response = await getRoleById(parseInt(id));
                setRoleToEdit(deSerializeRole(response.data));
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchMacroById();
    }, []);

    return <>{loading ? <LoadingPage title={t("editRole")} path={PATHS.roles} /> : <NewRole roleToEdit={roleToEdit} />}</>;
};

export default EditRole;
