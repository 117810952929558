import { array, number, object, string } from "yup";

import { t } from "../localization/i18n";

export const newSkillSchema = object({
    nameEn: string().required(t("required")),
    nameIt: string().required(t("required")),
    // descriptionEn: string().required(t("required")),
    // descriptionIt: string().required(t("required")),
});
export const newRoleSchema = object({
    roleNameEn: string().required(t("required")),
    roleNameIt: string().required(t("required")),
    // descriptionEn: string().required(t("required")),
    // descriptionIt: string().required(t("required")),
});
export const newCategorySchema = object({
    nameEn: string().required(t("required")),
    nameIt: string().required(t("required")),
    questionEn: string().required(t("required")),
    questionIt: string().required(t("required")),
    legends: array(
        object({
            nameIt: string().required(t("required")),
            nameEn: string().required(t("required")),
            id: number(),
        })
    ).required(),
});
export const newMacroCategorySchema = object({
    nameEn: string().required(t("required")),
    nameIt: string().required(t("required")),
    questionEn: string().required(t("required")),
    questionIt: string().required(t("required")),
});
export const newSurvey = object({
    nameEn: string().required(t("required")),
    nameIt: string().required(t("required")),
    expired: number().required(t("required")),
});
