import { IconButton, Skeleton } from "@vapor/react-material";
import { InputFlexBtm, InputFlexTop } from "../../style/div";
import { VaporPage, VaporToolbar, VaporUIShellNav } from "@vapor/react-custom";

import ArrowLeft from "@vapor/react-icons/ArrowLeft";
import Header from "../../components/header/Header";
import { PATHS } from "../../routes/routesEnum";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ILoadingPage {
    title: string;
    path: PATHS;
}

const LoadingPage = (props: ILoadingPage) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <VaporUIShellNav hideDrawer={true} setDrawerOpen={() => {}} renderToolbar={<Header />} renderDrawer={<></>}>
            <VaporPage
                title={props.title}
                subtitle=""
                headerLeft={
                    <IconButton onClick={() => navigate(props.path)}>
                        <ArrowLeft color="primary" />
                    </IconButton>
                }
                contentToolbar={
                    <VaporToolbar
                        size="large"
                        contentRight={[<Skeleton variant="rectangular" width={80} height={40} key={"secondary"} />, <Skeleton variant="rectangular" width={80} height={40} key={"primary"} />]}
                    />
                }
            >
                <VaporPage.Section divider>
                    <Typography variant="titleMedium" color="primary.textTitleColor">
                        <Skeleton variant="rectangular" width={80} height={40} />
                    </Typography>
                    <InputFlexTop>
                        <Skeleton variant="rectangular" width={80} height={40} />
                        <Skeleton variant="rectangular" width={80} height={40} />
                    </InputFlexTop>
                    <InputFlexBtm>
                        <Skeleton variant="rectangular" width={80} height={40} />
                        <Skeleton variant="rectangular" width={80} height={40} />
                    </InputFlexBtm>
                </VaporPage.Section>
                <VaporPage.Section>
                    <Typography variant="titleMedium" color="primary.textTitleColor">
                        <Skeleton variant="rectangular" width={80} height={40} />
                    </Typography>
                    <InputFlexTop>
                        <Skeleton variant="rectangular" width={80} height={40} />
                        <Skeleton variant="rectangular" width={80} height={40} />
                    </InputFlexTop>
                    <InputFlexBtm>
                        <Skeleton variant="rectangular" width={80} height={40} />
                        <Skeleton variant="rectangular" width={80} height={40} />
                    </InputFlexBtm>
                </VaporPage.Section>
            </VaporPage>
        </VaporUIShellNav>
    );
};

export default LoadingPage;
