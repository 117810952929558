import { IEditUser, IRoleBe } from "../../model";
import { useCallback, useEffect, useState } from "react";

import LoadingPage from "../../components/loadingPage/LoadingPage";
import NewUser from "./NewUser";
import { PATHS } from "../../routes/routesEnum";
import { getUserById } from "../../api/helper";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditUser = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [userToEdit, setUserToEdit] = useState<IEditUser>();

    const fetchUserById = useCallback(async () => {
        setLoading(true);
        try {
            if (!!id) {
                let user = await getUserById(id);
                setUserToEdit({ user: user.data.id, role: user.data.role.id as any, businessUnit: "", skills: [], name: user.data.firstName, lastName: user.data.lastName });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchUserById();
    }, []);

    return <>{loading ? <LoadingPage title={t("editUser")} path={PATHS.users} /> : <NewUser userToEdit={userToEdit} />}</>;
};

export default EditUser;
