import { ACTION_ID, ACTION_VIEW_ID, ARROWS_ID, STATUS_ID, truncateString } from "../../utils/utils";
import { Box, Collapse, Drawer, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from "@vapor/react-material";
import { ContainerSmallGap, FlexEnd, FlexStart } from "../../style/div";
import { EOrder, Order, SurveyStatus } from "../../model/enum";
import { IGenericTableProps, StyledTableRow } from "./Table";
import { IGetSkillForAdminResponse, ISkillForAdminResponse, ISurveyBe } from "../../model";
import { getSkillsForAdmin, getSkillsForManager } from "../../api/helper";
import { useCallback, useState } from "react";

import AngleDown from "@vapor/react-icons/AngleDown";
import AngleUp from "@vapor/react-icons/AngleUp";
import BannerStatus from "../BannerStatus/BannerStatus";
import CheckCircle from "@vapor/react-icons/CheckCircle";
import Close from "@vapor/react-icons/Xmark";
import DeleteBtn from "../btns/DeleteBtn";
import { ELanguages } from "../../localization/i18n";
import EditBtn from "../btns/EditBtn";
import ExclamationTriangle from "@vapor/react-icons/ExclamationTriangle";
import EyeBtn from "../btns/EyeBtn";
import { PATHS } from "../../routes/routesEnum";
import Title from "@vapor/react-custom/Title";
import Typography from "@vapor/react-extended/Typography";
import { UserRoles } from "../../store/model";
import { get } from "lodash";
import { selectUser } from "../../store/slice/userSlice";
import { t } from "i18next";
import { useAppSelector } from "../../store/hook";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface ICollapsibleTableProps extends IGenericTableProps {
    navigateToSurvey?: boolean;
}

interface IRowProps extends IGenericTableProps {
    key: number | string;
    index: number;
    loading: boolean;
    row: any;
    navigateToSurvey?: boolean;
}
interface IModalSurveyProps {
    surveyId: number;
    userId: string;
    title?: string;
}
export const ModalDetailSurvey = (props: IModalSurveyProps) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const userRole: any = useAppSelector(selectUser);
    const [matrix, setMatrix] = useState<IGetSkillForAdminResponse[]>([]);
    const { surveyId, userId, title } = props;
    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            let data;
            if (userRole.data.userType === UserRoles.manager) data = await getSkillsForManager({ surveyId: surveyId, userId: userId });
            else {
                data = await getSkillsForAdmin({ surveyId: surveyId, userId: userId });
            }
            const skills = get(data, "data", []);
            setMatrix(skills);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [surveyId, userId]);

    return (
        <>
            <EyeBtn
                onClick={() => {
                    setOpen(true);
                    fetchData();
                }}
            />
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)} width={600} sx={{ zIndex: "1220 !important" }}>
                <Title
                    description=""
                    rightItems={
                        <IconButton sx={{ alignSelf: "flex-end" }} onClick={() => setOpen(false)} color="primary">
                            <Close />
                        </IconButton>
                    }
                    title={!!title ? truncateString(title) : t("userMatrix")}
                />
                <ContainerSmallGap>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("skillName")}</TableCell>
                                <TableCell>{t("lastVote")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </>
                            ) : (
                                <>
                                    {matrix.map((m: IGetSkillForAdminResponse) =>
                                        m.skills.map((m) => (
                                            <TableRow key={`${m.skillId}${m.vote}${m.surveyId}`}>
                                                <TableCell component="th" scope="row">
                                                    {i18n.language === ELanguages.en ? m.skillNameEn : m.skillNameIt}
                                                </TableCell>
                                                <TableCell>{m.vote}</TableCell>
                                            </TableRow>
                                        )),
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </ContainerSmallGap>
            </Drawer>
        </>
    );
};

const Row = (props: IRowProps) => {
    const { index, loading, deleteBtnClick, eyeBtn, editBtn, row, headCells, navigateToSurvey } = props;
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const labelId = `enhanced-table-checkbox-${index}`;
    const [open, setOpen] = useState(false);
    let cellToRender = headCells.map((item) => item.id);

    const handleClick = (event: any, name: string) => {};

    const surveys: ISurveyBe[] = get(row, "role.surveys", []);
    const statusNoCompleted = surveys.find((s) => s.status !== SurveyStatus.Completed && s.status !== SurveyStatus.Deleted);
    return (
        <>
            <StyledTableRow hover onClick={(event: any) => handleClick(event, row.name)} tabIndex={-1} key={row.name}>
                {cellToRender.map((key, index) => (
                    <TableCell component="th" id={labelId} padding={key === ARROWS_ID ? "checkbox" : "normal"} scope="row" key={`enhanced-table-cell-${index}`}>
                        {loading ? (
                            <Skeleton variant="rectangular" />
                        ) : (
                            <>
                                {key === ARROWS_ID ? (
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} disabled={surveys.length < 1}>
                                        {open ? <AngleUp /> : <AngleDown />}
                                    </IconButton>
                                ) : null}
                                {key === ACTION_ID && (
                                    <FlexEnd>
                                        {surveys.length > 0 && (
                                            <Tooltip arrow title={!!statusNoCompleted ? t("allSurveyKO") : t("allSurveyOk")}>
                                                <div>
                                                    <IconButton disabled>{!!statusNoCompleted ? <ExclamationTriangle /> : <CheckCircle iconWeight="solid" color="success" />}</IconButton>
                                                </div>
                                            </Tooltip>
                                        )}
                                        {!!deleteBtnClick && (
                                            <DeleteBtn
                                                onClick={() => {
                                                    if (!!deleteBtnClick) deleteBtnClick(row.id);
                                                }}
                                            />
                                        )}

                                        {!!editBtn && (
                                            <EditBtn
                                                onClick={() => {
                                                    navigate(`${editBtn}/${row.id}`);
                                                }}
                                            />
                                        )}
                                    </FlexEnd>
                                )}
                                {key === ACTION_VIEW_ID && (
                                    <FlexEnd>
                                        <EyeBtn
                                            onClick={() => {
                                                if (!!eyeBtn) eyeBtn(row.id, { ...row });
                                                if (!!navigateToSurvey) navigate(`${PATHS.editRoles}/${row.id}`);
                                            }}
                                        />
                                    </FlexEnd>
                                )}
                                {key === STATUS_ID && <BannerStatus row={row} keyToSearch={key} />}
                                {key !== STATUS_ID && key !== ACTION_ID && <Typography variant="bodyLarge500">{get(row, `${key}`, "")}</Typography>}
                            </>
                        )}
                    </TableCell>
                ))}
            </StyledTableRow>

            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("surveyTitle")}</TableCell>
                                    <TableCell>{t("clmStatus")}</TableCell>
                                    <TableCell>{t("clmRequired")}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {surveys.map((historyRow: ISurveyBe) => (
                                    <StyledTableRow hover onClick={(event: any) => handleClick(event, row.name)} tabIndex={-1} key={historyRow.id}>
                                        <TableCell component="th" scope="row">
                                            {i18n.language === ELanguages.it ? historyRow.titleIt : historyRow.titleEn}
                                        </TableCell>
                                        <TableCell>
                                            <BannerStatus row={historyRow} keyToSearch={"status"} />
                                        </TableCell>
                                        <TableCell>{!!historyRow.isMandatory ? t("radioYes") : t("radioNo")}</TableCell>
                                        <TableCell component="th" scope="row">
                                            <ModalDetailSurvey
                                                surveyId={historyRow.id}
                                                userId={row.id}
                                                title={`${row.name} - ${i18n.language === ELanguages.it ? historyRow.titleIt : historyRow.titleEn}`}
                                            />
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </>
    );
};

const UsersTableWithCollapse = (props: ICollapsibleTableProps) => {
    const { headCells, rows, parentOrder, fieldToOrder, parentSortAscDesc } = props;
    const [order, setOrder] = useState<Order>(parentOrder ?? EOrder.asc);
    const [orderBy, setOrderBy] = useState<string>(fieldToOrder ?? "");
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string, queryName: string) => {
        const isAsc = orderBy === property && order === EOrder.asc;
        if (!!parentSortAscDesc) {
            parentSortAscDesc(property, queryName, isAsc);
            setOrderBy(property);
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
        } else {
            setOrder(isAsc ? EOrder.desc : EOrder.asc);
            setOrderBy(property);
        }
    };
    return (
        <TableContainer component={Paper}>
            <Table
                sx={{
                    minWidth: 700,
                }}
                aria-label="customized table"
            >
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell, index) => (
                            <TableCell key={headCell.id + index} sortDirection={orderBy === headCell.id ? order : false}>
                                <FlexStart>
                                    {!!headCell.label && (
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : EOrder.asc}
                                            onClick={(event) => handleRequestSort(event, headCell.id as string, headCell?.queryName ?? "")}
                                        >
                                            <Typography variant="bodyLarge700" key={headCell.id + index} component="div">
                                                {headCell.label}
                                            </Typography>
                                        </TableSortLabel>
                                    )}
                                    {!!headCell.filter && headCell.filter}
                                </FlexStart>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any, index: number) => (
                        <Row row={row} index={index} {...props} key={index} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UsersTableWithCollapse;
