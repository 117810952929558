import { Button } from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import styled from "styled-components";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

interface ILandingProps {
    loading: boolean;
}

const LandingContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #152935;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;
const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    opacity: 1;
`;
const ImgContainer = styled.div`
    position: absolute;
    top: 80px;
    left: 50%;
`;
const Landing = (props: ILandingProps) => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const { loading } = props;
    return (
        <LandingContainer>
            <ImgContainer>
                <img src="" />
            </ImgContainer>
            <BodyContainer>
                <Typography variant="titleSmall" sx={{ color: "white" }} gutterBottom component="div">
                    {t("welcome")}
                </Typography>
                <Typography variant="body" sx={{ color: "white" }} gutterBottom component="div">
                    {t("welcomeDescription")}
                </Typography>
                <Button variant="contained" onClick={() => instance.loginRedirect()}>
                    Login
                </Button>
            </BodyContainer>
        </LandingContainer>
    );
};

export default Landing;
